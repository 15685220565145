import { Flex, useToast } from "@chakra-ui/react";
import ItemStatistics from "./ItemStatistics";
import { getSummary } from "api/dashboad.api";
import { useEffect, useState } from "react";

export default function StatisticsSetting() {
  const toast = useToast();
  const [dataSummary, setDataSummary] = useState()

  const getSummaryStatistics = async () => {
    try {
      const { data } = await getSummary();
      if (data?.success) {
        setDataSummary(data?.data);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    getSummaryStatistics()
  }, [])

  return (
    <Flex flexDirection="row" alignItems='flex-end' gap={4}>
      <ItemStatistics
        title="DAU"
        amount={dataSummary?.daily_active_user_summary?.value || 0}
        content='The number of unique users from yesterday. Compared to the same weekday 7 days ago.'
        percent={dataSummary?.daily_active_user_summary?.percentage_change || 0}
      />
      <ItemStatistics 
        title="WAU"
        amount={dataSummary?.weekly_active_user_summary?.value || 0}
        content='The number of unique users from the past 7 days (calendar week). Compared to the same calendar week 7 days prior.'
        percent={dataSummary?.weekly_active_user_summary?.percentage_change || 0}
      />
      <ItemStatistics 
        title="MAU"
        amount={dataSummary?.monthly_active_user_summary?.value || 0}
        content='The number of unique users from the past calendar month. Compared to the previous calendar month.'
        percent={dataSummary?.monthly_active_user_summary?.percentage_change || 0}
      />
      <ItemStatistics 
        title="New Users"
        amount={dataSummary?.daily_new_user_summary?.value || 0}
        content='The count of new users yesterday. Compared to the same weekday 7 days ago.'
        percent={dataSummary?.daily_new_user_summary?.percentage_change || 0}
      />
    </Flex>
  );
}
