import { useCallback, useEffect, useState } from "react";
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import ItemsSendRow from "./row";
import ConditionLeaderBoard from "./action";
import ButtonType from "components/Button";
import CardHeader from "components/Card/CardHeader";
import { getPrizeBountyBall, getPrizeGauntlet, getPrizeTreasureBox } from "api/prize.api";
import NFTDetailModal from "./NFTDetailModal";

export default function ModalSelectPrize({
  isOpen,
  onClose,
  setCheckedIds,
  checkedIds,
  setValue,
  watch,
  idEdit,
  nftMngType,
  clearErrors,
}) {
  const labels = watch("prize_type") === "TREASURE_BOX" ? [
    "Image",
    "Name",
    "",
  ] : [
    "Image",
    "Name",
    "Rarity",
    "Trait",
    "Gimmick",
    "",
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [prizeItems, setPrizeItems] = useState(checkedIds);
  const [totalPage, setTotalPage] = useState(0);
  const [nftDetail, setNftDetail] = useState()
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");

  const {
    isOpen: isOpenDetailItem,
    onOpen: onOpenDetailUser,
    onClose: onCloseDetailItem,
  } = useDisclosure();

  const handleOpenDetailUserModal = async (item) => {
    setNftDetail(item)
    onOpenDetailUser();
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };
  
  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const getApiPrize = async (newParams) => {
    switch (watch("prize_type")) {
      case "PRIZE_BOUNTY_BALL": {
        const { data: prizeBountyBall } = await getPrizeBountyBall({
          ...params,
          ...newParams,
          chain_id: watch("chain"),
          prize_id: idEdit ? idEdit : ""
        });
        return prizeBountyBall
      }
      case "PRIZE_GAUNTLET": {
        const { data: prizeGauntlet } = await getPrizeGauntlet({
          ...params,
          ...newParams,
          chain_id: watch("chain"),
          prize_id: idEdit ? idEdit : ""
        });
        return prizeGauntlet
      }
      case "TREASURE_BOX": {
        const { data: prizeTreasure } = await getPrizeTreasureBox({
          ...params,
          ...newParams,
          play_fee_id: watch("play_fee_id")
        });
        return prizeTreasure
      }
      default: {
        const { data } = await getPrizeTreasureBox({
          ...params,
          ...newParams,
          play_fee_id: watch("play_fee_id")
        });
        return data
      }

    }
  }

  const getEvent = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const data = await getApiPrize(newParams)
        if (data?.success) {
          const res = data?.data;
          setItems(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  useEffect(() => {
    getEvent();
  }, [getEvent]);

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      const r = items.filter((elem) => !prizeItems.find(({ id }) => elem.id === id))
      setPrizeItems([...prizeItems, ...r]);
    } else {
      const uniqueArray1 = prizeItems.filter(item1 => 
        !items.some(item2 => item2.id === item1.id)
      );
      setPrizeItems(uniqueArray1);
    }
  };

  const handleClose = () => {
    setItems([])
    onClose()
  }

  const handleSelect = () => {
    onClose()
    setValue("prize_items", prizeItems)
    setCheckedIds(prizeItems);
    clearErrors("prize_items")
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} closeOnOverlayClick={false} size="xxl">
      <ModalOverlay />
      <ModalContent maxW="900px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          Select Item(s)
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">
          <Flex flexDirection="column">
            <Card px="0px">
              {watch("prize_type") !== "TREASURE_BOX" && (
                <CardHeader display="flex" flexDirection="column" gap="12px" mb="24px">
                  <ConditionLeaderBoard
                    isLoading={isLoading}
                    setParams={setParams}
                    params={params}
                  />
                </CardHeader>
              )}
              <CardBody overflowX="auto">
                <TableCustom
                  labels={labels}
                  isLoading={isLoading}
                  dataRow={items}
                  // isChecked={items?.length > 0 && checkedIds.length === items?.length}
                  isChecked={items?.length > 0 && prizeItems?.length > 0 && items.every(item => prizeItems.find(prize => item.id === prize.id))}
                  onChangeChecked={onChangeChecked}
                  showCheck={watch("prize_type") === "TREASURE_BOX" ? false : true}
                >
                  <>
                    {items?.map((item) => {
                      return (
                        <ItemsSendRow
                          key={item?.id}
                          item={item}
                          setCheckedIds={setPrizeItems}
                          checkedIds={prizeItems}
                          watch={watch}
                          handleOpenDetailUserModal={handleOpenDetailUserModal}
                        />
                      );
                    })}
                  </>
                </TableCustom>
              </CardBody>
              <Paginate
                page={params?.page}
                pageCount={totalPage}
                limit={params.limit}
                onChangeLimit={onChangeLimit}
                handleChangePage={handleChangePage}
              />
            </Card>
          </Flex>
        </ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            m="auto"
            w="160px"
            h="46px"
            borderRadius="5px"
            disabled={!prizeItems?.length}
            onClick={handleSelect}
          >
            Select
          </ButtonType>
        </ModalFooter>
      </ModalContent>
      {isOpenDetailItem && (
        <NFTDetailModal
          nftMngType={nftMngType}
          item={nftDetail}
          isOpen={isOpenDetailItem}
          onClose={onCloseDetailItem}
          chain={watch("chain")}
        />
      )}
    </Modal>
  );
}
  