import {
  Box,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Image,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { UploadCSVIcon } from "components/Icons/uploadCSVIcon";
import { useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useController, useFormContext } from "react-hook-form";

export default function FormUpload({
  label = "Select image file to upload",
  desLabel = "Or drag and drop it here",
  name,
  rules,
  defaultValue,
  wrapperProps,
  handleOnChange,
  accept = "*",
  isRequired,
  title,
  type,
  deleteImage,
  textDescription = "",
  showError = true,
  loading = false,
  w = '180px',
  h = '180px',
  ...rest
}) {
  const { control } = useFormContext();
  const inputRef = useRef();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });
  const handleOnChangeUpload = (e) => {
    if (handleOnChange) {
      handleOnChange(e.target.files);
    } else {
      onChange(e.target.files);
    }
  };

  const onDrop = (acceptedFiles) => {
    if (handleOnChange) {
      handleOnChange(acceptedFiles);
    } else {
      onChange(acceptedFiles);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    ...rest,
  });

  return (
    <FormControl
      maxW="100%"
      w="auto"
      isInvalid={error?.message}
      {...wrapperProps}
      {...getRootProps({ className: "dropzone" })}
    >
      {title && (
        <FormLabel whiteSpace="nowrap" color="#000000">
          {title} {isRequired && <span style={{ color: "red" }}>*</span>}
        </FormLabel>
      )}
      {type == "IMAGE" && value ? (
        value?.includes('.fbx') ? (
          <Box w="100%" position={"relative"}>
            <Text width='96%'>{value}</Text>
            {!rest?.disabled && (
              <CloseButton bg={'#ffff'} _hover={{ bg: '#ffff' }} boxShadow='base' size="sm" position={"absolute"} top={0} right={0} cursor="pointer" onClick={deleteImage}/>
            )} 
          </Box>
        ) : (
          <Box w={w} h={h} position={"relative"}>
            <Image src={value} w="100%" h="100%" alt={value} />
            {!rest?.disabled && (
              <CloseButton bg={'#ffff'} _hover={{ bg: '#ffff' }} size="sm" position={"absolute"} top={-4} right={-4} cursor="pointer" onClick={deleteImage}/>
            )} 
          </Box>
        )
      ) : (
        <Flex
          direction="column"
          gap={4}
          alignItems="center"
          justifyContent="center"
          border="1px dashed #DBDBDB"
          borderRadius="8px"
          p="28px"
          cursor={!rest?.disabled ? "pointer" : "not-allowed"}
          onClick={() => {
            inputRef.current?.click();
          }}
          opacity={isDragActive || rest?.disabled ? 0.5 : 1}
        >
          <Icon as={UploadCSVIcon} w="106px" h="56px" pointerEvents="none" />
          <Box
            pointerEvents="none"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Text fontSize="14px" fontWeight="500">
              {label}
            </Text>
            <Text fontSize="14px" fontWeight="400" color="#48484A">
              {desLabel}
            </Text>
          </Box>
          <input
            ref={inputRef}
            type="file"
            accept={accept}
            style={{
              display: "none",
            }}
            onChange={handleOnChangeUpload}
            {...getInputProps()}
          />
          {textDescription && (
            <Text fontSize="14px" fontWeight="400" color="#48484A">
              {textDescription}
            </Text>
          )}
        </Flex>
      )}
      {loading && (
        <Spinner />
      )}
      {error?.message && showError && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>
  );
}
