import { Flex, Text } from '@chakra-ui/react';
import FormDatePicker from 'components/form/FormDatePicker';
import FormInput from 'components/form/FormInput';
import FormLabelComponent from 'components/form/FormLabel';
import FormTextAria from 'components/form/FormTextAria';
import FormUpload from 'components/form/FormUpload';
import React from 'react'
import { gaugeOptions, OPTION_SALE } from '../constant';
import FormSelect from 'components/form/FormSelect';
import FormRadio from 'components/form/FormRadio';

export default function FormEng(props) {
  const { 
    transLabel, 
    handleImageChange, 
    handleDragImage, 
    deleteImage, 
    nameImage,
    setValue,
    idEdit,
    dateFormat,
    watch,
    toggle,
    checkedIds,
    setCheckedIds,
    setNftMngType,
    prizeTypes,
    listModeType,
    getChainOptions,
    listFee,
    prizeById,
    getListFeePrize,
    clearErrors,
  } = props
  
  return (
    <Flex flexDirection="column" gap="12px">
      <FormInput
        maxW="100%"
        name="name"
        label={transLabel("name")}
        placeholder={transLabel("enterName")}
        isRequired
      />
      <Flex flexDirection="column" gap="16px" w="100%">
        <Flex gap="12px" w="100%">
          <FormSelect
            wrapperProps={{
              w: "100%"
            }}
            name="allocation"
            label={transLabel("allocation")}
            defaultValue={listModeType[0]?.value}
            options={listModeType}
            onChange={(e) => {
              setValue("allocation", e.target.value);
              setValue("prize_items", null);
              setCheckedIds([])
              if (e.target.value === "PVE_FREE_PLAY") {
                getListFeePrize("TICKET")
              } else if (e.target.value === "PVE_SINGLE_PLAY") {
                getListFeePrize("ALL")
              } else if (e.target.value === "CO_OP" || e.target.value === "PVP") {
                getListFeePrize("COIN")
              }
              if (e.target.value !== "PVE_SINGLE_PLAY") {
                setValue("assist", false)
              }
            }}
            isRequired
            disabled={idEdit && prizeById?.prize_status !== "AVAILABLE"}
          />
          <FormSelect
            wrapperProps={{
              w: "100%"
            }}
            name="play_fee_id"
            label={transLabel("playFee")}
            defaultValue={listFee[0]?.value}
            options={listFee}
            onChange={(e) => {
              setValue("play_fee_id", e.target.value);
              setValue("prize_items", null);
              setCheckedIds([])
            }}
            isRequired
            disabled={idEdit && prizeById?.prize_status !== "AVAILABLE"}
          />
        </Flex>
        <Flex gap="12px" w="100%">
          <FormSelect
            wrapperProps={{
              w: "100%"
            }}
            name="prize_type"
            label={transLabel("prizeType")}
            defaultValue={prizeTypes[0]?.value}
            options={prizeTypes}
            onChange={(e) => {
              setValue("prize_type", e.target.value);
              setValue("prize_items", null);
              setCheckedIds([])
              setNftMngType(e.target.value)
              if (e?.target?.value === "INVITATION_CARD") {
                setValue("sale", true)
              }
            }}
            isRequired
            disabled={idEdit && prizeById?.prize_status !== "AVAILABLE"}
          />
          {watch("prize_type") === "PRIZE_BOUNTY_BALL" || watch("prize_type") === "PRIZE_GAUNTLET" ? (
            <FormSelect
              wrapperProps={{
                w: "100%"
              }}
              name="chain"
              label={transLabel("chain")}
              defaultValue={getChainOptions[0]?.value}
              options={getChainOptions}
              onChange={(e) => {
                setValue("chain", e.target.value);
                setValue("prize_items", null);
                setCheckedIds([])
              }}
              isRequired
              disabled={idEdit && prizeById?.prize_status !== "AVAILABLE"}
            />
          ) : (
            <Flex w="100%"></Flex>
          )}
        </Flex>
      </Flex>
      <FormInput
        maxW="100%"
        name="prize_items"
        value=""
        label={transLabel("prizeItems")}
        placeholder={transLabel("enterPrizeItems")}
        isRequired
        onClick={() => {
          if (idEdit && prizeById?.prize_status !== "AVAILABLE" && watch("prize_type") === "TREASURE_BOX") {
            return null
          } else {
            if (watch("prize_type") === "INVITATION_CARD") {
              toggle('modalPrizeInvitation')
            } else if (watch("prize_type") === 'VIEWER_BACKGROUND_3D_IMAGE') {
              toggle('modalPrizeBackground')
            } else if (watch("prize_type") === 'DIGITAL_FIGURE') {
              toggle('modalPrizeDF')
            } else {
              toggle('modalPrize')
            }
          }
        }}
        autocomplete="off"
      />
      {checkedIds?.length > 0 && (
        <Flex gap="4px" flexWrap='wrap'>
          {checkedIds?.map((check, index) => (
            <Flex
              key={index}
              padding="4px 8px"
              borderRadius="6px"
              background="#faf871"
              alignItems="center"
            >
              <Text fontSize="14px" fontWeight="400">{check?.name || check?.card_name}</Text>
              {/* <SmallCloseIcon onClick={() => filterChecked(check)}/> */}
            </Flex>
          ))}
        </Flex>
      )}
      <FormTextAria
        label={transLabel("description")}
        placeholder={transLabel("enterDesc")}
        name="description"
        defaultValue=''
        h="100px"
      />
      <FormUpload
        label="Select image file to upload"
        title={transLabel("prizeImage")}
        name="prize_img"
        type='IMAGE'
        accept={"image/*"}
        textButton={nameImage}
        isRequired
        handleOnChange={(e) =>
          handleImageChange(e, "prize_img")
        }
        handleDragImage={(val) =>
          handleDragImage(val, "prize_img")
        }
        deleteImage={() => deleteImage("prize_img")}
      />
      <Flex w="100%" alignItems="center" gap="12px">
        <Flex w="100%" alignItems="center" gap="70px">
          <FormRadio 
            label={transLabel("assist")}
            name="assist" 
            options={gaugeOptions}
            value={watch('assist')}
            disabled={watch("allocation") !== "PVE_SINGLE_PLAY"}
            onChange={(e) => {
              setValue("assist", e === "true" ? true : false)
              if (e !== "true") {
                setValue('assist_gauge_limit', null)
                clearErrors('assist_gauge_limit')
              } else {
                setValue('assist_gauge_limit', 1)
              }
            }}
          />
        </Flex>
        {watch('assist') && (
          <FormInput
            maxW="100%"
            name="assist_gauge_limit"
            type="number"
            label={transLabel("assistGauge")}
            placeholder={transLabel("enterValue")}
            isRequired
            min={1}
          />
        )}
      </Flex>
      <FormUpload
        label="Select image file to upload"
        title={transLabel("bgImage")}
        name="bg_img"
        type='IMAGE'
        accept={"image/*"}
        textButton={nameImage}
        isRequired
        handleOnChange={(e) =>
          handleImageChange(e, "bg_img")
        }
        handleDragImage={(val) =>
          handleDragImage(val, "bg_img")
        }
        deleteImage={() => deleteImage("bg_img")}
      />
      <Text fontSize="16px" fontWeight="500">{transLabel("salePeriods")}</Text>
      <Flex flexDirection="column" gap="12px" border="1px solid #ccc" borderRadius="8px" p="12px">
        <FormSelect
          wrapperProps={{
            w: "100%"
          }}
          name="sale"
          label={transLabel("sale")}
          options={OPTION_SALE}
          onChange={(e) => {
            setValue("sale", e.target.value);
            if (e.target.value === 'false') {
              clearErrors('start')
              clearErrors('end')
            }
          }}
          disabled={watch("prize_type") === "INVITATION_CARD"}
        />
        {watch("sale") && watch("sale") !== "false" && (
          <>
            <Flex direction="column" gap="8px" w="100%">
              <FormLabelComponent
                m="0px"
                title={transLabel("start")}
                isRequired
              />
              <FormDatePicker
                name="start"
                showTimeSelect
                dateFormat={dateFormat}
                h="40px"
                w="100%"
                disabled={!watch("sale") || watch("sale") === "false"}
              />
            </Flex>
            <Flex direction="column" gap="8px" w="100%">
              <FormLabelComponent
                m="0px"
                title={transLabel("end")}
                isRequired
              />
              <FormDatePicker
                name="end"
                showTimeSelect
                dateFormat={dateFormat}
                h="40px"
                w="100%"
                disabled={!watch("sale") || watch("sale") === "false"}
              />
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  )
}
