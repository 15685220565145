import {
  Flex,
  Icon,
  Input,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { CheckIcon } from "components/Icons/CheckIcon";
import { EditIcon } from "components/Icons/EditIcon";
import { useCallback, useEffect, useState } from "react";
import { getOnlyDecimal, showPercent } from "utils/number";
import { convertToTwoDigits, getOrdinalNumber } from "utils/time";

export default function MonthlyPayoutRow({
  isEditMode,
  data = {},
  handleOpenUpdateMode,
  handleUpdateMonthlyPayout,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const [dataEdit, setDataEdit] = useState({ ...data });

  const handleChangeNftField = (e, key) => {
    const valueField = getOnlyDecimal(e.target.value, 1, 1, 4);
    if (key) {
      setDataEdit((preState) => ({
        ...preState,
        [key]: valueField,
      }));
    }
  };

  const onUpdateValueNft = (dataEdit) => {
    if (dataEdit && handleUpdateMonthlyPayout) {
      handleUpdateMonthlyPayout(dataEdit);
    }
  };

  const renderTdNft = useCallback(
    (key) => {
      if (isEditMode) {
        return (
          <Input
            color="#000000"
            onChange={(e) => handleChangeNftField(e, key)}
            value={dataEdit?.[key]}
            type="text"
            maxLength={30}
            maxWidth={120}
          />
        );
      }
      return (
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {showPercent(data?.[key])}
        </Text>
      );
    },
    [isEditMode, dataEdit]
  );

  useEffect(() => {
    if (data && Object.values(data)?.length) {
      setDataEdit({ ...data });
    }
  }, [data]);

  return (
    <Tr>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {convertToTwoDigits(data?.month_number)}-
          {getOrdinalNumber(data?.year_order)} year
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {renderTdNft("pink_nft_rate")}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {renderTdNft("green_nft_rate")}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {renderTdNft("purple_nft_rate")}
        </Text>
      </Td>
      <Td>
        <Flex gap={6}>
          {isEditMode ? (
            <Icon
              fontSize="30px"
              borderRadius="8px"
              cursor="pointer"
              bg="#07CF84"
              color="white"
              fontWeight={"bold"}
              as={CheckIcon}
              onClick={() => onUpdateValueNft(dataEdit)}
            />
          ) : (
            <Icon
              fontSize="30px"
              borderRadius="8px"
              p="7px"
              cursor="pointer"
              bg="#1480FF"
              as={EditIcon}
              onClick={() => handleOpenUpdateMode()}
            />
          )}
        </Flex>
      </Td>
    </Tr>
  );
}
