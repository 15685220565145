import { Flex, Text, useToast } from '@chakra-ui/react'
import { getDetailachaHistorySummary } from 'api/gacha.api';
import React, { useCallback, useEffect, useState } from 'react'

export default function SummaryGachaHistory() {
  const [isLoading, setIsLoading] = useState(false);
  const [dataGachaSummary, setDataGachaSummary] = useState([]);
  const toast = useToast();

  const getGachaSummary = useCallback(
    async () => {
      try {
        setIsLoading(true);
        const { data } = await getDetailachaHistorySummary();
        if (data?.success) {
          const res = data?.data;
          setDataGachaSummary(res);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    getGachaSummary();
  }, [getGachaSummary]);

  return (
    <Flex
      flexDirection="column"
      px="12px"
      py="8px"
      borderRadius="8px"
      background="#f3f3f3"
      gap="10px"
      fontWeight="600"
    >
			<Text>Summary</Text>
      <Flex
        pl="12px"
        gap="6px"
        flexDirection="column"
      >
        <Text>Total plays: <Text as="span" color="#24baff">{dataGachaSummary?.total_plays || 0}</Text></Text>
        <Flex alignItems="center" gap="24px">
          <Text>Rewards per type</Text>
          <Text>Hunter: <Text as="span" color="#ff3c87">{dataGachaSummary?.count_hunter || 0}</Text></Text>
          <Text>Gauntlet: <Text as="span" color="#ff3c87">{dataGachaSummary?.count_gauntlet || 0}</Text></Text>
          <Text>Bounty Ball: <Text as="span" color="#ff3c87">{dataGachaSummary?.count_bounty_ball || 0}</Text></Text>
          <Text>Others: <Text as="span" color="#ff3c87">{dataGachaSummary?.count_non_nft || 0}</Text></Text>
        </Flex>
      </Flex>
		</Flex>
  )
}
