import END_POINT from "./constants";
import http from "./http";
import { convertParams, omitParams } from "utils/object";

export const getListBanner = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.BANNER}?${params}`);
};

export const createBanner = (data, option = {}) => {
  return http.post(END_POINT.BANNER, data, option);
};

export function updateBanner(id, data) {
  return http.put(`${END_POINT.BANNER}/${id}`, data);
}

export const deleteBanner = (ids, option = {}) => {
  return http.delete(`${END_POINT.BANNER}`, ids, option);
};

export function getBannerById(id) {
  return http.get(`${END_POINT.BANNER}/${id}`);
}

export function getEventsInComing() {
  return http.get(`${END_POINT.BANNER_EVENT_IN_COMING}`);
}
