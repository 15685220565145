/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { CalendarIcon } from "@chakra-ui/icons";
import { FormControl, FormErrorMessage, Icon } from "@chakra-ui/react";
import moment from "moment";
import { useRef } from "react";
import ReactDatePicker from "react-datepicker";
import { useController, useFormContext } from "react-hook-form";
import styled from "styled-components";

const FormControlStyled = styled(FormControl)`
  .react-datepicker {
    display: flex;
    flex-direction: ${(props) => props.isShowTodayBtn ? "column" : "row"};
  }
  .react-datepicker-popper {
    transform: translate(0px, 50px) !important;
  }
  svg {
    position: absolute;
    right: 0;
    top: 4px;
    color: #000000;
  }
  input {
    padding: 6px 25px 5px 10px;
    color: #000000;
  }
  input[type="text"]:disabled {
    background: #e6e6e6;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const DatePickStyled = styled(ReactDatePicker)`
  border: 1px solid #eeeff2;
  border-radius: 5px;
  width: 100%;
  height: 40px;
`;

const FormDatePicker = ({
  // label,
  name,
  rules,
  defaultValue = "",
  wrapperProps,
  // wrapperStyles,
  // forceError,
  // hideError,
  // useDate,
  classNames,
  placeholder = "",
  onChange,
  dateFormat = "YYYY-MM-dd",
  timeFormat = "HH:mm:ss",
  isShowTodayBtn = false,
  ...rest
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange: onChangeField, value },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  const inputRef = useRef(null);

  const onClickTodayBtn = (e) => {
    e.stopPropagation();
    const today = moment().toDate();
    if (onChange) {
      onChange(today);
    } else {
      onChangeField(today);
    }
  };

  return (
    <FormControlStyled
      maxW="100%"
      w="100%"
      className={classNames}
      // w="auto"
      isInvalid={error?.message}
      isShowTodayBtn={isShowTodayBtn}
      {...wrapperProps}
    >
      <DatePickStyled
        w="100%"
        selected={value ? moment(value).toDate() : null}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          } else {
            onChangeField(e);
          }
        }}
        ref={inputRef}
        dateFormat={dateFormat}
        showIcon
        placeholderText={placeholder}
        timeFormat={timeFormat}
        timeIntervals={1}
        timeCaption="time"
        onKeyDown={(e) => e.preventDefault()}
        icon={
          <Icon
            as={CalendarIcon}
            onClick={() => {
              inputRef?.current?.handleFocus();
            }}
          />
        }
        todayButton={isShowTodayBtn ? <div onClick={onClickTodayBtn}>Today</div> : false}
        {...rest}
      />
      {error?.message && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControlStyled>
  );
};

export default FormDatePicker;
