import {
  FormLabel,
  Text,
  Grid,
  Image,
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  TagLabel,
  Tag,
  useToast,
} from "@chakra-ui/react";
import { getDayLLLL } from "utils/time";
import { lowerCase, upperFirst } from "lodash";
import { DISTRIBUTE_CONDITION } from "./constant";
import TableCustom from "components/Tables/CustomTable";
import { useState, useEffect } from "react";
import RowGift from "./createAndUpdate/rowGift"
import { getEventById } from "api/presentBox.api";

export default function DetailPresentBox({
  isOpen,
  onClose,
  dataDetail,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const [gifts, setGifts] = useState([]);
  const [event, setEvent] = useState([]);
  const toast = useToast();
  const labels = [
    "NAME",
    "TYPE",
    "SUB TYPE",
    "VALUE",
    ""
  ];

  useEffect(() => {
    const cardData = dataDetail?.gift?.event_invitation_cards || [];
    const resourceData = dataDetail?.gift?.resource || {};
    const giftsData = [
      ...cardData?.map((item) => ({
        type: "Invitation Card",
        name: item?.card_name,
        value: item?.amount,
      })),
      ...Object.keys(resourceData || {})
        .filter((key) => resourceData[key] > 0)
        .map((key) => ({
          type: "Point & Currency",
          name: key,
          value: resourceData[key],
        }))
    ];

    setGifts(giftsData);
  }, [dataDetail]);

  const eventDetail = async () => {
    try {
      const { data } = await getEventById(dataDetail?.condition_setting?.event_id);
      if (data?.success) {
        const res = data?.data;
        setEvent(res);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }

  useEffect(() => {
    if(dataDetail?.condition_setting?.event_id) {
      eventDetail();
    }
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="1000px" overflowY="auto" maxHeight="90vh" overflow="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          DETAIL PRESENT BOX
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px" height="calc(100% - 70px)" overflowY="auto">
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Mgmt Name
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                {dataDetail?.mgmt_name}
              </Text>
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Name
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                {dataDetail?.name_en}
              </Text>
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Description
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                {dataDetail?.description_en}
              </Text>
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Distribution Types
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                {upperFirst(lowerCase(dataDetail?.distribution_type))}
              </Text>
            </div>
            {
              dataDetail?.distribution_type === "CONDITION" && (
              <>
                <div>
                  <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                    Distribution Condition
                  </FormLabel>
                  <Text
                    ml={2}
                    mt={4}
                    fontSize="16px"
                    fontWeight="400"
                    color={textColor}
                  >
                    {DISTRIBUTE_CONDITION.find(item => item.value === dataDetail?.distribution_condition)?.label}
                  </Text>
                </div>
                <div>
                  <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                    Event
                  </FormLabel>
                  <Text
                    ml={2}
                    mt={4}
                    fontSize="16px"
                    fontWeight="400"
                    color={textColor}
                  >
                    {event?.name}
                  </Text>
                </div>
                {
                  dataDetail?.distribution_condition === "EVENT_TOP_RANK" && (
                    <>
                      <div>
                        <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                          From
                        </FormLabel>
                        <Text
                          ml={2}
                          mt={4}
                          fontSize="16px"
                          fontWeight="400"
                          color={textColor}
                        >
                          {dataDetail?.condition_setting?.from_rank}
                        </Text>
                      </div>
                      <div>
                        <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                          To
                        </FormLabel>
                        <Text
                          ml={2}
                          mt={4}
                          fontSize="16px"
                          fontWeight="400"
                          color={textColor}
                        >
                           {dataDetail?.condition_setting?.to_rank}
                        </Text>
                      </div>
                    </>
                  )
                }
              </>
              )
            }
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Distribution Date
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                 {getDayLLLL(dataDetail?.distribution_start_date * 1000)}
              </Text>
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Recipients
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                mb={2}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                 {dataDetail?.recipient_type}
              </Text>
              {
                dataDetail?.recipient_type !== "ALL" && (
                  dataDetail?.receivers?.map((receiver) => (
                    <Tag key={receiver.id} colorScheme="teal" mr={2} mb={2}>
                      <TagLabel>{receiver.email}</TagLabel>
                    </Tag>
                  ))
                )
              }
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Select Present Box Items
              </FormLabel>
              {gifts.length > 0 && (
                <TableCustom
                labels={labels}
                dataRow={gifts}
                isCheck={false}
              >
                {gifts?.map((item, index) => {
                  return (
                    <RowGift
                      key={index}
                      item={item}
                      handleDelete
                      isDetail={true}
                    />
                  );
                })}
              </TableCustom>
              )}
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Receipt Expiration Settings
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                 {dataDetail?.is_no_expiration ? 'No Expiration' : getDayLLLL(dataDetail?.expired_date * 1000)}
              </Text>
            </div>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
