import { Box, Flex, Icon, Image, Td, Tr } from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { EditIcon } from "components/Icons/EditIcon";
import { ViewIcon } from "components/Icons/ViewIcon";
import { convertShortDateLocalSlash } from "utils/time";
import { TRIGGER_KEY } from "./constants";

export default function BannerManagementRow({
  itemBanner,
  checkedIds,
  setCheckedIds,
  handleOpenCreateOrUpdate,
  handleSetDataDetail,
}) {
  const handleCheckboxChange = () => {
    if (checkedIds.includes(itemBanner?.id)) {
      setCheckedIds(
        checkedIds.filter((checkedId) => checkedId !== itemBanner?.id)
      );
    } else {
      setCheckedIds([...checkedIds, itemBanner?.id]);
    }
  };

  const isDisableEdit = Date.now() > itemBanner?.end_date * 1000 ||
    itemBanner?.start_date * 1000 <= Date.now() &&  itemBanner?.end_date * 1000 >= Date.now();

  return (
    <>
      <Tr>
        <Td width="10px">
          <CheckBoxType
            isChecked={checkedIds.includes(itemBanner?.id)}
            onChange={() => handleCheckboxChange()}
          />
        </Td>
        <Td>{itemBanner?.id}</Td>
        <Td>
          <Image src={itemBanner?.image} w="150px" alt="Banner Image" />
        </Td>
        <Td>{itemBanner?.placement}</Td>
        <Td>
          <Flex flexDirection={"column"} gap={"10px"}>
            <Box
              w={"fit-content"}
              padding={"5px 15px"}
              backgroundColor={"#eff6ff"}
              color={"#4b9ffa"}
              border={"1px solid #4b9ffa"}
              rounded={"50px"}
            >
              {convertShortDateLocalSlash(itemBanner?.start_date * 1000)}
            </Box>
            <Box
              w={"fit-content"}
              padding={"5px 15px"}
              backgroundColor={"#f0fdf4"}
              color={"#32ae60"}
              border={"1px solid #32ae60"}
              rounded={"50px"}
            >
              {convertShortDateLocalSlash(itemBanner?.end_date * 1000)}
            </Box>
          </Flex>
        </Td>
        <Td>
          <Flex flexDirection={"column"} gap={"10px"}>
            <Box
              w={"fit-content"}
              padding={"5px 15px"}
              backgroundColor={"#ffe1e8"}
              color={"#f82f20"}
              border={"1px solid #f82f20"}
              rounded={"50px"}
            >
              Redirect | {itemBanner?.destination?.replaceAll("_", " ")}
            </Box>
            {itemBanner?.gift && (
              <Box
                w={"fit-content"}
                padding={"5px 15px"}
                backgroundColor={"#e4d4ff"}
                color={"#3f06a3"}
                border={"1px solid #3f06a3"}
                rounded={"50px"}
              >
                Trigger |{" "}
                {itemBanner?.gift === TRIGGER_KEY.GRANT_SPECIFIC_ITEM
                  ? "Grant Item"
                  : "Grant Point"}
              </Box>
            )}
          </Flex>
        </Td>
        <Td width="12.5%">
          <Flex gap={3} align="center">
            <Icon
              fontSize="30px"
              borderRadius="8px"
              cursor="pointer"
              as={ViewIcon}
              onClick={() => handleSetDataDetail()}
            />
            <Icon
              fontSize="30px"
              borderRadius="8px"
              p="7px"
              as={EditIcon}
              cursor={isDisableEdit ? "not-allowed" : "pointer"}
              bg={isDisableEdit ? "#b9d9ff" : "#1480FF"}
              onClick={() => handleOpenCreateOrUpdate()}
            />
          </Flex>
        </Td>
      </Tr>
    </>
  );
}
