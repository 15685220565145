import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import ButtonType from "components/Button";
import TableRewardNft from "./RewardNft";
import TableRewardNonNft from "./RewardNonNft";
import { useState } from "react";

export default function ModalSelectReward({
  isOpen,
  onClose,
  checkedIds,
  setCheckedIds,
  setValue,
  watch,
  listType,
}) {
  const TAB_NAMES = ["NFT ITEMS", "NON-NFT ITEMS"];
  const tabBg = useColorModeValue("gray.50", "gray.800");
  const activeTabBg = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const activeTabColor = useColorModeValue("blue.600", "blue.300");

  const textColor = useColorModeValue("#000000", "white");

  const [listChoose, setListChoose] = useState(checkedIds);

  const handleClose = () => {
    onClose()
  }

  const handleSelect = () => {
    setCheckedIds(listChoose);
    setValue("rewards", listChoose)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} closeOnOverlayClick={false} size="xxl">
      <ModalOverlay />
      <ModalContent maxW="1100px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          Select item(s)
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">
          <Flex justifyContent="space-between" width="100%" >
            <Text mb={4}>Item(s) selected: {listChoose?.length}</Text>
          </Flex>
          <Tabs>
            <TabList borderBottom={`1px solid ${borderColor}`}>
              {TAB_NAMES.map((name) => (
                <Tab
                  key={name}
                  fontWeight="semibold"
                  _selected={{
                    color: activeTabColor,
                    bg: activeTabBg,
                    borderBottom: "3px solid",
                    borderBottomColor: activeTabColor,
                    marginBottom: "-1px",
                  }}
                  _hover={{
                    color: activeTabColor,
                  }}
                  bg={tabBg}
                  borderTopRadius="md"
                  mr={1}
                  py={3}
                  px={5}
                >
                  {name}
                </Tab>
              ))}
            </TabList>
            <Box mt={4}>
              <TabPanels>
                <TabPanel padding="0">
                  <TableRewardNft setCheckedIds={setListChoose} checkedIds={listChoose} watch={watch} listType={listType}/>
                </TabPanel>
                <TabPanel padding="0">
                  <TableRewardNonNft setCheckedIds={setListChoose} checkedIds={listChoose}/>
                </TabPanel>
              </TabPanels>
            </Box>
          </Tabs>
        </ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            m="auto"
            w="160px"
            h="46px"
            borderRadius="5px"
            disabled={!listChoose?.length}
            onClick={handleSelect}
          >
            Select
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
