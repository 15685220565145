import END_POINT from './constants'
import http from './http'

export function getSummary() {
  return http.get(`${END_POINT.SUMMARY}`)
}

export function getHourlySummary() {
  return http.get(`${END_POINT.HOURLY_SUMMARY}`)
}

export function getEventSummary() {
  return http.get(`${END_POINT.EVENT_SUMMARY}`)
}

export function getMachineSummary() {
  return http.get(`${END_POINT.MACHINE_SUMMARY}`)
}
