import * as yup from "yup";

const schemaCumulativeSetting = () =>
  yup.object().shape({
    fusion_bcoin_fee: yup
      .number()
      .min(0, "Number of cost must be greater or equal to 0")
      .nullable(true)
      .max(9999999, "Number of cost must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    fusion_sphere_fee: yup
      .number()
      .min(0, "Number of cost must be greater or equal to 0")
      .nullable(true)
      .max(9999999, "Number of cost must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    awaken_bcoin_fee: yup
      .number()
      .min(0, "Number of cost must be greater or equal to 0")
      .nullable(true)
      .max(9999999, "Number of cost must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    awaken_sphere_fee: yup
      .number()
      .min(0, "Number of cost must be greater or equal to 0")
      .nullable(true)
      .max(9999999, "Number of cost must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
  });

export default schemaCumulativeSetting;
