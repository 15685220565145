import {
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ButtonType from "components/Button";
import { NUMBER } from "constants/enum";
import { uploadImageToServer } from "utils/uploadImage";
import { convertToIsoString, convertToUTCTime, dateAfterNow } from "views/Event/help";
import { prizeSelector } from "stores/prize/selector";
import FormEng from "./formEng";
import FormJp from "./formJp";
import ModalSelectPrize from "./ModalSelectPrize";
import { useSelector } from "react-redux";
import { ALL_NFT_CHAINS, CHAIN_TYPE, TYPE_PRIZE } from "constants/constants";
import { MODE_TYPE_SHOW } from "views/Machine/MachineSettings/constants";
import { useDispatch } from "react-redux";
import {
  listFeePrizeApi,
} from "stores/prize/action";
import { postPrize, putPrize } from "api/prize.api";
import { ERROR_PRIZE, OPTION_SALE } from "../constant";
import styled from "styled-components";
import ModalSelectInvitation from "./ModalSelectInvitation";
import ModalSelectBackground from "./ModalSelectBackground";
import ModalSelectDF from "./ModalSelectDF";
import moment from "moment";
import { schemaPrize, schemaPrizeUpdate } from "./schema";

const TRANS_LABEL = {
  en: {
    name: "Name",
    enterName: "Enter a name",
    prizeType: "Prize Type",
    chain: "Chain",
    allocation: "Allocation",
    playFee: "Play Fee",
    prizeItems: "Prize Items",
    enterPrizeItems: "Select item(s) for the prize",
    description: "Description",
    prizeImage: "Prize image",
    assist: "Assist Gauge",
    assistGauge: "Min. Attempt to Get Prize (Assist Gauge)",
    enterValue: "Enter a value",
    bgImage: "Background image",
    salePeriods: "Sale periods",
    sale: "Sale",
    start: "Start",
    end: "End",
  },
  jp: {
    name: "名前",
    enterName: "名前を入力してください",
    prizeType: "賞品の種類",
    chain: "鎖",
    allocation: "割り当て",
    playFee: "プレイ料金",
    prizeItems: "賞品",
    enterPrizeItems: "賞品のアイテムを選択してください",
    description: "説明",
    prizeImage: "賞品画像",
    assist: "アシストゲージ",
    assistGauge: "賞品獲得までの最小試行回数（アシストゲージ）",
    enterValue: "値を入力してください",
    bgImage: "背景画像",
    salePeriods: "セール期間",
    sale: "販売",
    start: "開始時間",
    end: "終了時間",
  },
};

const TextError = styled.div`
  padding: 0 24px;
  color: #E53E3E;
  font-size: 16px;
`

export default function CreateAndUpdatePrize({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const { listPrizeType, listPrizeModeType, prizeById } = useSelector(prizeSelector);
  const dispatch = useDispatch();
  const dateFormat = "dd MMM. YYYY HH:mm:ss";
  const [tabIndex, setTabIndex] = useState(0);
  const [trans, setTrans] = useState("en");
  const [checkedIds, setCheckedIds] = useState([]);
  const [nftMngType, setNftMngType] = useState()
  const [prizeTypes, setListPrizeTypes] = useState(() => "");
  const [listModeType, setListModeTypes] = useState(() => "");
  const [listFee, setListFee] = useState(() => "");
  const [modal, setModal] = useState({
    modalPrize: false,
    modalPrizeInvitation: false,
    modalPrizeBackground: false,
    modalPrizeDF: false,
  })

  const toggle = (type) => {
    setModal(prev => ({ ...prev, [type]: !prev[type] }))
  }

  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const form = useForm({
    resolver: dataDetail?.id ? yupResolver(schemaPrizeUpdate()) : yupResolver(schemaPrize())
  });
  const {
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    watch,
    setError,
    formState: { errors, isSubmitting },
  } = form;

  useEffect(() => {
    if (listPrizeType?.length) {
      let arr = [];
      listPrizeType?.map((item) => {
        return (arr = [...arr, { label: TYPE_PRIZE[item], value: item }]);
      });
      setListPrizeTypes(() => arr);
      setNftMngType(arr[0]?.value)
    }
    if (listPrizeModeType?.length) {
      let arrMode = [];
      listPrizeModeType?.map((item) => {
        return (arrMode = [...arrMode, { label: MODE_TYPE_SHOW[item], value: item }]);
      });
      setListModeTypes(() => arrMode);
    }
  }, [listPrizeType, listPrizeModeType]);

  useEffect(() => {
    if(watch("allocation") === "PVE_FREE_PLAY" || watch("allocation") === "PVP" || watch("allocation") === "PVE_SINGLE_PLAY") {
      let listFilter = []
      listPrizeType?.forEach((item) => {
        // return (arr = [...arr, { label: TYPE_PRIZE[item], value: item }]);
        if (item === "TREASURE_BOX") {
          listFilter.push({ label: TYPE_PRIZE[item], value: item })
        }
      });
      setListPrizeTypes(listFilter);
      setNftMngType(listFilter[0]?.value)
      setValue("prize_type", listFilter[0]?.value);
    } else {
      let arr = [];
      listPrizeType?.map((item) => {
        return (arr = [...arr, { label: TYPE_PRIZE[item], value: item }]);
      });
      setListPrizeTypes(() => arr);
      setNftMngType(arr[0]?.value)
      setValue("prize_type", arr[0]?.value);
    }
  }, [watch("allocation")])

  useEffect(() => {
    const filterFee = listFee?.length > 0 && listFee?.filter((fee) => fee?.value === watch("play_fee_id"))
    if(watch("allocation") === "PVE_SINGLE_PLAY" && filterFee[0]?.type === 'PVE_FREE_TICKET') {
      let listFilter = []
      listPrizeType?.forEach((item) => {
        if (item === "TREASURE_BOX") {
          listFilter.push({ label: TYPE_PRIZE[item], value: item })
        }
      });
      setListPrizeTypes(listFilter);
      setNftMngType(listFilter[0]?.value)
      setValue("prize_type", listFilter[0]?.value);
    } else if (watch("allocation") === "PVE_SINGLE_PLAY" && filterFee[0]?.type !== 'PVE_FREE_TICKET') {
      let arr = [];
      listPrizeType?.map((item) => {
        return (arr = [...arr, { label: TYPE_PRIZE[item], value: item }]);
      });
      setListPrizeTypes(() => arr);
      // setNftMngType(arr[0]?.value)
      // setValue("prize_type", arr[0]?.value);
    }
  }, [watch("play_fee_id")])

  // useEffect(() => {
  //   dispatch(listFeePrizeApi("TICKET"));
  // }, [])

  const disableWhenJP = useMemo(() => {
    return trans === "jp";
  }, [trans]);

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  useEffect(() => {
    if (dataDetail) {
      reset({
        ...dataDetail,
      });
    } else {
      reset();
    }
  }, [dataDetail]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    if (index === 1) {
      setTrans("jp");
    } else {
      setTrans("en");
    }
  };

  const transLabel = (label) => {
    return TRANS_LABEL[trans][label];
  };

  const onSubmit = async (data, e) => {
    console.log("🚀 ~ onSubmit ~ data:", data)
    const listPrizeItems = data?.prize_items?.map((item) => ({
      item_related_id: item?.id
    }))
    e.preventDefault();  
    const utcTime = moment.utc(data?.end).valueOf() / 1000
    if (utcTime > checkedIds[0]?.event_end_time && data?.prize_type === "INVITATION_CARD") {
      setError('end', {
        type: 'manual',
        message: 'The end time cannot be greater than the end time of the event.',
      });
      return
    }
    const dataSubmit = {
      prize_type: data?.prize_type,
      mode_type: data?.allocation,
      chain_id: data?.chain,
      play_fee_id: data?.play_fee_id,
      name: data?.name,
      description: data?.description,
      assist_gauge_limit: data?.assist_gauge_limit,
      assist_gauge: data?.assist,
      image_url: data?.prize_img,
      background_image_url: data?.bg_img,
      sale_period: data?.sale,
      start_sale_period: data?.sale === 'true' || data?.sale  ? convertToIsoString(data?.start) : null,
      end_sale_period: data?.sale === 'true' || data?.sale  ? convertToIsoString(data?.end) : null,
      prize_items: listPrizeItems,
      translations: [
        {
          lang_key: "EN",
          name: data?.name,
          description: data?.description,
        },
        {
          lang_key: "JP",
          name: data?.name_jp,
          description: data?.description_jp,
        }
      ]
    }
    try {
      if (idEdit) {
        const res = await putPrize(dataDetail?.id, dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Prize Edited Successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose()
        } else {
          toast({
            description: ERROR_PRIZE[res?.data?.message] || ERROR_PRIZE[res?.data?.messages[0]] || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
          if (res?.data?.messages[0] === 'PRIZE_ITEM_NOT_FOUND_IN_NFT_BOUNTY_BALL' || res?.data?.messages[0] === 'PRIZE_ITEM_NOT_FOUND_IN_NFT_GAUNTLET') {
            setValue("prize_items", [])
            setCheckedIds([]);
          }
        }
      } else {
        const res = await postPrize(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Prize Created Successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose()
        } else {
          toast({
            description: ERROR_PRIZE[res?.data?.message] || ERROR_PRIZE[res?.data?.messages[0]] || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
          if (res?.data?.messages[0] === 'PRIZE_ITEM_NOT_FOUND_IN_NFT_BOUNTY_BALL' || res?.data?.messages[0] === 'PRIZE_ITEM_NOT_FOUND_IN_NFT_GAUNTLET') {
            setValue("prize_items", [])
            setCheckedIds([]);
          }
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleImageChange = async (event, name) => {
    const image = event[0];
    await uploadImageToServer(image, toast, setValue, name);
    clearErrors(name);
  };

  const handleDragImage = async (file, name) => {
    if (file.length > NUMBER.ONE) {
      toast({
        description: "The number of uploaded images is greater than one.",
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    const image = file && file[0];
    await uploadImageToServer(image, toast, setValue, name);
    clearErrors(name);
  };

  const deleteImage = (name) => {
    setValue(name, null)
    clearErrors(name);
  }

  const getListFeePrize = async (type, isSetValue = true) => {
    const test = await dispatch(listFeePrizeApi(type));
    let arrMode = [];
    test?.payload?.data?.records?.map((item) => {
      return (arrMode = [...arrMode, { label: item?.name, value: item?.id, type: item?.payment_item }]);
    });
    setListFee(() => arrMode);
    isSetValue && setValue("play_fee_id", arrMode[0]?.value)
  }

  useEffect(() => {
    if (dataDetail) {
      const itemJP = prizeById?.translations?.find(
        (item) => item?.lang_key === "JP"
      );
      const listChecked = prizeById?.prize_items?.map((prize) => ({
        id: prize?.item_related_id,
        name: prize?.item_related_name,
      }))
      if (prizeById?.mode_type === "PVE_FREE_PLAY") {
        getListFeePrize("TICKET", false)
      } else if (prizeById?.mode_type === "PVE_SINGLE_PLAY") {
        getListFeePrize("ALL", false)
      } else if (prizeById?.mode_type === "CO_OP" || prizeById?.mode_type === "PVP") {
        getListFeePrize("COIN", false)
      }
      reset({
        name: dataDetail?.name,
        name_jp: itemJP?.name,
        description: dataDetail?.description,
        description_jp: itemJP?.description,
        prize_img: prizeById?.image_url,
        bg_img: prizeById?.background_image_url,
        assist_gauge_limit: prizeById?.assist_gauge_limit,
        prize_type: prizeById?.prize_type,
        allocation: prizeById?.mode_type,
        play_fee_name: prizeById?.play_fee?.name,
        play_fee_id: prizeById?.play_fee_id,
        sale: prizeById?.sale_period,
        chain: prizeById?.chain_id,
        prize_items: listChecked,
        assist: prizeById?.assist_gauge,
        start: dataDetail?.start_sale_period && prizeById?.sale_period
          ? convertToUTCTime(dataDetail?.start_sale_period)
          : dateAfterNow(1),
        end: dataDetail?.end_sale_period && prizeById?.sale_period
          ? convertToUTCTime(dataDetail?.end_sale_period)
          : dateAfterNow(2),
      });
      setNftMngType(prizeById?.prize_type)
      setCheckedIds(listChecked)
    } else {
      getListFeePrize("ALL")
      reset({
        start: dateAfterNow(1),
        end: dateAfterNow(2),
        sale: OPTION_SALE[0]?.value,
        assist_gauge_limit: 0,
        prize_items: null,
        assist: true,
      });
    }
  }, [reset, dataDetail, prizeById]);
  
  const getChainOptions = useMemo(() => {
    const isProduction = process.env.REACT_APP_ENV === "production";
    return [
      ...ALL_NFT_CHAINS.filter((chain) =>
        isProduction
          ? chain.type === CHAIN_TYPE.MAINNET
          : chain.type === CHAIN_TYPE.TESTNET
      ).map((chain) => ({
        label: chain.label,
        value: chain.chain_id.toString(),
      })),
    ];
  }, []);

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            mb={4}
            index={tabIndex}
            variant="unstyled"
            onChange={handleTabsChange}
          >
            <TabList gap={2}>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                English
              </Tab>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                Japanese
              </Tab>
            </TabList>
          </Tabs>
          <Flex direction="column" w="100%" gap={4}>
            <Grid templateColumns="repeat(1, 1fr)" gap={4}>
              <Flex direction="column" gap={4}>
                {trans === "en" ? (
                  <FormEng 
                    transLabel={transLabel}
                    handleImageChange={handleImageChange}
                    handleDragImage={handleDragImage}
                    deleteImage={deleteImage}
                    setValue={setValue}
                    idEdit={idEdit}
                    dateFormat={dateFormat}
                    watch={watch}
                    toggle={toggle}
                    checkedIds={checkedIds}
                    setNftMngType={setNftMngType}
                    prizeTypes={prizeTypes}
                    listModeType={listModeType}
                    getChainOptions={getChainOptions}
                    setCheckedIds={setCheckedIds}
                    listFee={listFee}
                    prizeById={prizeById}
                    getListFeePrize={getListFeePrize}
                    clearErrors={clearErrors}
                  />
                ) : (
                  <FormJp
                    transLabel={transLabel}
                    disableWhenJP={disableWhenJP}
                    handleImageChange={handleImageChange}
                    handleDragImage={handleDragImage}
                    deleteImage={deleteImage}
                    setValue={setValue}
                    idEdit={idEdit}
                    dateFormat={dateFormat}
                    watch={watch}
                    prizeTypes={prizeTypes}
                    listModeType={listModeType}
                    getChainOptions={getChainOptions}
                    checkedIds={checkedIds}
                    setCheckedIds={setCheckedIds}
                    listFee={listFee}
                  />
                )}
              </Flex>
            </Grid>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="700px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "Edit Prize Setup" : "New Prize Setup"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        {errors?.name_jp?.message && trans === "en" && <TextError>Japanese prize name is {errors?.name_jp?.message}</TextError>}
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
            disabled={(!checkedIds?.length && !watch("name")) || Object.keys(errors)?.length > 0}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
      {modal?.modalPrize && (
        <ModalSelectPrize
          isOpen={modal?.modalPrize}
          onClose={() => toggle('modalPrize')}
          setCheckedIds={setCheckedIds}
          checkedIds={checkedIds}
          setValue={setValue}
          watch={watch}
          idEdit={idEdit}
          nftMngType={nftMngType}
          clearErrors={clearErrors}
        />
      )}
      {modal?.modalPrizeInvitation && (
        <ModalSelectInvitation
          isOpen={modal?.modalPrizeInvitation}
          onClose={() => toggle('modalPrizeInvitation')}
          setCheckedIds={setCheckedIds}
          checkedIds={checkedIds}
          setValue={setValue}
          watch={watch}
          idEdit={idEdit}
          clearErrors={clearErrors}
          setError={setError}
        />
      )}
      {modal?.modalPrizeBackground && (
        <ModalSelectBackground
          isOpen={modal?.modalPrizeBackground}
          onClose={() => toggle('modalPrizeBackground')}
          setCheckedIds={setCheckedIds}
          checkedIds={checkedIds}
          setValue={setValue}
          watch={watch}
          idEdit={idEdit}
          clearErrors={clearErrors}
        />
      )}
      {modal?.modalPrizeDF && (
        <ModalSelectDF
          isOpen={modal?.modalPrizeDF}
          onClose={() => toggle('modalPrizeDF')}
          setCheckedIds={setCheckedIds}
          checkedIds={checkedIds}
          setValue={setValue}
          watch={watch}
          idEdit={idEdit}
          clearErrors={clearErrors}
        />
      )}
    </Modal>
  );
}
