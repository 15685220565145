import React from "react";
import NFTsFilterPanel from "./NFTsFilterPanel";

const FilterAccordion = ({
  nftMngType,
  packageOptions,
  onUpdateFilterParams,
}) => {
  return (
    // <Accordion defaultIndex={[0]} allowMultiple paddingBlock="24px">
    //   <AccordionItem>
    //     <h2>
    //       <AccordionButton>
    //         <Box flex="1" textAlign="left" fontWeight="bold">
    //           Filters
    //         </Box>
    //         <AccordionIcon />
    //       </AccordionButton>
    //     </h2>
    //     <AccordionPanel pb={4}>
    //       <NFTsFilterPanel
    //         nftMngType={nftMngType}
    //         packageOptions={packageOptions}
    //         onUpdateFilterParams={onUpdateFilterParams}
    //       />
    //     </AccordionPanel>
    //   </AccordionItem>
    // </Accordion>
    <NFTsFilterPanel
      nftMngType={nftMngType}
      packageOptions={packageOptions}
      onUpdateFilterParams={onUpdateFilterParams}
    />
  );
};

export default FilterAccordion;
