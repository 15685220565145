import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getGameMode = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.GAME_MODE}?${params}`);
};

export const getMachineByGameMode = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.MACHINE_GAME_MODE}?${params}`);
};

export const getDetailGameMode = (id) => {
  return http.get(`${END_POINT.GAME_MODE}/${id}`);
};

export const createGameMode = (data) => {
  return http.post(`${END_POINT.GAME_MODE}`, data);
};

export const updateGameMode = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.GAME_MODE}/${idEdit}`, data, option);
};

export const changeStatusGameMode = (id, data) => {
  return http.post(`${END_POINT.GAME_MODE}/update-status/${id}`, data);
};

export const deleteGameMode = (idEdit) => {
  return http.delete(`${END_POINT.GAME_MODE}/${idEdit}`);
};

export const getCategoryPrize = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.CATEGORY_PRIZE}?${params}`);
};

export const createCategoryPrize = (data) => {
  return http.post(`${END_POINT.CATEGORY_PRIZE}`, data);
};

export const updateStatusCategoryPrize = (idEdit, data) => {
  return http.post(`${END_POINT.CATEGORY_PRIZE}/update-status/${idEdit}`, data);
};

export const updateCategoryPrize = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.CATEGORY_PRIZE}/${idEdit}`, data, option);
};

export const deleteCategoryPrize = (data) => {
  return http.delete(`${END_POINT.CATEGORY_PRIZE}/delete`, data);
};
