import { Box, Flex, Image, Text, Tooltip } from '@chakra-ui/react'
import Question from 'assets/svg/question.svg'
import ArrowDown from 'assets/svg/arrow_down.svg'
import ArrowUp from 'assets/svg/arrow_up.svg'
import React from 'react'

export default function ItemStatistics(props) {
  const { title, amount, content, percent } = props

  return (
    <Flex
      padding="14px"
      borderRadius='8px'
      border='1px solid #ccc'
      direction='column'
      gap='8px'
      background='#fff'
      w='100%'
    >
      <Flex alignItems='center' justifyContent='space-between'>
        <Text fontSize="16px" color='#777777'>{title}</Text>
        <Tooltip 
          label={(
            <Box background="#fff">
              <Text color='#000' fontWeight='700' w='100%' borderBottom='1px solid #ccc'>{title}</Text>
              <Text color='#000' fontWeight='400' mt={2}>{content}</Text>
            </Box>
          )}
          placement="top"
          backgroundColor='#fff'
          padding='8px'
          borderRadius='8px'
          boxShadow='2xl'
        >
          <Image
            src={Question}
            w='20px'
            borderRadius='12px'
          />
        </Tooltip>
      </Flex>
      <Text color='#000' fontWeight='700'>{amount}</Text>
      <Flex 
        padding='2px 6px' 
        borderRadius='8px'
        backgroundColor={Number(percent) >= 0 ? '#d6ffd4' : '#ffe0e3'}
        maxWidth='max-content'
      >
        {Number(percent) >= 0 ? (
          <Image
            src={ArrowUp}
            w='20px'
            borderRadius='12px'
          />
        ) : (
          <Image
            src={ArrowDown}
            w='20px'
            borderRadius='12px'
          />
        )}
        <Text 
          fontSize='14px'
          color={Number(percent) >= 0 ? '#5ce057' : '#d13a47'}
        >
          {Number(percent) >= 0 ? `+${Math.round(percent)}%` : `${Math.round(percent)}%`}
        </Text>
      </Flex>
    </Flex>
  )
}
