import { useCallback, useEffect, useState } from "react";
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import ItemsSendRow from "./row";
import ConditionLeaderBoard from "./action";
import ButtonType from "components/Button";
import { listPrizesGameModeApi } from "stores/prize/action";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { prizeSelector } from "stores/prize/selector";

export default function ModalSelectPrize({
  isOpen,
  onClose,
  setCheckedIds,
  checkedIds,
  type,
  watch,
  idModeDetail,
  setValue,
  prizeTypes,
  prizeType,
  prizeStatus = "AVAILABLE",
  clearErrors,
  categoryPrizeId,
  isFreeTicket
}) {
  const labels = [
    "Name",
    "Image",
    "Type",
    "Play Fee",
    "Min. Attempt",
    "Sale Status",
    "Sale Start Time",
    "Sale End Time",
    // "Action",
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [prizeItems, setPrizeItems] = useState(checkedIds);
  const [nftDetail, setNftDetail] = useState()
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    prize_status: prizeStatus,
    mode_type: type,
    play_fee_id: watch("play_fee_id"),
    game_mode_id: idModeDetail,
    category_prize_id: categoryPrizeId,
    prizeType: prizeType
  });
  const dispatch = useDispatch();
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const { listPrizesGameMode } = useSelector(prizeSelector);

  const {
    isOpen: isOpenDetailItem,
    onOpen: onOpenDetailUser,
    onClose: onCloseDetailItem,
  } = useDisclosure();

  const handleOpenDetailUserModal = async (item) => {
    setNftDetail(item)
    onOpenDetailUser();
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };
  
  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  // const getEvent = useCallback(
  //   async (newParams = {}) => {
  //     try {
  //       setIsLoading(true);
  //       const data = await dispatch(listPrizesGameModeApi({
  //         ...params,
  //         ...newParams,
  //         mode_type: type,
  //         // play_fee_id: watch("play_fee_id"),
  //       }));
  //       if (data?.payload?.success) {
  //         const res = data?.data;
  //         console.log("🚀 ~ res:", res)
  //         setItems(res?.records);
  //         setTotalPage(res?.total_page);
  //       }
  //     } catch (error) {
  //       toast({
  //         description: error?.message || error?.messages[0],
  //         status: "error",
  //         position: "bottom-right",
  //       });
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   },
  //   [params]
  // );

  const getListPrize = () => {
    dispatch(listPrizesGameModeApi(params));
  };

  useEffect(() => {
    getListPrize();
  }, [params]);

  useEffect(() => {
    if (listPrizesGameMode) {
      setItems(() => listPrizesGameMode?.records || []);
      setTotalPage(listPrizesGameMode?.total_page || 0);
    }
  }, [listPrizesGameMode]);

  // useEffect(() => {
  //   getEvent();
  // }, [getEvent]);

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setPrizeItems(items);
      setValue("prize_items", items)
    } else {
      setPrizeItems([]);
      setValue("prize_items", [])
    }
  };

  const handleClose = () => {
    setItems([])
    onClose()
  }

  const handleSelect = () => {
    onClose()
    setValue("prize_items", prizeItems)
    setCheckedIds(prizeItems);
    clearErrors("prize_items")
    setItems([])
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} closeOnOverlayClick={false} size="xxl">
      <ModalOverlay />
      <ModalContent maxW="900px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          Select Prize(s)
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">
          <Flex flexDirection="column">
            <Card px="0px">
              <CardHeader display="flex" flexDirection="column" gap="12px" mb="24px">
                {type !== 'PVP' && (
                  <ConditionLeaderBoard
                    isLoading={isLoading}
                    setParams={setParams}
                    params={params}
                    prizeTypes={prizeTypes}
                  />
                )}
              </CardHeader>
              <CardBody overflowX="auto">
                <TableCustom
                  labels={labels}
                  isLoading={isLoading}
                  dataRow={items}
                  isCheck
                  isChecked={
                    items?.length > 0 && prizeItems.length === items?.length
                  }
                  showCheck={(type === 'PVE_FREE_PLAY' || type === "PVP" || isFreeTicket) ? false : true}
                  onChangeChecked={onChangeChecked}
                >
                  <>
                    {items?.map((item) => {
                      return (
                        <ItemsSendRow
                          key={item?.id}
                          item={item}
                          setCheckedIds={setPrizeItems}
                          checkedIds={prizeItems}
                          setValue={setValue}
                          type={type}
                          handleOpenDetailUserModal={handleOpenDetailUserModal}
                          isFreeTicket={isFreeTicket}
                        />
                      );
                    })}
                  </>
                </TableCustom>
              </CardBody>
              <Paginate
                page={params?.page}
                pageCount={totalPage}
                limit={params.limit}
                onChangeLimit={onChangeLimit}
                handleChangePage={handleChangePage}
              />
            </Card>
          </Flex>
        </ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            m="auto"
            w="160px"
            h="46px"
            borderRadius="5px"
            disabled={!prizeItems?.length}
            onClick={handleSelect}
          >
            Select
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
  