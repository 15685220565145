import END_POINT from "./constants";
import http from "./http";
import { convertParams, omitParams } from "utils/object";

export function getInvitationCard(p = {}) {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.INVITATION_CARD}?${params}`);
}

export const getListPresentBox = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.PRESENT_BOX}?${params}`);
};

export const createPresentBox = (data, option = {}) => {
  return http.post(END_POINT.PRESENT_BOX, data, option);
};

export function putPresentBox(id, data) {
  return http.put(`${END_POINT.PRESENT_BOX}/${id}`, data);
}

export const deletePresentBox = (ids, option = {}) => {
  return http.delete(`${END_POINT.PRESENT_BOX}`, ids, option);
};

export function getPresentBoxById(id) {
  return http.get(`${END_POINT.PRESENT_BOX}/${id}`);
}

export function getEventById(id) {
  return http.get(`${END_POINT.EVENT}/${id}`);
}

export function getEventsInComing() {
  return http.get(`${END_POINT.EVENT_IN_COMING}`);
}

export function checkUserValid(data) {
  return http.post(END_POINT.EXIST_USER, data, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
}

export function getUserInfo(p = {}) {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.USER_INFO}?${params}`);
}

export function putTriggerPresentBox(id) {
  return http.put(`${END_POINT.PRESENT_BOX_TRIGGER}/${id}`);
}

export const getListPresentBoxHistory = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.PRESENT_BOX_HISTORY}?${params}`);
};

export const getDetailEventManagement = (id) => {
  return http.get(`${END_POINT.EVENT}/${id}`);
};
