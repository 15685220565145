import { Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { getListBanner } from "api/ads.api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import { FIRST_PAGE, LIMIT_PAGE } from "constants/constants";
import { useCallback, useEffect, useState } from "react";
import ActionAds from "./action";
import ConditionAds from "./condition";
import CreateAndUpdateBanner from "./CreateAndUpdateBanner";
import BannerManagementRow from "./row";
import DetailBanner from "./detail"

export default function AdsManagement() {
  const [banner, setBanner] = useState([]);
  const [dataDetail, setDataDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: FIRST_PAGE,
    limit: LIMIT_PAGE,
  });
  const toast = useToast();

  const {
    isOpen: isOpenCreateAndUpdate,
    onOpen: onOpenCreateAndUpdate,
    onClose: onCloseCreateAndUpdate,
  } = useDisclosure();

  const {
    isOpen: isOpenDetail,
    onOpen: onOpenDetail,
    onClose: onCloseDetail,
  } = useDisclosure();

  const getBanner = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await getListBanner(params);
      if (data?.success) {
        const res = data?.data;
        setBanner(res?.records);
        setTotalPage(res?.total_page);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  }, [params]);

  useEffect(() => {
    getBanner();
  }, [params]);

  const handleOpenCreateOrUpdate = async (data = null) => {
    if(
      Date.now() > data?.end_date * 1000 ||
      data?.start_date * 1000 <= Date.now() &&  data?.end_date * 1000 >= Date.now()
    ) return;

    await setDataDetail(data);
    onOpenCreateAndUpdate();
  };

  const handleSetDataDetail = async (data = null) => {
    await setDataDetail(data);
    onOpenDetail();
  };

  const handleCloseCreateOrUpdate = () => {
    onCloseCreateAndUpdate();
    setDataDetail(null);
  };

  const handleClose = () => {
    onCloseDetail();
    setDataDetail(null);
  };

  const handleCreateOrUpdateSuccess = useCallback(() => {
    handleCloseCreateOrUpdate();
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedIds(banner.map((banner) => banner.id));
    } else {
      setCheckedIds([]);
    }
  };

  const labels = [
    "ID",
    "IMAGE",
    "PLACEMENT",
    "dISPLAY PERIOD",
    "TAP ACTIONS",
    "",
  ];

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="24px">
        <CardHeader mb="32px">
          <ConditionAds
            isLoading={isLoading}
            setParams={setParams}
            params={params}
          />
        </CardHeader>
        <CardHeader mb="12px">
          <Flex
            w="100%"
            gap={4}
            direction={{
              md: "row",
            }}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Flex w="100%" justifyContent="flex-end" alignItems="flex-start">
              <ActionAds
                handleOpenCreateOrUpdate={() => handleOpenCreateOrUpdate()}
                checkedIds={checkedIds}
                setCheckedIds={setCheckedIds}
                setParams={setParams}
                params={params}
              />
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck
            isChecked={
              banner?.length > 0 && checkedIds.length === banner?.length
            }
            dataRow={banner}
            onChangeChecked={onChangeChecked}
          >
            <>
              {banner?.map((item) => {
                return (
                  <BannerManagementRow
                    key={item?.id}
                    itemBanner={item}
                    checkedIds={checkedIds}
                    setCheckedIds={setCheckedIds}
                    handleOpenCreateOrUpdate={() =>
                      handleOpenCreateOrUpdate(item)
                    }
                    handleSetDataDetail={() => {
                      handleSetDataDetail(item);
                    }}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
      {isOpenCreateAndUpdate && (
        <CreateAndUpdateBanner
          isOpen={isOpenCreateAndUpdate}
          onClose={onCloseCreateAndUpdate}
          dataDetail={dataDetail}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
        />
      )}
      {isOpenDetail && (
        <DetailBanner
          isOpen={isOpenDetail}
          onClose={handleClose}
          dataDetail={dataDetail}
        />
      )}
    </Flex>
  );
}
