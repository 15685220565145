import { Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { deleteInquiries } from "api/system";
import ButtonType from "components/Button";
import ModalConfirm from "components/Modal/ModalConfirm";
import { useMemo } from "react";

export default function ActionInquiries({ handleOpenCreateOrUpdate, checkedIds, setCheckedIds, handleCreateOrUpdateSuccess }) {
  const toast = useToast();

  const isHaveCheckedIds = useMemo(() => {
    return checkedIds?.length > 0;
  }, [checkedIds]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDelete = async () => {
    try {
      if (isHaveCheckedIds) {

        const listData =  checkedIds?.map((check) => check?.id)
        const res = await deleteInquiries({
          ids: listData
        });
        if (res?.data?.success) {
          setCheckedIds([]);
          toast({
            title: "Delete success.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess()
          onClose();
        }
      }
    } catch (error) {
      onClose();
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Flex
      gap={4}
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="flex-end"
    >
      <Flex alignItems="flex-end" gap={4} mb="10px">
        <ButtonType
          text="New"
          fontSize="16px"
          w="120px"
          onClick={() => handleOpenCreateOrUpdate()}
        />
        <ButtonType
          text="DELETE"
          fontSize="12px"
          w="120px"
          btnType="danger"
          disabled={!isHaveCheckedIds}
          onClick={onOpen}
        />
      </Flex>
      <ModalConfirm isOpen={isOpen} onClose={onClose} onSubmit={handleDelete} />
    </Flex>
  );
}
