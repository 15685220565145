import { checkMaxImage } from "utils/uploadFile";
import { postUploadImage } from "api/prize.api";

export const uploadImageToServer = async (
  image,
  toast,
  setValue,
  fieldName = "icon_url",
  setNameImage,
  typeAccept = /\.(jpg|jpeg|png|JPG|JPEG|PNG)$/,
  isValidate = true,
) => {
  if (image && !image.name.match(typeAccept)) {
    toast({
      description: "The image format is incorrect.",
      status: "error",
      position: "bottom-right",
    });
    return false;
  } else {
    if (image?.size > 204800 && isValidate) {
      toast({
        description: "Image must be smaller than 200KB.",
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
  }
  try {
    const request = new FormData();
    request.append("file", image);
    request.append("name", image?.name || "image");
    const { data } = await postUploadImage(request);
    if (data?.code === 200) {
      if (setNameImage) {
        setNameImage(data?.data?.name);
      }
      setValue(fieldName, data?.data?.url);
      return data?.data?.url;
    }
  } catch (error) {
    toast({
      description: error?.message || error?.messages[0],
      status: "error",
      position: "bottom-right",
    });
  }
};
