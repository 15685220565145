import { containSpecialUserNameJapanRegex } from "utils/regex";
import * as yup from "yup";

const schemaInvitationCard = () =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .required("Required")
      .matches(containSpecialUserNameJapanRegex, "Name should not contain special characters")
      .max(30, "Maximum limit of 30 characters."),
    name_jp: yup
      .string()
      .trim()
      .required("Required")
      .matches(containSpecialUserNameJapanRegex, "Name should not contain special characters")
      .max(30, "Maximum limit of 30 characters."),
    description: yup
      .string()
      .trim()
      .notRequired()
      .max(300, "Description must be at most 300 characters"),
    description_jp: yup
      .string()
      .trim()
      .notRequired()
      .max(300, "Description must be at most 300 characters"),
    url_image: yup
      .string()
      .trim()
      .required("Required"),
  });

export default schemaInvitationCard;
