export const STATE_SUMMARY_MACHINE = {
  FREE: "Free",
  PLAYING: "Playing",
  PRIZE_ACQUIRED: "Preparing Prize",
  PRIZE_CONFIRMATION_REQUEST: "Confirming Prize",
  UNDER_MAINTENANCE: "Maintenance",
  ERROR: "Error",
  RESTARTING: "Restarting",
  INACTIVE: "Inactive",
  ACTIVE: "Active",
};