import { Flex } from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import { FormProvider, useForm } from "react-hook-form";
import { STATUS_EVENT } from "views/Event/constant";

export default function ConditionGachaManagement({
  isLoading,
  params,
  setParams,
  statusGacha,
}) {
  const form = useForm();
  const { setValue, handleSubmit } = form;

  const onSubmit = (data) => {
    if (!isLoading) {
      const newParams = {
        ...params,
        ...data,
        page: 1,
      };
      setParams(newParams);
    }
  };

  return (
    <Flex
      gap={4}
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="flex-end"
    >
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex gap={4}>
            <FormInput
              wrapperProps={{
                w: "300px",
              }}
              maxW="100%"
              name="name"
              label="Search"
              placeholder={"Search by event name"}
            />
            <FormSelect
              wrapperProps={{
                w: "300px",
              }}
              name="status"
              label="Filter by Status"
              defaultValue={""}
              w="100%"
              options={[{ label: "All", value: "" }, ...statusGacha]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("status", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
}
