import {
  Flex,
  Grid,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {getDetailGameMode, getGameMode, getMachineByGameMode, updateGameMode } from "api/gameMode.api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import useWithToast from "hooks/useWithToast";
import { useCallback, useEffect, useState } from "react";
import GameModeRow from "./row";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import schemaFreeplay from "./schema";
import ButtonType from "components/Button";
import FormInput from "components/form/FormInput";
import ModalSelectPrize from "../Common/SelectPrize";
import FormSelect from "components/form/FormSelect";
import { getPlayFees } from "api/playFee.api";
import { convertParams, omitParams } from "utils/object";

export default function Freeplay({ type }) {
  const [isLoading, setIsLoading] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [listPlayFee, setListPlayFee] = useState([]);
  const [modeDetail, setModeDetail] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const toast = useToast();
  const { showToastSuccess, showToastFail } = useWithToast();
  const [gamesMode, setGamesMode] = useState([]);

  const labels = [
    "Mac Address",
    "Booth No.",
    "Image",
    "State",
    "Booth type",
    "Gameplay Type",
    "Connect Status",
  ];
  const textColor = useColorModeValue("#000000", "white");
  
  const form = useForm({
    resolver: yupResolver(schemaFreeplay()),
  });

  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    clearErrors,
    formState: { isSubmitting },
  } = form;

  const handleCreateOrUpdateSuccess = useCallback(() => {
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);

  const getListMachineFreePlay = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getMachineByGameMode({
          ...params,
          ...newParams,
          mode_type: type,
        });
        if (data?.success) {
          const res = data?.data;
          setGamesMode(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const getListPlayFee = async() => {
    try {
      setIsLoading(true);
      const params = convertParams(omitParams({
        "payment_type": "TICKET",
        status: "ACTIVE",
      }));
      const { data } = await getPlayFees(params);
      if (data?.success) {
        const res = data?.data?.records?.map((data) => ({ label: data?.name, value: data?.id }));
        setListPlayFee(res)
        setValue("play_fee_id", res[0]?.value)
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getGameModeById = async(id) => {
    try {
      setIsLoading(true);
      const { data } = await getDetailGameMode(id);
      if (data?.success) {
        const listPrize = data?.data?.prize_items?.map(item => ({
          ...item,
          id: item?.prize_id,
        }))
        reset({
          life_gauge: data?.data?.life_gauge_consumption,
          limit_plays: data?.data?.limit_plays_per_day,
          prize_items: listPrize,
          play_fee_id: data?.data?.play_fee_id,
        });
        setCheckedIds(listPrize)
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getModeDetail = async() => {
    try {
      setIsLoading(true);
      const { data } = await getGameMode({
        page: 1,
        limit: 999,
        mode_type: type,
      });
      if (data?.success) {
        getGameModeById(data?.data?.records[0]?.id)
        setModeDetail(data?.data?.records[0]?.id)
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  useEffect(() => {
    getListMachineFreePlay();
  }, []);

  useEffect(() => {
    getListPlayFee();
    getModeDetail()
  }, [])

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };
  
  const onSubmit = async (data, e) => {
    const listPrizes = data?.prize_items?.map((item) => ({
      prize_id: item?.id,
      prize_type: item?.prize_type,
    }))
    const dataSubmit = {
      limit_plays_per_day: data?.limit_plays,
      life_gauge_consumption: data?.life_gauge,
      play_fee_id: data?.play_fee_id,
      mode_type: "PVE_FREE_PLAY",
      prizes: listPrizes,
      translations: []
    };
    try {
      const res = await updateGameMode(modeDetail, dataSubmit);
      if (res?.data?.success) {
        showToastSuccess({
          title: "Updated Successfully!",
        });
        getGameModeById(modeDetail)
      } else {
        showToastFail({
          title: "Updated fail!",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  // const filterChecked = (itemRemove) => {
  //   const dataFilter = checkedIds?.filter((check) => check?.id !== itemRemove?.id)
  //   setCheckedIds(dataFilter)
  // }

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="modeFreeForm" onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <FormSelect
              name="play_fee_id"
              label="Play fee"
              defaultValue={listPlayFee[0]?.value}
              w="49%"
              options={listPlayFee}
              isRequired
            />
            <FormInput
              type="text"
              label="Prize(s)"
              name="prize_items"
              value=""
              placeholder="Select prize(s) for game mode"
              autocomplete="off"
              onClick={() => onOpen()}
            />
            {checkedIds?.length > 0 && !isOpen && (
              <Flex gap="4px">
                {checkedIds?.map((check, index) => (
                  <Flex
                    key={index}
                    padding="4px 8px"
                    borderRadius="6px"
                    background="#faf871"
                    alignItems="center"
                  >
                    <Text fontSize="14px" fontWeight="400">{check?.prize_name || check?.name}</Text>
                    {/* <SmallCloseIcon onClick={() => filterChecked(check)}/> */}
                  </Flex>
                ))}
              </Flex>
            )}
            <Flex alignItems="center" gap="20px" w="100%">
              <FormInput
                wrapperProps={{
                  w: "100%"
                }}
                type="number"
                label="Life gauge consumed"
                placeholder="Enter a number"
                name="life_gauge"
              />
              <FormInput
                wrapperProps={{
                  w: "100%"
                }}
                type="number"
                label="Limit plays per day"
                placeholder="Enter a number"
                name="limit_plays"
              />
            </Flex>
          </Grid>
          <ButtonType
            mt={5}
            type="submit"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="modeFreeForm"
            isLoading={isSubmitting}
          >
            Save
          </ButtonType>
        </form>
      </FormProvider>
    );
  };

  return (
    <Flex flexDirection="column">
      <Card px="0px" pt="0">
        <CardHeader px="22px" mb="20px" w="100%">
          <Flex
            w="100%"
            gap={4}
            direction={{
              base: "column",
              md: "row",
            }}
            justifyContent={{
              base: "flex-start",
              md: "space-between",
            }}
            alignItems="center"
          >
            <Text
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              whiteSpace="nowrap"
            >
              Setup
            </Text>
          </Flex>
        </CardHeader>
        <CardBody p="24px" pt="0">{renderForm()}</CardBody>
      </Card>
      <Card px="0px" pt="0">
        <CardHeader px="22px" mb="20px" w="100%">
          <Flex
            w="100%"
            gap={4}
            direction={{
              base: "column",
              md: "row",
            }}
            justifyContent={{
              base: "flex-start",
              md: "space-between",
            }}
            alignItems="center"
          >
            <Text
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              whiteSpace="nowrap"
            >
              List mode-specific machines
            </Text>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck={false}
            dataRow={gamesMode}
          >
            <>
              {gamesMode?.map((item, index) => {
                return (
                  <GameModeRow
                    key={item?.id}
                    index={(params?.page - 1) * params?.limit + index + 1}
                    item={item}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
      {isOpen && (
        <ModalSelectPrize
          isOpen={isOpen}
          onClose={onClose}
          setCheckedIds={setCheckedIds}
          checkedIds={checkedIds}
          type={type}
          watch={watch}
          idModeDetail={modeDetail}
          setValue={setValue}
          clearErrors={clearErrors}
        />
      )}
    </Flex>
  );
}
