import * as yup from "yup";

const schemaInquiries = () =>
  yup.object().shape({
    inquiry_en: yup
      .string()
      .trim()
      .required("Required"),
    inquiry_jp: yup
      .string()
      .trim()
      .required("Required"),
  });

export default schemaInquiries;
