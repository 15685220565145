/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { prizeSelector } from "stores/prize/selector";
import { STATUS_PRIZE, TYPE_PRIZE } from "constants/constants";
import ImageDefault from "assets/img/empty-image.png";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { getDayLLLL } from "utils/time";
import { getChainIcon } from "views/NFTsManagement/utils/utils";
import { MODE_TYPE_SHOW } from "views/Machine/MachineSettings/constants";

export default function PrizeDetail({ isOpen, onClose }) {
  const textColor = useColorModeValue("#000000", "white");
  const { prizeById, loadingPrize } = useSelector(prizeSelector);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent
        position="relative"
        minH="828px"
        minW="500px"
        maxW='1000px'
        overflowY="auto"
      >
        {loadingPrize ? (
          <Box
            position="absolute"
            top={0}
            left={0}
            bottom={0}
            w="100%"
            minH="600px"
          >
            <LoadingSpinner />
          </Box>
        ) : (
          <>
            <ModalHeader margin="auto" color={textColor}></ModalHeader>
            <ModalCloseButton />
            <ModalBody minW="500px">
              <VStack spacing={4} align="stretch">
                <Grid templateColumns="1fr 2fr" gap={4}>
                <Box
                  position="relative"
                  backgroundPosition="center"
                  backgroundRepeat="no-repeat"
                  backgroundSize="contain"
                  borderRadius="md"
                  overflow="hidden"
                >
                  <Image
                    src={prizeById?.image_url}
                    alt={prizeById?.nft_information?.name}
                    objectFit="contain"
                    w="60%"
                    h="100%"
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                  />
                </Box>
                  <VStack align="start" spacing={2}>
                    <Flex borderBottom="1px solid #6abcff" width="100%" alignItems='center' justifyContent='space-between' pb="2px">
                      <Text color="#6abcff" fontSize='16px' fontWeight={600}>{prizeById?.name}</Text>
                      <Flex alignItems='center' gap="8px">
                        <Text
                          padding="2px 8px"
                          borderRadius="12px"
                          background="#3381c2"
                          color="#fff"
                          fontSize='14px'
                        >
                          {TYPE_PRIZE[prizeById?.prize_type]}
                        </Text>
                        {prizeById?.chain_id && (
                          <Image
                            boxSize="30px"
                            src={getChainIcon(prizeById?.chain_id).src}
                            alt={getChainIcon(prizeById?.chain_id).alt}
                          />
                         )}
                      </Flex>
                    </Flex>4
                    <Grid templateColumns="repeat(2, 1fr)" gap={2} columnGap={6}>
                      <Text fontWeight="bold" color={textColor}>
                        Allocation:{" "}
                        <Text
                          as="span"
                          color={"green"}
                        >
                          {MODE_TYPE_SHOW[prizeById?.mode_type]}
                        </Text>
                      </Text>
                      <Text fontWeight="bold" color={textColor}>
                        Status:{" "}
                        <Text
                          as="span"
                          color="red.500"
                        >
                          {STATUS_PRIZE[prizeById?.prize_status]}
                        </Text>
                      </Text>
                      <Text fontWeight="bold" color={textColor}>
                        Play fee:{" "}
                        <Text
                          as="span"
                          color="#6abcff"
                        >
                          {prizeById?.play_fee?.name}
                        </Text>
                      </Text>
                      <Text></Text>
                      <Text fontWeight="bold" color={textColor}>
                        Assist Gauge: {" "}
                        <Text
                          as="span"
                          border="1px solid #000"
                          px="6px"
                          borderRadius="12px"
                        >
                          {prizeById?.assist_gauge ? "Enable" : "disabled"}
                        </Text>
                      </Text>
                      <Text fontWeight="bold" color={textColor}>
                        Attempt: {" "}
                        <Text
                          as="span"
                          color="#6abcff"
                        >
                          {prizeById?.assist_gauge_limit || '--'}
                        </Text>
                      </Text>
                    </Grid>
                    <Text fontWeight="bold" color={textColor}>
                      Sale start time : {" "}
                      <Text
                        as="span"
                        color="#6abcff"
                      >
                        {prizeById?.start_sale_period
                          ? getDayLLLL(prizeById?.start_sale_period * 1000)
                          : "---"}
                      </Text>
                    </Text>
                    <Text fontWeight="bold" color={textColor}>
                      Sale end time : {" "}
                      <Text
                        as="span"
                        color="#6abcff"
                      >
                        {prizeById?.end_sale_period
                          ? getDayLLLL(prizeById?.end_sale_period * 1000)
                          : "---"}
                      </Text>
                    </Text>
                  </VStack>
                </Grid>
              </VStack>
              <Flex direction='column' px="12px" gap="8px" mt={4}>
                <Text fontWeight={600}>Description</Text>
                <Text>{prizeById?.description}</Text>
              </Flex>
              <Flex direction='column' px="12px" gap="8px" mt={4}>
                <Text fontWeight={600}>Background Image</Text>
                <Box w="100%" h="200px" backgroundSize="cover">
                  <Image
                    src={prizeById?.background_image_url || ImageDefault}
                    w="100%"
                    h="100%"
                    objectFit='contain'
                    loading="lazy"
                  />
                </Box>
              </Flex>
              <Flex direction='column' px="12px" gap="8px" mt={4}>
                <Text fontWeight={600}>Prize Item(s)</Text>
                <Flex gap="4px" flexWrap='wrap'>
                  {prizeById?.prize_items?.map((check, index) => (
                    <Flex
                      key={index}
                      padding="6px 10px"
                      borderRadius="12px"
                      background="rgb(0, 129, 214)"
                      alignItems="center"
                      color="#fff"
                    >
                      <Text fontSize="14px" fontWeight="400">{check?.item_related_name}</Text>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
