import {
  Flex,
} from "@chakra-ui/react";
import StatisticsSetting from "./Statistics";
import MachineDashboard from "./MachineDashboard";
import ActiveUsers from "./ActiveUsers";
import ActiveEvent from "./ActiveEvent";

export default function DashboardPage() {

  return (
    <Flex flexDirection="column" gap={6} pt={{ base: "120px", md: "75px" }}>
      <StatisticsSetting />
      <MachineDashboard />
      <ActiveEvent />
      <ActiveUsers />
    </Flex>
  );
}
