import { useCallback, useEffect, useState } from "react";
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import ItemsSendRow from "./row";
import ButtonType from "components/Button";
import { getBackground } from "api/digitaFigure.api";
import DetailViewBg from "views/DigitalFigure/DFViewBG/DetailViewBg";
import ItemsBackgroundRow from "./row";

export default function ModalSelectBackground({
  isOpen,
  onClose,
  setCheckedIds,
  checkedIds,
  setValue,
  watch,
  idEdit,
  clearErrors,
}) {
  const labels = [
    "Image",
    "Name",
    "RELATED DIGITAL FIGURE",
    "Count",
    "",
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [prizeItems, setPrizeItems] = useState(checkedIds);
  const [totalPage, setTotalPage] = useState(0);
  const [dataDetail, setDataDetail] = useState();
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");

  const {
    isOpen: isOpenDetailItem,
    onOpen: onOpenDetailUser,
    onClose: onCloseDetailItem,
  } = useDisclosure();

  const handleOpenDetailUserModal = async (item) => {
    setDataDetail(item)
    onOpenDetailUser();
  };
  

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };
  
  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const getInvitationIncoming = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getBackground({
          ...params,
          ...newParams,
          "allocation": 'PRIZE',
          prize_id: idEdit ? idEdit : "",
          query_prize: true
        });
        if (data?.success) {
          const res = data?.data;
          setItems(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  useEffect(() => {
    getInvitationIncoming();
  }, [getInvitationIncoming]);

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      const r = items.filter((elem) => !prizeItems.find(({ id }) => elem.id === id))
      setPrizeItems([...prizeItems, ...r]);
    } else {
      const uniqueArray1 = prizeItems.filter(item1 => 
        !items.some(item2 => item2.id === item1.id)
      );
      setPrizeItems(uniqueArray1);
    }
  };

  const handleClose = () => {
    setItems([])
    onClose()
  }

  const handleSelect = () => {
    onClose()
    setValue("prize_items", prizeItems)
    setCheckedIds(prizeItems);
    clearErrors("prize_items")
  }

  const bgFeature = (type) => {
    switch (type) {
      case "3D":
        return "#fffd77"
      case "AR":
        return "#b8ffa2"
      case "ROOM":
        return "#d096ff"
      case "HoloModel":
        return "#ffabff"
      default:
        return "#ccc"
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} closeOnOverlayClick={false} size="xxl">
      <ModalOverlay />
      <ModalContent maxW="900px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          Select Item(s)
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">
          <Flex flexDirection="column">
            <Card px="0px">
              <CardBody overflowX="auto">
                <TableCustom
                  labels={labels}
                  isLoading={isLoading}
                  dataRow={items}
                  isChecked={items?.length > 0 && prizeItems?.length > 0 && items.every(item => prizeItems.find(prize => item.id === prize.id))}
                  onChangeChecked={onChangeChecked}
                  showCheck={false}
                >
                  <>
                    {items?.map((item) => {
                      return (
                        <ItemsBackgroundRow
                          key={item?.id}
                          item={item}
                          setCheckedIds={setPrizeItems}
                          checkedIds={prizeItems}
                          watch={watch}
                          handleOpenDetailUserModal={handleOpenDetailUserModal}
                        />
                      );
                    })}
                  </>
                </TableCustom>
              </CardBody>
              <Paginate
                page={params?.page}
                pageCount={totalPage}
                limit={params.limit}
                onChangeLimit={onChangeLimit}
                handleChangePage={handleChangePage}
              />
            </Card>
          </Flex>
        </ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            m="auto"
            w="160px"
            h="46px"
            borderRadius="5px"
            disabled={!prizeItems?.length}
            onClick={handleSelect}
          >
            Select
          </ButtonType>
        </ModalFooter>
      </ModalContent>
      {isOpenDetailItem && (
        <DetailViewBg
          isOpen={isOpenDetailItem}
          onClose={onCloseDetailItem}
          item={dataDetail}
          bgFeature={bgFeature}
        />
      )}
    </Modal>
  );
}
  