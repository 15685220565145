import { containSpecialUserNameRegex, containSpecialUserNameJapanRegex } from "utils/regex";
import * as yup from "yup";

const schemaStandardEvent = () =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .matches(containSpecialUserNameRegex, "Name should not contain special characters")
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    name_jp: yup
      .string()
      .trim()
      .required("Required")
      .matches(containSpecialUserNameJapanRegex, "Name should not contain special characters")
      .max(255, "Maximum limit of 255 characters."),
    image_url: yup
      .string()
      .trim()
      .required("Required"),
    asset_url: yup
      .string()
      .trim()
      .required("Required"),
    serial_code: yup
      .array()
      .nullable()
      .min(1, 'Required'),
    count: yup
      .number()
      .required("Required")
      .max(99999999, "Count must be at most 8 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("Must be an integer")
      .positive("Count must be greater than 0"),
    restricted_countries: yup
      .array()
      .min(1, 'Required'),
  });

export default schemaStandardEvent;
