export const PRIZE_TYPE = [
  { value: "GAUNTLET", label: "Gauntlet" },
  { value: "TREASURE_BOX", label: "Treasure Box" },
  { value: "BOUNTY_BALL", label: "Bounty Ball" },
]

export const LIST_CHAIN = [
  { value: "137", label: "137" },
  { value: "80002", label: "80002" },
  { value: "13473", label: "13473" },
]

export const LIST_ALLOCATION = [
  { value: "FREE_PLAY", label: "Free Play" },
  { value: "SINGLE_PLAY", label: "Single Play" },
  { value: "BATTLE_ARENA", label: "Battle Arena" },
  { value: "CO_OP", label: "Co-op" },
]

export const LIST_PLAY_FEE = [
  { value: "50_B_COIN", label: "50 BCOIN" },
  { value: "100_B_CON", label: "100 BCOIN" },
]

export const RARITY = {
  COMMON: "Common",
  UNCOMMON: "Uncommon",
  EPIC: "Epic",
  RARE: "Rare",
  LEGENDARY: "Legendary",
  OFF_CHAIN: "Off Chain",
};

export const TRAIT = {
  ARENA_HUNTER: "Arena Hunter",
  MECHANIC: "Mechanic",
  MERCHANT: "Merchant",
  PRIZE_HUNTER: "Prize Hunter",
  TREASURE_HUNTER: "Treasure hunter",
};

export const OPTION_SALE = [
  { value: true, label: "Sale" },
  { value: false, label: "None" },
]

export const OPTION_SALE_JP = [
  { value: false, label: "なし" },
  { value: true, label: "販売" },
]

export const gaugeOptions = [
  {
    value: true,
    label: "Enable",
  },
  {
    value: false,
    label: "Disable",
  },
]

export const gaugeOptionsJp = [
  {
    value: true,
    label: "有効",
  },
  {
    value: false,
    label: "無効",
  },
]

export const ERROR_PRIZE = {
  PRIZE_NAME_ALREADY_EXISTS: "Prize name already exists",
  CHAIN_ID_REQUIRED : "Chain ID required",
  PRIZE_FOR_MODE_TYPE_FREE_PLAY_ALREADY_EXISTS : "Prize for mode type free play already exists",
  PRIZE_ITEM_NOT_FOUND_IN_NFT_BOUNTY_BALL : "Prize item not found in NFT bounty ball",
  PRIZE_NOT_FOUND : "Prize not found",
  PRIZE_ITEM_ALREADY_EXISTS: "Prize item already exists",
  PRIZE_ITEM_TREASURE_BOX_ONLY_ONE: "Prize item treasure box only one",
  PRIZE_ITEM_INVITATION_CARD_ONLY_ONE: "Prize item invitation card only one",
  PRIZE_ITEM_INVITATION_CARD_ONLY_FOR_MODE_SINGLE_PLAY: "Prize item invitation card only for mode single play",
  PLAY_FEE_REQUIRED_TICKET: "Play fee required ticket",
  PLAY_FEE_REQUIRED_COIN: "Play fee required coin",
  PLAY_FEE_NOT_FOUND: "Play fee not found",
  PRIZE_TRANSLATE_IS_REQUIRED: "Prize translate is required",
  PRIZE_ITEM_TREASURE_BOX_ALREADY_EXISTS: "Prize item treasure box already exists",
  PLAY_FEE_PRIZE_PLAY_FEE_TREASURE_BOX_NOT_MAP: "Play fee prize play fee treasure box not map",
  PRIZE_ITEM_TREASURE_BOX_REQUIRED_EXISTS: "Prize item treasure box required exists",
  PRIZE_ITEM_INVITATION_CARD_REQUIRED_EXISTS: "Prize item invitation card required exists",
  PRIZE_ITEM_INVITATION_CARD_REQUIRED_STATUS_OPEN: "Prize item invitation card required status open",
  LIMIT_PLAY_IN_DAY_REQUIRED: "Limit play in day required",
  PRIZE_TYPE_IS_INVALID: "Prize type is invalid",
  PRIZE_REQUIRED: "Prize required",
  PRIZE_REQUIRED_ONLY_ONE: "Prize required only one",
  PLAY_FEE_PRIZE_BOX_NOT_MAP_PLAY_FEE_GAME_MODE: "Play fee prize box not map play fee game mode",
  YOU_CAN_NOT_CHANGE_PRIZE_TYPE: "You can not change prize type",
  PRIZE_ITEM_NOT_FOUND_IN_NFT_GAUNTLET: "Prize item not found in nft gauntlet",
  PRIZE_ITEM_3D_IMAGE_IS_EMPTY: "Prize item 3D image is empty",
  PRIZE_ITEM_3D_IMAGE_REQUIRED_EXISTS: "Prize item 3D image required exists",
  PRIZE_ITEM_DF_IS_EMPTY: "Prize item DF is empty",
  PRIZE_ITEM_IS_LOCKED: "Prize item is locked",
  PLEASE_NO_ACTIVE_GAME_MODE_BEFORE_UPDATE: "Please no active game mode before update",
  PLEASE_NO_ACTIVE_GAME_MODE_BEFORE_DELETE: "Please no active game mode before delete",
  PRIZE_IS_USE: "Prize is use",
  ROOM_IS_PLAY_GAME_WITH_GAME_MODE: "Room is play game with game mode",
  PRIZE_ITEM_3D_IMAGE_NOT_FOUND: "Prize item 3D image not found",
}
