import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { importCSVRewardNft } from "api/gacha.api";
import ButtonType from "components/Button";
import FormUpload from "components/form/FormUpload";
import { useCallback, useMemo, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import ItemCSV from "views/Hunter/import/itemCSV";

export default function ModalImport({
  isOpen,
  onClose,
  setCheckedIds,
  rewardNftGacha,
  setRewardNftGacha,
}) {
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const form = useForm();
  const [type, setType] = useState("success");

  const handleClose = () => {
    onClose()
  }

  const {
    getValues,
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const {
    fields: fieldCSV,
    append: appendCSV,
    remove: removeCSV,
  } = useFieldArray({
    control,
    name: "listCSV",
  });

  const clearListCSV = () => {
    reset({
      ...getValues(),
      listCSV: [],
    });
  };

  const importCSV = async (id, file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      return importCSVRewardNft(formData)
    } catch (error) {
      toast({
        description: `${error}`,
        status: "error",
        position: "top",
      });
    }
  };

  const handleOnChangeCSV = (files) => {
    if (files?.length > 0) {
      const listFile = files?.map((file) => {
        return { file };
      });
      if (listFile && listFile?.length > 0) {
        appendCSV(listFile);
      }
    }
  };

  const isExitsListCSV = useMemo(() => {
    return fieldCSV && fieldCSV?.length > 0;
  }, [fieldCSV]);

  const isExitCSVFail = useMemo(() => {
    if (fieldCSV && fieldCSV?.length > 0) {
      const list = fieldCSV?.filter((item) => {
        if (item?.isError) {
          return item;
        }
      });
      return list?.length > 0;
    }
    return false;
  }, [fieldCSV]);

  const listNameCSVErrors = useCallback(
    (listError) => {
      if (fieldCSV && fieldCSV?.length > 0) {
        const list = fieldCSV
          ?.filter((item, index) => {
            if (listError[index]) {
              return item;
            }
          })
          .map((item) => item?.file?.name);
        return list?.join(", ");
      }
      return null;
    },
    [fieldCSV]
  );

  const onSubmit = useCallback(async () => {
    try {
      const idContract = getValues("contract");
      if (isExitsListCSV) {
        const promise = fieldCSV?.map((item) => {
          return importCSV(idContract, item?.file);
        });
        const res = await Promise.all(promise);
        if (res) {
          let arrError = [];
          let isError = false;
          for (let i = 0; i < res?.length; i++) {
            const err = !res[i]?.data?.success;
            if (err) {
              isError = true;
            }
            arrError = arrError.concat(err);
          }
          if (isError) {
            reset({
              ...getValues(),
              listCSV: fieldCSV?.map((item, index) => ({
                ...item,
                isError: arrError[index],
              })),
            });
            setType("error");
            const listNameError = listNameCSVErrors(arrError);
            if (listNameError) {
              if (!res[0]?.data?.data) {
                toast({
                  description: `${res[0]?.data?.messages[0]}`,
                  status: "error",
                  position: "top",
                });
              } else {
                toast({
                  description: `${res[0]?.data?.data[0]?.error_message?.replace(
                    ";",
                    "."
                  )}`,
                  status: "error",
                  position: "top",
                });
              }
            }
          } else {
            let arrayNftGacha = rewardNftGacha
            rewardNftGacha.forEach(item1 => {
              // Tìm phần tử trong array2 có ID trùng với ID của item1
              const item2 = res[0]?.data?.data.find(item => item.id === item1.id);
              
              // Nếu tìm thấy phần tử trong array2, gán các thuộc tính của item2 vào item1
              if (item2) {
                Object.assign(item1, item2);  // Kết hợp các thuộc tính từ item2 vào item1
              }
            });
            setType("success");
            clearListCSV();
            onClose()
            setRewardNftGacha(arrayNftGacha)
          }
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }, [isExitsListCSV, listNameCSVErrors]);

  const renderForm = () => {
    return (
      <FormProvider {...form} w="100%">
        <form
          id="role-manager-form-id"
          onSubmit={handleSubmit(onSubmit)}
          style={{
            width: "100%",
            minWidth: "600px",
            overflowX: "auto",
          }}
        >
          <Flex direction="column" gap={4}>
            <Flex direction="column" gap={4} w="100%">
              <FormUpload
                name="csv"
                handleOnChange={handleOnChangeCSV}
                accept=".csv"
              />
            </Flex>
            <Flex
              direction="column"
              gap={2}
              w="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              {fieldCSV?.map((item, index) => {
                return (
                  <ItemCSV
                    key={item?.id}
                    name={item?.file?.name}
                    isError={item?.isError}
                    indexItem={index}
                    removeCSV={removeCSV}
                  />
                );
              })}
            </Flex>
            <Flex gap="16px" pl="2px" pb="2px">
              <ButtonType
                fontSize="16px"
                fontWeight="500"
                w="140px"
                h="56px"
                type="submit"
                btnType="primary-new"
                disabled={
                  !isExitsListCSV || isExitCSVFail
                }
                isLoading={isSubmitting}
              >
                Import
              </ButtonType>
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} closeOnOverlayClick={false} size="xxl">
      <ModalOverlay />
      <ModalContent maxW="900px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          Import Reward
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">
          {renderForm()}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
