import { containSpecialUserNameJapanRegex } from "utils/regex";
import * as yup from 'yup'

const schemaPrize = () =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .matches(containSpecialUserNameJapanRegex, "Name should not contain special characters")
      .required("Required")
      .max(30, "Maximum limit of 30 characters."),
    name_jp: yup
      .string()
      .trim()
      .matches(containSpecialUserNameJapanRegex, "Name should not contain special characters")
      .required("Required")
      .max(30, "Maximum limit of 30 characters."),
    description: yup
      .string()
      .trim()
      .max(200, "Maximum limit of 200 characters."),
    description_jp: yup
      .string()
      .trim()
      .max(200, "Maximum limit of 200 characters."),
    prize_img: yup
      .string()
      .trim()
      .required("Required"),
    assist_gauge_limit: yup
      .number()
      .when('assist', {
        is: true,
        then: () => yup
          .number().min(1, 'Number of assist gauge be greater or equal to 1')
          .transform((_, val) => {
            if (val) {
              return Number(val) || Number(val) === 0 ? Number(val) : 0;
            }
            return 0;
          })
          .max(99999999, "Number of assist gauge must be at most 8 characters")
          .integer("Must be an integer"), 
        otherwise: () => yup.number().nullable(),
      }),
    bg_img: yup
      .string()
      .trim()
      .required("Required"),
    prize_items: yup
      .array()
      .required("Required"),
    start: yup
      .date()
      .test(
        "is-future-minute",
        "Start time must be in the future",
        function (value) {
          const { sale } = this.parent;
          const now = new Date();
          now.setSeconds(0, 0); // Ignoring seconds
          const inputTime = new Date(value);
          inputTime.setSeconds(0, 0); // Ignoring seconds
          if (!sale || sale === 'false') {
            return true
          } else {
            return inputTime.getTime() >= now.getTime();
          }
        }
      )
      .required("Required"),
    end: yup
      .date()
      .test(
        "is-greater-start",
        "Result end time must be after start time",
        function (value) {
          const { start } = this.parent;
          return (
            start &&
            value &&
            new Date(value) > new Date(start)
          );
        }
      )
      .required(),
  });

const schemaPrizeUpdate = () =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .matches(containSpecialUserNameJapanRegex, "Name should not contain special characters")
      .required("Required")
      .max(30, "Maximum limit of 30 characters."),
    name_jp: yup
      .string()
      .trim()
      .matches(containSpecialUserNameJapanRegex, "Name should not contain special characters")
      .required("Required")
      .max(30, "Maximum limit of 30 characters."),
    description: yup
      .string()
      .trim()
      .max(200, "Maximum limit of 200 characters."),
    description_jp: yup
      .string()
      .trim()
      .max(200, "Maximum limit of 200 characters."),
    prize_img: yup
      .string()
      .trim()
      .required("Required"),
    assist_gauge_limit: yup
      .number()
      .when('assist', {
        is: true,
        then: () => yup
          .number().min(1, 'Number of assist gauge be greater or equal to 1')
          .transform((_, val) => {
            if (val) {
              return Number(val) || Number(val) === 0 ? Number(val) : 0;
            }
            return 0;
          })
          .max(99999999, "Number of assist gauge must be at most 8 characters")
          .integer("Must be an integer"), 
        otherwise: () => yup.number().nullable(),
      }),
    bg_img: yup
      .string()
      .trim()
      .required("Required"),
    prize_items: yup
      .array()
      .required("Required"),
    start: yup
      .date()
      .required("Required"),
    end: yup
      .date()
      .test(
        "is-greater-start",
        "Result end time must be after start time",
        function (value) {
          const { start } = this.parent;
          return (
            start &&
            value &&
            new Date(value) > new Date(start)
          );
        }
      )
      .required(),
  });

export { schemaPrize, schemaPrizeUpdate }
