import {
  Box,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "components/form/FormInput";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ButtonType from "components/Button";
import FormUpload from "components/form/FormUpload";
import { NUMBER } from "constants/enum";
import { uploadImageToServer } from "utils/uploadImage";
import { ERROR_CREATE_EVENT } from "views/Event/EventManagement/constant";
import FormTextAria from "components/form/FormTextAria";
import { TRANS_LABEL } from "views/DigitalFigure/constants";
import ModalSelectRelated from "./ModalSelectRelated";
import schemaViewBG from "./schema";
import { createlBackground, updateBackground } from "api/digitaFigure.api";

export default function CreateAndUpdateViewBg({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const [tabIndex, setTabIndex] = useState(0);
  const [trans, setTrans] = useState("en");
  const [nameImage, setNameImage] = useState("");
  const [checkedIds, setCheckedIds] = useState([])
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const form = useForm({
    resolver: yupResolver(schemaViewBG())
  });
  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { isSubmitting },
  } = form;

  const { 
    isOpen: isOpenSelectRelated, 
    onOpen: onOpenSelectRelated, 
    onClose: onCloseSelectRelated, 
  } = useDisclosure();

  const disableWhenJP = useMemo(() => {
    return trans === "jp";
  }, [trans]);

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  useEffect(() => {
    if (dataDetail) {
      reset({
        ...dataDetail,
      });
    } else {
      reset();
    }
  }, [dataDetail]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    if (index === 1) {
      setTrans("jp");
    } else {
      setTrans("en");
    }
  };

  const transLabel = (label) => {
    return TRANS_LABEL[trans][label];
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const listDigital = checkedIds?.map((relate) => ({
      id: relate?.id
    }))
    const dataSubmit = {
      name: data?.name,
      description: data?.description,
      count: data?.count,
      image_url: data?.asset_url,
      digital_figure_id: listDigital?.length > 0 ? listDigital[0]?.id : null,
      translations: [
        {
          lang_key: "EN",
          name: data?.name,
          description: data?.description
        },
        {
          lang_key: "JP",
          name: data?.name_jp,
          description: data?.description_jp
        }
      ]
    }
    try {
      if (idEdit) {
        const res = await updateBackground(dataDetail?.id, dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "DF View BG Edited Successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose()
        } else {
          toast({
            description: ERROR_CREATE_EVENT[res?.data?.message] || ERROR_CREATE_EVENT[res?.data?.messages[0]] || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      } else {
        const res = await createlBackground(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "DF View BG Created Successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose()
        } else {
          toast({
            description: ERROR_CREATE_EVENT[res?.data?.message] || ERROR_CREATE_EVENT[res?.data?.messages[0]] || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleImageChange = async (event) => {
    const image = event[0];
    await uploadImageToServer(image, toast, setValue, "asset_url", setNameImage);
  };

   const handleDragImage = async (file) => {
    if (file.length > NUMBER.ONE) {
      toast({
        description: "The number of uploaded images is greater than one.",
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    const image = file && file[0];
    await uploadImageToServer(image, toast, setValue, "asset_url", setNameImage);
  };

  const deleteImage = () => {
    setValue('asset_url', null)
  }

  useEffect(() => {
    if (dataDetail?.id) {
      const itemJP = dataDetail?.translations?.find(
        (item) => item?.lang_key === "JP"
      );
      reset({
        name: dataDetail?.name,
        name_jp: itemJP?.name,
        description: dataDetail?.description,
        description_jp: itemJP?.description,
        asset_url: dataDetail?.image_url,
        related: dataDetail?.digital_figure ? [dataDetail?.digital_figure] : [],
        count: dataDetail?.count,
      });
      setCheckedIds(dataDetail?.digital_figure ? [dataDetail?.digital_figure] : [])
    } else {
      reset({
        related: []
      });
    }
  }, [reset, dataDetail]);

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            mb={4}
            index={tabIndex}
            variant="unstyled"
            onChange={handleTabsChange}
          >
            <TabList gap={2}>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                English
              </Tab>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                Japanese
              </Tab>
            </TabList>
          </Tabs>
          <Flex direction="column" w="100%" gap={4}>
            <Grid emplateColumns="repeat(1, 1fr)" gap={4}>
              <Flex direction="column" gap={4}>
                {trans === "en" ? (
                  <Box display="flex" flexDirection="column" gap="8px">
                    <FormInput
                      maxW="100%"
                      name="name"
                      label={transLabel("name")}
                      placeholder={transLabel("enterName")}
                      isRequired
                    />
                    <FormTextAria
                      label={transLabel("description")}
                      placeholder={transLabel("enterDesc")}
                      name="description"
                      defaultValue=''
                      h="100px"
                      borderRadius="8px"
                    />
                  </Box>
                ) : (
                  <Flex flexDirection="column" gap="8px">
                    <FormInput
                      maxW="100%"
                      name="name_jp"
                      label={transLabel("name")}
                      placeholder={transLabel("enterName")}
                      isRequired
                    />
                    <FormTextAria
                      label={transLabel("description")}
                      placeholder={transLabel("enterDesc")}
                      name="description_jp"
                      defaultValue=''
                      h="100px"
                      borderRadius="8px"
                    />
                  </Flex>
                )}
                <>
                  <FormUpload
                    label="Select image file to upload"
                    title={transLabel("asset")}
                    name="asset_url"
                    type='IMAGE'
                    disabled={disableWhenJP}
                    accept={"image/*"}
                    isRequired
                    textButton={nameImage}
                    handleOnChange={(e) =>
                      handleImageChange(e, "asset_url")
                    }
                    handleDragImage={(val) =>
                      handleDragImage(val, "asset_url")
                    }
                    deleteImage={() => deleteImage("asset_url")}
                  />
                  {!watch("asset_url") && (
                    <Text>*Currently support .png, .jpg less than 200KB </Text>
                  )}
                  <Flex gap="16px" w="100%">
                    <FormInput
                      wrapperProps={{
                        w: "100%",
                      }}
                      type="number"
                      min={0}
                      name="count"
                      label={transLabel("count")}
                      placeholder={transLabel("enterValue")}
                      isRequired
                      disabled={disableWhenJP}
                    />
                    <Box w="100%"></Box>
                  </Flex>
                  <FormInput
                    type="text"
                    label={transLabel("relatedDigital")}
                    name="related_digital"
                    placeholder={transLabel("selectDigital")}
                    onClick={() => onOpenSelectRelated()}
                    disabled={disableWhenJP}
                    value=""
                    autoComplete="off"
                  />
                  <Flex gap="4px" flexWrap="wrap">
                    {checkedIds?.map((check, index) => (
                      <Flex
                        key={index}
                        padding="4px 8px"
                        borderRadius="6px"
                        background="#fdff88"
                        alignItems="center"
                        gap="4px"
                      >
                        <Text fontSize="14px" fontWeight="400">{check?.name}</Text>
                      </Flex>
                    ))}
                  </Flex>
                </>
              </Flex>
            </Grid>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="700px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "Edit DF View BG" : "New DF View BG"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
      {isOpenSelectRelated && (
        <ModalSelectRelated
          isOpen={isOpenSelectRelated}
          onClose={onCloseSelectRelated}
          setCheckedIds={setCheckedIds}
          checkedIds={checkedIds}
          setValue={setValue}
          watch={watch}
          idEdit={idEdit}
        />
      )}
    </Modal>
  );
}
