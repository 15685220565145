import { Icon } from "@chakra-ui/icons";
import {
  Image,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import moment from "moment";
import { ViewIcon } from "components/Icons/ViewIcon";
import { TYPE_PRIZE } from "constants/constants";
import { convertDateLocalLLLL } from "utils/time";

export default function LeaderBoardRow({
  item,
}) {
  const textColor = useColorModeValue("#000000", "white");

  const convertTime = (time) => {
    return moment(time * 1000).utc().format("DD MMM. YYYY HH:mm:ss");
  };

  return (
    <Tr>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.name}
        </Text>
      </Td>
      <Td>
        <Image w="50px" lazyLoad={true} src={item?.image_url} alt="NFT" />
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {TYPE_PRIZE[item?.prize_type]}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.assist_gauge ? item?.assist_gauge_limit : '--'}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.total_items || '0'}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.sale_period ? "Yes" : "No"}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.start_sale_period ? convertDateLocalLLLL(item?.start_sale_period * 1000) : "--"}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.end_sale_period ? convertDateLocalLLLL(item?.end_sale_period * 1000) : "--"}
        </Text>
      </Td>
      {/* <Td>
        <Icon
          fontSize="30px"
          borderRadius="8px"
          cursor="pointer"
          as={ViewIcon}
        />
      </Td> */}
    </Tr>
  );
}
