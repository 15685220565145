import { Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import NumOfNFTsTd from "./NumOfNFTsTd";
import { formatTimestampToMonthYearDash } from "utils/time";

export default function PayoutHistoryRow({ item, index }) {
  const textColor = useColorModeValue("#000000", "white");
  return (
    <Tr>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {index}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {formatTimestampToMonthYearDash(item?.summary_month)}
        </Text>
      </Td>
      <NumOfNFTsTd nfts={item?.nft_quantity} />
      <Td>
        {item?.payout_temp ? (
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.payout_temp?.symbol}
            {item?.payout_temp?.amount}
          </Text>
        ) : (
          "Processing..."
        )}
      </Td>
    </Tr>
  );
}
