import { Flex, Text } from "@chakra-ui/react";
import FormDatePicker from "components/form/FormDatePicker";
import FormLabelComponent from "components/form/FormLabel";
import FormSelect from "components/form/FormSelect";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import { convertToIsoString } from "views/Event/help";
import {
  DESTINATION_OPTIONS,
  PLACEMENT_OPTIONS,
  TRIGGER_OPTIONS,
} from "./constants";

export default function ConditionAds({ isLoading, setParams, params }) {
  const form = useForm();
  const { setValue, reset, handleSubmit } = form;
  const formatResult = "YYYY-MM-DD HH:mm:ss";
  const dateFormat = "dd MMM. YYYY HH:mm:ss";

  const onSubmit = (data) => {
    const dataSubmit = {
      fromDate: data?.start_date ? convertToIsoString(data?.start_date) : "",
      toDate: data?.end_date ? convertToIsoString(data?.end_date) : "",
      destination: data?.destination,
      trigger: data?.trigger,
      placement: data?.placement,
    };

    if (!isLoading) {
      const newParams = {
        ...params,
        ...dataSubmit,
        page: 1,
      };
      setParams(newParams);
    }
  };

  return (
    <Flex
      gap="16px"
      flexWrap="wrap"
      direction="column"
      justifyContent="flex-start"
      w="100%"
      alignItems="flex-start"
    >
      <FormProvider {...form} w="100%">
        <form
          style={{
            width: "100%",
            minWidth: "600px",
            display: "flex",
            justifyContent: "space-between",
            gap: "8px",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Flex direction="row" gap={6} flexWrap={'wrap'}>
            <Flex direction="column" gap="8px" minW="250px">
              <FormLabelComponent m="0px" title="From" />
              <FormDatePicker
                name="start_date"
                showTimeSelect
                dateFormat={dateFormat}
                placeholder="dd/mm/yyyy hh:mm"
                w="200px"
                h="40px"
                isShowTodayBtn
                onChange={(e) => {
                  const time = moment(e).format(formatResult);
                  setValue("start_date", time);
                  handleSubmit(onSubmit)();
                }}
              />
            </Flex>
            <Flex direction="column" gap="8px" minW="250px">
              <FormLabelComponent m="0px" title="To" />
              <FormDatePicker
                name="end_date"
                showTimeSelect
                dateFormat={dateFormat}
                placeholder="dd/mm/yyyy hh:mm"
                w="200px"
                h="40px"
                isShowTodayBtn
                onChange={(e) => {
                  const time = moment(e).format(formatResult);
                  setValue("end_date", time);
                  handleSubmit(onSubmit)();
                }}
              />
            </Flex>
            <FormSelect
              w="250px"
              name="destination"
              label="Filter by Destination"
              options={[{ label: "All", value: "" }, ...DESTINATION_OPTIONS]}
              onChange={(e) => {
                setValue("destination", e.target.value);
                handleSubmit(onSubmit)();
              }}
            />
            <FormSelect
              w="250px"
              name="trigger"
              label="Filter by Trigger"
              options={[{ label: "All", value: "" }, ...TRIGGER_OPTIONS]}
              onChange={(e) => {
                setValue("trigger", e.target.value);
                handleSubmit(onSubmit)();
              }}
            />
            <FormSelect
              w="250px"
              name="placement"
              label="Filter by Placement"
              options={[{ label: "All", value: "" }, ...PLACEMENT_OPTIONS]}
              onChange={(e) => {
                setValue("placement", e.target.value);
                handleSubmit(onSubmit)();
              }}
            />
          </Flex>
          <Text
            cursor="pointer"
            color="#1480FF"
            fontSize="14px"
            fontWeight="500"
            alignSelf="flex-end"
            onClick={() => {
              reset({
                keyword: "",
                destination: "",
                trigger: "",
                placement: "",
              });
              handleSubmit(onSubmit)();
            }}
          >
            Reset
          </Text>
        </form>
      </FormProvider>
    </Flex>
  );
}
