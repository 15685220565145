import { containSpecialRegex } from "utils/regex";
import * as yup from "yup";

const schemaBannerSetup = () =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .matches(containSpecialRegex, "Name should not contain special characters")
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    image_url: yup
      .string()
      .trim()
      .required("Required"),
    prize_items: yup
      .array()
      .min(1, 'Required'),
  });

export default schemaBannerSetup;
