import {
  Flex,
  Grid,
  Text,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { useEffect, useState } from "react";
import CardHeader from "components/Card/CardHeader";
import { FormProvider, useForm } from "react-hook-form";
import ButtonType from "components/Button";
import { getSystemMail, updateSystemMail } from "api/system";
import FormInput from "components/form/FormInput";
import { schemaRegistration } from "./schema";
import { yupResolver } from "@hookform/resolvers/yup";

export default function EmailRegistration(params) {
  const { title } = params
  const [isEdit, setIsEdit] = useState(false);
  const toast = useToast();

  const form = useForm({
    resolver: yupResolver(schemaRegistration()),
    defaultValues: {}
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isSubmitting },
  } = form;

  const onSubmit = async (data, e) => {
    try {
      const res = await updateSystemMail(data);
      if (res?.data?.success) {
        toast({
          title: "Contact updated.",
          description: "Contact updated.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setIsEdit(false)
        getSystemMaintenanceMail()
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const getSystemMaintenanceMail = async () => {
    try {
      const { data } = await getSystemMail();
      if (data?.success) {
        Object.keys(data?.data).filter(key => setValue(key, data?.data[key] || ''));
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } 
  };

  useEffect(() => {
    getSystemMaintenanceMail()
  }, [])

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="email-registration" style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          <Flex alignItems="flex-start" gap="16px" w="100%" mt="10px">
            <Grid templateColumns='repeat(2, 1fr)' gap='16px' w="100%">
              <FormInput
                wrapperProps={{
                  w: "100%",
                }}
                maxW="100%"
                name="host"
                label='SMTP Server'
                placeholder='Enter the server'
              />
              <FormInput
                wrapperProps={{
                  w: "100%",
                }}
                maxW="100%"
                name="url"
                label='URL'
                placeholder='Enter the url'
              />
              <FormInput
                wrapperProps={{
                  w: "100%",
                }}
                maxW="100%"
                type="number"
                name="port"
                label='Port Number'
                placeholder='Enter the port'
              />
              <FormInput
                wrapperProps={{
                  w: "100%",
                }}
                maxW="100%"
                name="sender"
                label='Sender Email'
                placeholder='Enter the url'
              />
              <FormInput
                wrapperProps={{
                  w: "100%",
                }}
                maxW="100%"
                name="username"
                label='Login name'
                placeholder='Enter the login name'
              />
              <FormInput
                wrapperProps={{
                  w: "100%",
                }}
                maxW="100%"
                name="password"
                label='Login Password'
                placeholder='Enter the password'
              />
            </Grid>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Flex flexDirection="column" w="100%">
      <Card px="22px" maxW="80%">
        <CardHeader mb="20px" w="100%" display="flex" alignItems="center" justifyContent="space-between">
          <Text
            fontSize="xl"
            fontWeight="bold"
            whiteSpace="nowrap"
          >
            {title}
          </Text>
          {!isEdit ? (
            <ButtonType
              text="EDIT"
              w="100px"
              fontSize="12px"
              onClick={(e) => {
                e.preventDefault()
                setIsEdit(!isEdit)
              }}
            />
          ) : (
            <ButtonType
              text="SAVE"
              w="100px"
              fontSize="12px"
              type="submit"
              form="email-registration"
              isLoading={isSubmitting}      
            />
          )}
        </CardHeader>
        <CardBody 
          overflowX="auto"
          opacity={isEdit ? 1 : 0.5}
          pointerEvents={isEdit ? 'auto' : 'none'}
          overflow="visible"
        >
          {renderForm()}
        </CardBody>
      </Card>
    </Flex>
  );
}
