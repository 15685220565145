/* eslint-disable react/prop-types */
import { Checkbox, FormControl, FormLabel } from "@chakra-ui/react";
import { useController, useFormContext } from "react-hook-form";
import styled from "styled-components";

const CheckBoxStyled = styled(Checkbox)`
  .chakra-form-control {
    flex-direction: ${(props) => props.position ? "row-reverse" : "row"};
    justify-content: ${(props) => props.position ? "flex-end" : "flex-start"};
  }
  .chakra-checkbox__control {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 1.5px solid #969696;

    &[data-checked] {
      /* background-image: linear-gradient(310deg, #141727, #3a416f); */
      background-color: #ffcf3e;
      border: none;
      &:hover {
        background-color: #ffcf3e;
        /* background-image: linear-gradient(310deg, #141727, #3a416f); */
      }
      svg {
        color: #000000;
      }
    }
  }
`;

const FormCheckbox = ({
  t,
  label,
  name,
  rules,
  defaultValue = "",
  wrapperProps,
  options = [],
  isRequired,
  position,
  ...rest
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });
  return (
    <FormControl
      maxW="100%"
      w="auto"
      flexDirection={`${position ? "row-reverse" : "row"}`}
      justifyContent={`${position ? "flex-end" : "flex-start"}`}
      isInvalid={error?.message}
      {...wrapperProps}
    >
      {label && (
        <FormLabel whiteSpace="nowrap" color="#000000" mb="0" fontWeight="400">
          {label} {isRequired && <span style={{ color: "red" }}>*</span>}
        </FormLabel>
      )}
      <CheckBoxStyled
        onChange={onChange}
        value={value}
        isChecked={value}
        colorScheme="teal"
        {...rest}
      >
        {options.map((opt) => (
          <CheckBoxStyled position={position} key={opt.value} value={opt.value}>
            {t(opt.label)}
          </CheckBoxStyled>
        ))}
      </CheckBoxStyled>
    </FormControl>
  );
};

export default FormCheckbox;
