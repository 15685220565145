import {
  Box,
  Flex,
  Text,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { useEffect, useState } from "react";
import CardHeader from "components/Card/CardHeader";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "components/form/FormInput";
import FormLabelComponent from "components/form/FormLabel";
import ButtonType from "components/Button";
import moment from "moment";
import SwitchType from "components/Switch";
import FormDatePicker from "components/form/FormDatePicker";
import { convertToIsoString, convertToUTCTime, dateAfterNow } from "views/Event/help";
import { updateSystemMaintenance } from "api/system";

export default function Maintenance(params) {
  const { title, dataMaintenance, itemMaintenance, type, getListMaintenance } = params
  const dateFormat = "dd MMM. YYYY HH:mm:ss";
  const formatResult = "YYYY-MM-DD HH:mm:ss";
  
  const [isEdit, setIsEdit] = useState(false);
  const toast = useToast();

  const form = useForm({
    defaultValues: {
      enabled: itemMaintenance?.enabled ? true : false,
      redirect_url: itemMaintenance?.redirect_url ? itemMaintenance?.redirect_url : '',
      start_at: itemMaintenance?.start_at ? convertToUTCTime(itemMaintenance?.start_at) : dateAfterNow(0),
      end_at: itemMaintenance?.end_at ? convertToUTCTime(itemMaintenance?.end_at) : dateAfterNow(3),
    }
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isSubmitting },
  } = form;

  const onSubmit = async (data, e) => {
    e.preventDefault()
    try {
      const dataSubmit = {
        site_maintenance: {
          ...dataMaintenance?.site_maintenance,
          [type]: {
            ...data,
            start_at: convertToIsoString(data?.start_at),
            end_at: convertToIsoString(data?.end_at)
          }
        },
        login_access: dataMaintenance?.login_access
      }
      const res = await updateSystemMaintenance(dataSubmit);
      if (res?.data?.success) {
        toast({
          title: "Contact updated.",
          description: "Contact updated.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        await setIsEdit(false)
        await getListMaintenance()
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id={type} style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          <Flex alignItems="center" width=" 100%" justifyContent="space-between" gap="8px">
            <FormInput
              wrapperProps={{
                w: "80%",
              }}
              name="redirect_url"
              label='Marketplace Url'
              placeholder='Marketplace url'
              isRequired
            />
            <Flex alignItems="flex-start" flexDirection="column">
              <Text fontSize="16px" marginBottom='8px' fontWeight="500">Maintenance</Text>
              <SwitchType
                color="#ff6db6"
                colorThumb="#fff"
                size="lg"
                colorScheme="teal"
                position="relative"
                name='enabled'
                isChecked={watch("enabled")}
                onChange={(e) => setValue("enabled", e?.target?.checked)}
              >
                <Box
                  as="span"
                  position="absolute"
                  left="25%"
                  top="50%"
                  transform="translate(-50%, -50%)"
                  color="white"
                  fontWeight="bold"
                  fontSize="12px"
                  pointerEvents="none" // Prevents pointer events from interfering with the switch
                >
                  On
                </Box>
                {!watch("enabled") && (
                  <Box
                    as="span"
                    position="absolute"
                    left="64%"
                    top="50%"
                    transform="translate(-50%, -50%)"
                    fontWeight="bold"
                    color="#4a4a4a"
                    fontSize="12px"
                    pointerEvents="none" // Prevents pointer events from interfering with the switch
                  >
                    Off
                  </Box>
                )}
              </SwitchType>
            </Flex> 
          </Flex>
          <Flex alignItems="flex-start" gap="16px" w="100%" mt="10px">
            <Flex direction="column" gap="8px" w="100%">
              <FormLabelComponent
                m="0px"
                title='Start'
                isRequired
              />
              <FormDatePicker
                disabled={!isEdit}
                name="start_at"
                showTimeSelect
                dateFormat={dateFormat}
                h="40px"
                onChange={(e) => {
                  const time = moment(e).format(formatResult);
                  setValue("start_at", time);
                }}
              />
            </Flex>
            <Flex direction="column" gap="8px" w="100%">
              <FormLabelComponent
                m="0px"
                title='End'
                isRequired
              />
              <FormDatePicker
                disabled={!isEdit}
                name="end_at"
                showTimeSelect
                dateFormat={dateFormat}
                h="40px"
                onChange={(e) => {
                  const time = moment(e).format(formatResult);
                  setValue("end_at", time);
                }}
              />
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Flex flexDirection="column" w="100%">
      <Card px="22px" maxW="80%">
        <CardHeader mb="20px" w="100%" display="flex" alignItems="center" justifyContent="space-between">
          <Text
            fontSize="xl"
            fontWeight="bold"
            whiteSpace="nowrap"
          >
            {title}
          </Text>
          {!isEdit ? (
            <ButtonType
              text="EDIT"
              w="100px"
              fontSize="12px"
              onClick={(e) => {
                e.preventDefault()
                setIsEdit(!isEdit)
              }}
            />
          ) : (
            <ButtonType
              text="SAVE"
              w="100px"
              fontSize="12px"
              type="submit"
              form={type}
              isLoading={isSubmitting}      
            />
          )}
        </CardHeader>
        <CardBody 
          overflowX="auto"
          opacity={isEdit ? 1 : 0.5}
          pointerEvents={isEdit ? 'auto' : 'none'}
          overflow="visible"
        >
          {renderForm()}
        </CardBody>
      </Card>
    </Flex>
  );
}
