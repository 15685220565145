import {
  Flex,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import ConditionPresentBox from "./condition";
import CreateAndUpdatePresentBox from "./createAndUpdate"
import DetailPresentBox from "./detail"
import TableCustom from "components/Tables/CustomTable";
import Paginate from "components/Paginate";
import { useCallback, useEffect, useState } from "react";
import { getListPresentBox } from "api/presentBox.api";
import { FIRST_PAGE, LIMIT_PAGE, STATUS_PRESENTBOX } from "constants/constants";
import ActionPresentBox from "./action";
import PresentBoxManagementRow from "./row"
import { getPresentBoxById, getEventsInComing, putTriggerPresentBox } from "api/presentBox.api";

export default function PresentBoxManagement() {
  const [presentBoxs, setPresentBoxs] = useState([]);
  const [dataDetail, setDataDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [events, setEvents] = useState([]);
  const [optionEvents, setOptionEvents] = useState([]);
  const [params, setParams] = useState({
    page: FIRST_PAGE,
    limit: LIMIT_PAGE,
  });
  const toast = useToast();
  const statusAllowPlay = ["FAIL", "PENDING"];

  const {
    isOpen: isOpenCreateAndUpdate,
    onOpen: onOpenCreateAndUpdate,
    onClose: onCloseCreateAndUpdate,
  } = useDisclosure();

  const {
    isOpen: isOpenDetail,
    onOpen: onOpenDetail,
    onClose: onCloseDetail,
  } = useDisclosure();

  const getPresentBox = useCallback(
    async () => {
      try {
        setIsLoading(true);
        const { data } = await getListPresentBox(params);
        if (data?.success) {
          const res = data?.data
          setPresentBoxs(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  useEffect(() => {
    getPresentBox();
  }, [params]);

  const handleOpenCreateOrUpdate = async (data = null) => {
    if(data && data?.status !== "PENDING") return;
    if(data) {
      await getDetailPresentBox(data.id)
    }
    onOpenCreateAndUpdate();
  };

  const handleTrigger =  async (item) => {
    if(item && (item?.distribution_condition || !statusAllowPlay.includes(item.status))) return;
    try {
      const { data } = await putTriggerPresentBox(item.id);
      if (data?.success) {
        getPresentBox();
        toast({
          title: "Trigger Present Box Successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }

  const getDetailPresentBox = async (id) => {
    try {
      const { data } = await getPresentBoxById(id);
      if (data?.success) {
        const res = data?.data;
        setDataDetail(res);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleSetDataDetail = async (id) => {
    await getDetailPresentBox(id)
    onOpenDetail();
  };

  const handleCloseCreateOrUpdate = () => {
    onCloseCreateAndUpdate();
    setDataDetail(null);
  };

  const handleClose = () => {
    onCloseDetail();
    setDataDetail(null);
  };

  const handleCreateOrUpdateSuccess = useCallback(() => {
    handleCloseCreateOrUpdate();
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedIds(presentBoxs.filter(box => box.status === "PENDING").map(presentBox => presentBox.id));
    } else {
      setCheckedIds([]);
    }
  };

  const getListEvents = async () => {
    try {
      const { data } = await getEventsInComing();
      if (data?.success) {
        const res = data?.data;
        const list = res.map(item => ({
          value: item.id,
          label: item.name,
      }));
        setOptionEvents(list);
        setEvents(res);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    getListEvents();
  }, []);


  const presentBoxPending = presentBoxs.filter(box => box.status === "PENDING");

  useEffect(() => {
    presentBoxPending;
  }, [checkedIds, presentBoxs]);

  const labels = [
    "MGMT.NAME",
    "NAME",
    "STATUS",
    "RECIPIENTS",
    "DISTRIBUTION TYPE",
    "DISTRIBUTION DATE",
    "EXPIRATION",
    "",
  ];

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="24px">
        <CardHeader mb="32px">
          <ConditionPresentBox
            isLoading={isLoading}
            setParams={setParams}
            params={params}
          />
        </CardHeader>
        <CardHeader mb="12px">
          <Flex
            w="100%"
            gap={4}
            direction={{
              md: "row",
            }}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Flex
              w="100%"
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              <ActionPresentBox
                handleOpenCreateOrUpdate={() =>
                  handleOpenCreateOrUpdate()
                }
                checkedIds={checkedIds}
                setCheckedIds={setCheckedIds}
                setParams={setParams}
                params={params}
              />
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck
            isChecked={presentBoxPending?.length > 0 && checkedIds.length === presentBoxPending?.length}
            dataRow={presentBoxs}
            onChangeChecked={onChangeChecked}
          >
            <>
              {presentBoxs?.map((item) => {
                return (
                  <PresentBoxManagementRow
                    key={item?.id}
                    itemPresentBox={item}
                    checkedIds={checkedIds}
                    setCheckedIds={setCheckedIds}
                    handleOpenCreateOrUpdate={() =>
                      handleOpenCreateOrUpdate(item)
                    }
                    handleTrigger={() =>
                      handleTrigger(item)
                    }
                    handleSetDataDetail={() => {
                      handleSetDataDetail(item.id);
                    }}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
      {isOpenCreateAndUpdate && (
        <CreateAndUpdatePresentBox
          isOpen={isOpenCreateAndUpdate}
          onClose={handleCloseCreateOrUpdate}
          dataDetail={dataDetail}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
          handleViewClose={handleCloseCreateOrUpdate}
          events={events}
          optionEvents={optionEvents}
        />
      )}
      {isOpenDetail && (
        <DetailPresentBox
          isOpen={isOpenDetail}
          onClose={handleClose}
          dataDetail={dataDetail}
        />
      )}
    </Flex>
  );
}
