import {
  Box,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "components/form/FormInput";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import schemaCreateGacha from "./schema";
import ButtonType from "components/Button";
import FormLabelComponent from "components/form/FormLabel";
import FormDatePicker from "components/form/FormDatePicker";
import { convertToIsoString, convertToUTCTime, dateAfterNow } from "views/Event/help";
import moment from "moment";
import SwitchType from "components/Switch";
import styled from "styled-components";
import { createNewGacha, getDetailGacha, updateDetailGacha } from "api/gacha.api";
import ModalSelectPrize from "./ModalSelectPrize";
import TableListReward from "./TableListReward";
import FormUpload from "components/form/FormUpload";
import { uploadImageToServer } from "utils/uploadImage";
import { NUMBER } from "constants/enum";
import { LIST_ERROR_GACHA } from "views/GachaManagement/constants";

const TextError = styled.div`
  padding: 0 24px;
  color: #E53E3E;
  font-size: 16px;
`

const TRANS_LABEL = {
  en: {
    gachaName: "Gacha name",
    enterGachaName: "Enter new gacha name",
    description: "Feature description",
    enterDesc: "Enter feature description",
    start: "Start",
    end: "End",
    ceiling: "Ceiling value",
    pullFree: "Pull Free Orb",
    pullPaid: "Pull Paid Orb",
    cost: "Cost",
    enterValue: "Enter a value",
    playLimit: "Plays limit per day",
    dropRate: "Display Drop Rates on UI",
    setupReward: "Setup reward components for the gacha",
    largeReward: "Add large reward containers",
    bannerImage: "Banner Image",
    slideImage: "Slide Image",
    labelImg: "Select image file to upload",
    desLabelImg: "Or drag and drop it here",
  },
  jp: {
    gachaName: "ガチャ名",
    enterGachaName: "新しいガチャ名を入力してください",
    description: "機能の説明",
    enterDesc: "機能の説明を入力",
    start: "開始",
    end: "終了",
    ceiling: "上限値",
    pullFree: "無料オーブの取得",
    pullPaid: "有料オーブを引く",
    cost: "コスト",
    enterValue: "値を入力してください",
    playLimit: "1日あたりのプレイ回数制限",
    dropRate: "UIにドロップ率を表示",
    setupReward: "ガチャの報酬コンポーネントを設定",
    largeReward: "大きな報酬コンテナを追加",
    bannerImage: "バナー画像",
    slideImage: "スライド画像",
    labelImg: "アップロードする画像ファイルを選択",
    desLabelImg: "またはここにドラッグ アンド ドロップしてください",
  },
};

export default function CreateAndUpdate({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const formatResult = "YYYY-MM-DD HH:mm:ss";
  const dateFormat = "dd MMM. YYYY HH:mm:ss";
  const [tabIndex, setTabIndex] = useState(0);
  const [trans, setTrans] = useState("en");
  const [checkedIds, setCheckedIds] = useState([]);
  const [gachaDetail, setGachaDetail] = useState("en");
  const [loadingImg, setLoadingImg] = useState({
    banner_image_url: false,
    slide_image_url: false,
  });
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const form = useForm({
    resolver: yupResolver(schemaCreateGacha())
  });
  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = form;
  
  const { 
    isOpen: isOpenSetupReward, 
    onOpen: onOpenSetupReward, 
    onClose: onCloseSetupReward 
  } = useDisclosure();

  const disableWhenJP = useMemo(() => {
    return trans === "jp";
  }, [trans]);

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);
  
  const getDataDetailGacha = async () => {
    const { data } = await getDetailGacha(dataDetail?.id);
    setGachaDetail(data?.data)
  }

  useEffect(() => {
    if (dataDetail) {
      getDataDetailGacha(dataDetail?.id)
    } else {
      reset();
    }
  }, [dataDetail]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    if (index === 1) {
      setTrans("jp");
    } else {
      setTrans("en");
    }
  };

  const transLabel = (label) => {
    return TRANS_LABEL[trans][label];
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const dataSubmit = {
      name: data?.gacha_name,
      feature_description: data?.description,
      start_date: convertToIsoString(data?.start_time),
      end_date: convertToIsoString(data?.end_time),
      ceiling_value: data?.ceiling_value,
      free_orb_cost1: data?.free_orb_cost1,
      free_orb_limit_per_date1: data?.free_orb_limit_per_date1,
      free_orb_cost10: data?.free_orb_cost10,
      free_orb_limit_per_date10: data?.free_orb_limit_per_date10,
      paid_orb_cost1: data?.paid_orb_cost1,
      paid_orb_limit_per_date1: data?.paid_orb_limit_per_date1,
      paid_orb_cost10: data?.paid_orb_cost10,
      paid_orb_limit_per_date10: data?.paid_orb_limit_per_date10,
      display_rate: data?.display_rate,
      banner_image_url: data?.banner_image_url,
      slide_image_url: data?.slide_image_url,
      reward_containers: checkedIds?.map((check) => ({
        id: check?.id,
        chain_id: check?.chain_id,
        grand_prize: check?.grand_prize
      })),
      translations: [
        {
          lang_key: "EN",
          name: data?.gacha_name,
          feature_description: data?.description,
        },
        {
          lang_key: "JP",
          name: data?.gacha_name_jp,
          feature_description: data?.description_jp,
        }
      ],
    }
    try {
      if (idEdit) {
        const res = await updateDetailGacha(dataDetail?.id, dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Gacha Edited Successfully.",
            description: "Gacha Edited Successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose()
        } else {
          toast({
            description: LIST_ERROR_GACHA[res?.data?.message] || LIST_ERROR_GACHA[res?.data?.messages[0]] || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      } else {
        const res = await createNewGacha(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Create Gacha Successfully!",
            description: "Create Gacha Successfully!",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose()
        } 
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleImageChange = async (event, name) => {
    setLoadingImg(prev => ({ ...prev, [name]: !prev[name] }))
    const image = event[0];
    const dataUpload = await uploadImageToServer(image, toast, setValue, name);
    if (dataUpload) {
      setLoadingImg(prev => ({ ...prev, [name]: !prev[name] }))
    }
  };

  const handleDragImage = async (file, name) => {
    if (file.length > NUMBER.ONE) {
      toast({
        description: "The number of uploaded images is greater than one.",
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    setLoadingImg(prev => ({ ...prev, [name]: !prev[name] }))
    const image = file && file[0];
    const dataUpload = await uploadImageToServer(image, toast, setValue, name);
    if (dataUpload) {
      setLoadingImg(prev => ({ ...prev, [name]: !prev[name] }))
    }
  };

  const deleteImage = (name) => {
    setValue(name, null)
  }

  useEffect(() => {
    if (dataDetail?.id) {
      const itemJP = dataDetail?.translations?.find(
        (item) => item?.lang_key === "JP"
      );
      reset({
        start_time: gachaDetail?.start_date
          ? convertToUTCTime(gachaDetail?.start_date)
          : dateAfterNow(0),
        end_time: gachaDetail?.end_date
          ? convertToUTCTime(gachaDetail?.end_date)
          : dateAfterNow(1),
        gacha_name: gachaDetail?.name,
        gacha_name_jp: itemJP?.name,
        description: gachaDetail?.feature_description,
        description_jp: itemJP?.feature_description,
        ceiling_value: gachaDetail?.ceiling_value,
        free_orb_cost1: gachaDetail?.free_orb_cost1 || "0",
        free_orb_limit_per_date1: gachaDetail?.free_orb_limit_per_date1 || null,
        free_orb_cost10: gachaDetail?.free_orb_cost10 || "0",
        free_orb_limit_per_date10: gachaDetail?.free_orb_limit_per_date10 || null,
        paid_orb_cost1: gachaDetail?.paid_orb_cost1 || "0",
        paid_orb_limit_per_date1: gachaDetail?.paid_orb_limit_per_date1 || null,
        paid_orb_cost10: gachaDetail?.paid_orb_cost10 || "0",
        paid_orb_limit_per_date10: gachaDetail?.paid_orb_limit_per_date10 || null,
        display_rate: gachaDetail?.display_rate,
        banner_image_url: gachaDetail?.banner_image_url,
        slide_image_url: gachaDetail?.slide_image_url,
        reward_containers: gachaDetail?.reward_containers,
      });
      setCheckedIds(gachaDetail?.reward_containers)
    } else {
      reset({
        start_time: dateAfterNow(0),
        end_time: dateAfterNow(1),
        ceiling_value: 10,
        display_rate: true,
        rewards: []
      });
    }
  }, [reset, gachaDetail]);

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            mb={4}
            index={tabIndex}
            variant="unstyled"
            onChange={handleTabsChange}
          >
            <TabList gap={2}>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                English
              </Tab>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                Japanese
              </Tab>
            </TabList>
          </Tabs>
          <Flex direction="column" w="100%" gap={4}>
            <Grid templateColumns="repeat(1, 1fr)" gap={4}>
              <Flex direction="column" gap={4}>
                {trans === "en" ? (
                  <Box display="flex" flexDirection="column" gap="8px">
                    <FormInput
                      maxW="100%"
                      name="gacha_name"
                      label={transLabel("gachaName")}
                      placeholder={transLabel("enterGachaName")}
                      isRequired
                    />
                    <FormInput
                      maxW="100%"
                      name="description"
                      label={transLabel("description")}
                      placeholder={transLabel("enterDesc")}
                      isRequired
                    />
                  </Box>
                ) : (
                  <Flex flexDirection="column" gap="8px">
                    <FormInput
                      maxW="100%"
                      name="gacha_name_jp"
                      label={transLabel("gachaName")}
                      placeholder={transLabel("enterGachaName")}
                      isRequired
                    />
                    <FormInput
                      maxW="100%"
                      name="description_jp"
                      label={transLabel("description")}
                      placeholder={transLabel("enterDesc")}
                      isRequired
                    />
                  </Flex>
                )}
                <Flex alignItems="flex-start" gap="16px" w="100%">
                  <Flex direction="column" gap="8px" w="100%">
                    <FormLabelComponent
                      m="0px"
                      title={transLabel("start")}
                      isRequired
                    />
                    <FormDatePicker
                      disabled={disableWhenJP}
                      name="start_time"
                      showTimeSelect
                      dateFormat={dateFormat}
                      h="40px"
                      onChange={(e) => {
                        const time = moment(e).format(formatResult);
                        setValue("start_time", time);
                      }}
                    />
                  </Flex>
                  <Flex direction="column" gap="8px" w="100%">
                    <FormLabelComponent
                      m="0px"
                      title={transLabel("end")}
                      isRequired
                    />
                    <FormDatePicker
                      disabled={disableWhenJP}
                      name="end_time"
                      showTimeSelect
                      dateFormat={dateFormat}
                      h="40px"
                      onChange={(e) => {
                        const time = moment(e).format(formatResult);
                        setValue("end_time", time);
                      }}
                    />
                  </Flex>
                </Flex>
                <FormUpload
                  label={transLabel("labelImg")}                  
                  desLabel={transLabel("desLabelImg")}
                  title={transLabel("bannerImage")}
                  name="banner_image_url"
                  type='IMAGE'
                  accept={"image/*"}
                  isRequired
                  handleOnChange={(e) =>
                    handleImageChange(e, "banner_image_url")
                  }
                  handleDragImage={(val) =>
                    handleDragImage(val, "banner_image_url")
                  }
                  deleteImage={() => deleteImage("banner_image_url")}
                  disabled={disableWhenJP}
                  loading={loadingImg?.banner_image_url}
                />
                <FormUpload
                  label={transLabel("labelImg")}                  
                  desLabel={transLabel("desLabelImg")}
                  title={transLabel("slideImage")}
                  name="slide_image_url"
                  type='IMAGE'
                  accept={"image/*"}
                  isRequired
                  handleOnChange={(e) =>
                    handleImageChange(e, "slide_image_url")
                  }
                  handleDragImage={(val) =>
                    handleDragImage(val, "slide_image_url")
                  }
                  deleteImage={() => deleteImage("slide_image_url")}
                  disabled={disableWhenJP}
                  loading={loadingImg?.slide_image_url}
                />
                <FormInput
                  maxW="100%"
                  name="ceiling_value"
                  label={transLabel("ceiling")}
                  min={0}
                  type="number"
                  isRequired
                  disabled={disableWhenJP}
                />
                <Grid templateColumns="repeat(1, 1fr)" gap={4}>
                  <Flex flexDirection="column" gap={4}>
                    <Text fontSize="16px" fontWeight="600">1 {transLabel("pullFree")}</Text>
                    <Flex alignItems="flex-start" gap="16px" w="100%">
                      <Flex alignItems="flex-end" gap="8px" w="100%">
                        <FormInput
                          maxW="100%"
                          type="number"
                          min={0}
                          name="free_orb_cost1"
                          disabled={disableWhenJP}
                          label={transLabel("cost")}
                          placeholder={transLabel("enterValue")}
                          isRequired
                        />
                        <Text lineHeight="40px">Orbs</Text>
                      </Flex>
                      <FormInput
                        wrapperProps={{ w: "100%" }}
                        type="number"
                        min={1}
                        name="free_orb_limit_per_date1"
                        disabled={disableWhenJP}
                        label={transLabel("playLimit")}
                        placeholder={transLabel("enterValue")}
                        isRequired
                      />
                    </Flex>
                  </Flex>
                  <Flex flexDirection="column" gap={4}>
                    <Text fontSize="16px" fontWeight="600">10 {transLabel("pullFree")}</Text>
                    <Flex alignItems="flex-start" gap="16px" w="100%">
                      <Flex alignItems="flex-end" gap="8px" w="100%">
                        <FormInput
                          maxW="100%"
                          type="number"
                          min={0}
                          name="free_orb_cost10"
                          disabled={disableWhenJP}
                          label={transLabel("cost")}
                          placeholder={transLabel("enterValue")}
                          isRequired
                        />
                        <Text lineHeight="40px">Orbs</Text>
                      </Flex>
                      <FormInput
                        wrapperProps={{ w: "100%" }}
                        type="number"
                        min={1}
                        name="free_orb_limit_per_date10"
                        disabled={disableWhenJP}
                        label={transLabel("playLimit")}
                        placeholder={transLabel("enterValue")}
                        isRequired
                      />
                    </Flex>
                  </Flex>
                  <Flex flexDirection="column" gap={4}>
                    <Text fontSize="16px" fontWeight="600">1 {transLabel("pullPaid")}</Text>
                    <Flex alignItems="flex-start" gap="16px" w="100%">
                      <Flex alignItems="flex-end" gap="8px" w="100%">
                        <FormInput
                          maxW="100%"
                          type="number"
                          min={0}
                          name="paid_orb_cost1"
                          disabled={disableWhenJP}
                          label={transLabel("cost")}
                          placeholder={transLabel("enterValue")}
                          isRequired
                        />
                        <Text lineHeight="40px">Orbs</Text>
                      </Flex>
                      <FormInput
                        wrapperProps={{ w: "100%" }}
                        type="number"
                        min={1}
                        name="paid_orb_limit_per_date1"
                        disabled={disableWhenJP}
                        label={transLabel("playLimit")}
                        placeholder={transLabel("enterValue")}
                        isRequired
                      />
                    </Flex>
                  </Flex>
                  <Flex flexDirection="column" gap={4}>
                    <Text fontSize="16px" fontWeight="600">10 {transLabel("pullPaid")}</Text>
                    <Flex alignItems="flex-start" gap="16px" w="100%">
                      <Flex alignItems="flex-end" gap="8px" w="100%">
                        <FormInput
                          maxW="100%"
                          type="number"
                          min={0}
                          name="paid_orb_cost10"
                          disabled={disableWhenJP}
                          label={transLabel("cost")}
                          placeholder={transLabel("enterValue")}
                          isRequired
                        />
                        <Text lineHeight="40px">Orbs</Text>
                      </Flex>
                      <FormInput
                        wrapperProps={{ w: "100%" }}
                        type="number"
                        min={1}
                        name="paid_orb_limit_per_date10"
                        disabled={disableWhenJP}
                        label={transLabel("playLimit")}
                        placeholder={transLabel("enterValue")}
                        isRequired
                      />
                    </Flex>
                  </Flex>
                  <Flex alignItems="center" gap="12px" w="100%" padding="8px 0" position="relative">
                    <FormLabelComponent
                      m="0px"
                      title={transLabel("dropRate")}
                    />
                    <SwitchType
                      color="#e04995"
                      colorThumb="#fff"
                      size="lg"
                      position="relative"
                      colorScheme="teal"
                      isChecked={watch('display_rate')}
                      onChange={(e) => {
                        setValue("display_rate", e.target?.checked)
                      }}
                    >
                      <Box
                        as="span"
                        position="absolute"
                        left="25%"
                        top="50%"
                        transform="translate(-50%, -50%)"
                        color="white"
                        fontWeight="bold"
                        fontSize="12px"
                        pointerEvents="none" // Prevents pointer events from interfering with the switch
                      >
                        On
                      </Box>
                      {!watch('display_rate') && (
                        <Box
                          as="span"
                          position="absolute"
                          left="64%"
                          top="50%"
                          transform="translate(-50%, -50%)"
                          fontWeight="bold"
                          color="#4a4a4a"
                          fontSize="12px"
                          pointerEvents="none" // Prevents pointer events from interfering with the switch
                        >
                          Off
                        </Box>
                      )}
                    </SwitchType>
                  </Flex>
                  <Flex flexDirection="column" gap={4}>
                    <FormInput
                      maxW="100%"
                      name="rewards"
                      disabled={disableWhenJP}
                      label={transLabel("setupReward")}
                      placeholder={transLabel("largeReward")}
                      isRequired
                      onClick={() => onOpenSetupReward()}
                      value=""
                      onChange={() => null}
                      autoComplete="off"
                    />
                  </Flex>
                  {checkedIds?.length > 0 && (
                    <TableListReward 
                      setCheckedIds={setCheckedIds}
                      checkedIds={checkedIds}
                      setValue={setValue}
                    />
                  )} 
                </Grid>
              </Flex>
            </Grid>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="700px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "Edit Gacha" : "New Gacha"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        {errors?.gacha_name_jp?.message && trans === "en" && <TextError>Gacha name Japan is {errors?.gacha_name_jp?.message}</TextError>}
        {errors?.description_jp?.message && trans === "en" && <TextError>Description name Japan is {errors?.description_jp?.message}</TextError>}
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            {idEdit ? "Update" : "Add"}
          </ButtonType>
        </ModalFooter>
      </ModalContent>
      {isOpenSetupReward && (
        <ModalSelectPrize
          isOpen={isOpenSetupReward}
          onClose={onCloseSetupReward}
          setCheckedIds={setCheckedIds}
          checkedIds={checkedIds}
          setValue={setValue}
          watch={watch}
          idEdit={idEdit}
          dataDetail={dataDetail}
        />
      )}
    </Modal>
  );
}
  