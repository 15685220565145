import {
  FormLabel,
  Text,
  Grid,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  TagLabel,
  Tag,
  useToast,
} from "@chakra-ui/react";
import { getDayLLLL } from "utils/time";
import { lowerCase, upperFirst } from "lodash";
import {
  LEADER_BOARD_OPTIONS,
  TRIGGER_OPTIONS,
} from "./constants";
import { useState, useEffect } from "react";
import { getDetailEventManagement } from "api/event.api";
import { getDetailGacha } from "api/gacha.api";


export default function DetailBanner({
  isOpen,
  onClose,
  dataDetail,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const [event, setEvent] = useState([]);
  const [gacha, setGacha] = useState([]);
  const toast = useToast();


  const eventDetail = async () => {
    try {
      const { data } = await getDetailEventManagement(dataDetail?.specific_item?.event?.event_id);
      if (data?.success) {
        const res = data?.data;
        setEvent(res);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }

  const gachaDetail = async () => {
    try {
      const { data } = await getDetailGacha(dataDetail?.specific_item?.gacha?.gacha_id);
      if (data?.success) {
        const res = data?.data;
        setGacha(res);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }

  useEffect(() => {
    if(dataDetail?.specific_item?.event?.event_id) {
      eventDetail();
    }
    if(dataDetail?.specific_item?.gacha?.gacha_id) {
      gachaDetail();
    }
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="1000px" overflowY="auto" maxHeight="90vh" overflow="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          DETAIL BANNER ADS
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px" height="calc(100% - 70px)" overflowY="auto">
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <div>
                <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                  Start Date
                </FormLabel>
                <Text
                  ml={2}
                  mt={4}
                  fontSize="16px"
                  fontWeight="400"
                  color={textColor}
                >
                  {getDayLLLL(dataDetail?.start_date * 1000)}
                </Text>
              </div>
              <div>
                <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                  End Date
                </FormLabel>
                <Text
                  ml={2}
                  mt={4}
                  fontSize="16px"
                  fontWeight="400"
                  color={textColor}
                >
                  {getDayLLLL(dataDetail?.end_date * 1000)}
                </Text>
              </div>
            </Grid>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Placement
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                {dataDetail?.placement}
              </Text>
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Image
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                <Image src={dataDetail?.image} w="100%" h="100%" alt="image banner" />
              </Text>
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Destination
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                {dataDetail?.destination == "PVE" ? dataDetail?.destination : upperFirst(lowerCase(dataDetail?.destination))}
              </Text>
            </div>
            {
              dataDetail?.destination === "URL" && (
                <div>
                  <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                    URL
                  </FormLabel>
                  <Text
                    ml={2}
                    mt={4}
                    fontSize="16px"
                    fontWeight="400"
                    color={textColor}
                  >
                    {dataDetail?.specific_item?.url?.link}
                  </Text>
                </div>
              )
            }
            {
              dataDetail?.destination === "SHOP" && (
                <div>
                  <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                    Specific Item
                  </FormLabel>
                  <Text
                    ml={2}
                    mt={4}
                    fontSize="16px"
                    fontWeight="400"
                    color={textColor}
                  >
                     {dataDetail?.specific_item?.shop?.point}
                  </Text>
                </div>
              )
            }
            {
              dataDetail?.destination === "GACHA" && (
                <div>
                  <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                    Specific Item
                  </FormLabel>
                  <Text
                    ml={2}
                    mt={4}
                    fontSize="16px"
                    fontWeight="400"
                    color={textColor}
                  >
                     {gacha?.name}
                  </Text>
                </div>
              )
            }
            {
              dataDetail?.destination === "RANKING" && (
                <div>
                  <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                    Specific Item
                  </FormLabel>
                  <Text
                    ml={2}
                    mt={4}
                    fontSize="16px"
                    fontWeight="400"
                    color={textColor}
                  >
                     {LEADER_BOARD_OPTIONS.find(item => item.value === dataDetail?.specific_item?.ranking?.leader_board)?.label}
                  </Text>
                </div>
              )
            }
            {
              dataDetail?.destination === "PVE" && (
                <div>
                  <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                    Specific Item
                  </FormLabel>
                  <Text
                    ml={2}
                    mt={4}
                    fontSize="16px"
                    fontWeight="400"
                    color={textColor}
                  >
                    {
                      dataDetail?.specific_item?.prizes.map((prize) => (
                        <Tag key={prize.prize_id} colorScheme="teal" mr={2} mb={2}>
                          <TagLabel>{prize.prize_name}</TagLabel>
                        </Tag>
                      ))
                    }
                  </Text>
                </div>
              )
            }
            {
              dataDetail?.destination === "EVENT" && (
                <div>
                  <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                    Specific Item
                  </FormLabel>
                  <Text
                    ml={2}
                    mt={4}
                    fontSize="16px"
                    fontWeight="400"
                    color={textColor}
                  >
                     {event?.name}
                  </Text>
                </div>
              )
            }
            {
              dataDetail?.destination === "MODAL" && (
                <>
                  <div>
                    <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                      Modal Title
                    </FormLabel>
                    <Text
                      ml={2}
                      mt={4}
                      fontSize="16px"
                      fontWeight="400"
                      color={textColor}
                    >
                      {dataDetail?.specific_item?.modal?.modal_title}
                    </Text>
                  </div>
                  <div>
                    <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                      Modal Background
                    </FormLabel>
                    <Text
                      ml={2}
                      mt={4}
                      fontSize="16px"
                      fontWeight="400"
                      color={textColor}
                    >
                      <Image src={dataDetail?.specific_item?.modal?.modal_background} w="100%" h="100%" alt="image background modal" />
                    </Text>
                  </div>
                </>
              )
            }
            {
              dataDetail?.destination === "MARKET_PLACE" && (
                <div>
                  <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                    URL
                  </FormLabel>
                  <Text
                    ml={2}
                    mt={4}
                    fontSize="16px"
                    fontWeight="400"
                    color={textColor}
                  >
                     {dataDetail?.specific_item?.market_place?.link}
                  </Text>
                </div>
              )
            }
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Trigger (for 1st time)
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                {dataDetail?.gift ? TRIGGER_OPTIONS.find(item => item.value === dataDetail?.gift)?.label : "---"}
              </Text>
            </div>

            {
              dataDetail?.trigger_detail?.free_ticket && (
                <div>
                  <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                    Free Ticket: <Text as="span" fontWeight="400">{dataDetail?.trigger_detail?.free_ticket}</Text>
                  </FormLabel>
                  <Text
                    ml={2}
                    mt={4}
                    mb={2}
                    fontSize="16px"
                    fontWeight="400"
                    color={textColor}
                  >
                  </Text>
                </div>
              )
            }
            {
              dataDetail?.trigger_detail?.invitation_card && (
                <div>
                  <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                    Invitation Card: <Text as="span" fontWeight="400">{dataDetail?.trigger_detail?.invitation_card}</Text>
                  </FormLabel>
                </div>
              )
            }
            <Grid templateColumns="repeat(3, 1fr)" gap={4}>
              {
                !!dataDetail?.trigger_detail?.sphere && (
                  <div>
                    <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                      Sphere: <Text as="span" fontWeight="400">{dataDetail?.trigger_detail?.sphere}</Text>
                    </FormLabel>
                  </div>
                )
              }
              {
                !!dataDetail?.trigger_detail?.orb && (
                  <div>
                    <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                      Orb: <Text as="span" fontWeight="400">{dataDetail?.trigger_detail?.orb}</Text>
                    </FormLabel>
                  </div>
                )
              }
              {
                !!dataDetail?.trigger_detail?.cp && (
                  <div>
                    <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                      CP: <Text as="span" fontWeight="400">{dataDetail?.trigger_detail?.cp}</Text>
                    </FormLabel>
                  </div>
                )
              }
            </Grid>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
