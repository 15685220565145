import {
  Flex,
  Text
} from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import { FormProvider, useForm } from "react-hook-form";
import { STATUS_USER_PRESENTBOX } from "./constant"

export default function ConditionPresentBoxHistory({ isLoading, setParams, params }) {
  const form = useForm();
  const { setValue, reset, handleSubmit } = form;

  const onSubmit = (data) => {
    const dataSubmit = {
      keyword: data?.keyword,
      status: data?.status ,
    }

    if (!isLoading) {
      const newParams = {
        ...params,
        ...dataSubmit,
        page: 1,
      };
      setParams(newParams);
    }
  };

  return (
    <Flex
      gap="16px"
      flexWrap="wrap"
      direction="column"
      justifyContent="flex-start"
      w="100%"
      alignItems="flex-start"
    >
      <FormProvider {...form} w="100%">
        <form
          style={{
            width: "100%",
            minWidth: "600px",
            display: 'flex',
            justifyContent: 'space-between',
            gap: '8px'
          }}
          onSubmit={handleSubmit(onSubmit)}>
          <Flex direction="row" gap={6}>
            <FormInput
              name="keyword"
              label="Search"
              placeholder="Search by key word"
              w="300px"
              onChange={(e) => {
                setValue("keyword", e.target.value);
                handleSubmit(onSubmit)();
              }}
            />
            <FormSelect
              name="status"
              label="Filter by Status"
              options={[{ label: "All", value: "" }, ...STATUS_USER_PRESENTBOX]}
              w="300px"
              onChange={(e) => {
                setValue("status", e.target.value);
                handleSubmit(onSubmit)();
              }}
            />
          </Flex>
          <Text
            cursor="pointer"
            color="#1480FF"
            fontSize="14px"
            fontWeight="500"
            alignSelf="flex-end"
            onClick={() => {
              reset({
                keyword: "",
                status: "",
                recipient: "",
              });
              handleSubmit(onSubmit)();
            }}
          >
            Reset
          </Text>
        </form>
      </FormProvider>
    </Flex>
  );
}
