import { Flex, Text, useToast } from "@chakra-ui/react";
import { getMachineSummary } from "api/dashboad.api";
import { useCallback, useEffect, useState } from "react";
import { STATE_SUMMARY_MACHINE } from "../constant";
import { useHistory } from "react-router-dom";
import { useCronJob } from "hooks/useCronJob";

export default function MachineDashboard() {
  const history = useHistory();
  const toast = useToast();
  const [dataMachine, setDataMachine] = useState()

  const getSummaryMachine = useCallback(
    async (newParams = {}, isReStartJon = true) => {
      try {
        if (isReStartJon) {
          stopJob();
        }
        const { data } = await getMachineSummary({
          ...newParams,
        });
        if (data?.success) {
          setDataMachine(data?.data);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        if (isReStartJon) {
          startJob();
        }
      }
    },
    []
  );

  const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  useEffect(() => {
    getSummaryMachine()
    return () => {
      stopJob();
    };
  }, [getSummaryMachine])

  const { startJob, stopJob } = useCronJob({
    timeCron: 60000,
    task: () => {
      getSummaryMachine({}, false);
    },
  });

  return (
    <Flex 
      borderRadius='4px'
      border='1px solid #ccc'
      direction='column'
      background='#fff'
      w='100%'
    >
      <Flex alignItems='center' justifyContent='space-between' padding='4px 12px' borderBottom='1px solid #ccc'>
        <Text fontSize="lg" fontWeight='600'>Machine</Text>
        <Text fontSize='20px' cursor='pointer' onClick={() => history.push("/admin/machine/machine-map")}>...</Text>
      </Flex>
      <Flex padding='4px 10px'>
        <Flex 
          direction='column' 
          justifyContent='space-between' 
          width='100%' 
          borderRight={'1px solid #ccc'} 
          padding='8px'
        >
          <Text fontSize='12px' fontWeight={700} color='#bbbbbb'>Total</Text>
          <Text fontSize='20px' fontWeight={700} color={getRandomColor()}>{dataMachine?.total}</Text>
        </Flex>
        {dataMachine?.machine_state_summaries?.map((summary, index) => (
          <Flex 
            key={index} 
            direction='column' 
            justifyContent='space-between' 
            width='100%' 
            borderRight={index === dataMachine?.machine_state_summaries?.length - 1 ? 'none' : '1px solid #ccc'} 
            padding='8px'
          >
            <Text fontSize='12px' fontWeight={700} color='#bbbbbb'>{STATE_SUMMARY_MACHINE[summary?.state]}</Text>
            <Text fontSize='20px' fontWeight={700} color={getRandomColor()}>{summary?.amount}</Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
