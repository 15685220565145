import { Icon } from "@chakra-ui/icons";
import {
  Flex,
  Image,
  Stack,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { getChainIcon } from "views/NFTsManagement/utils/utils";
import { LIST_GRAND_PRIZE } from "views/GachaManagement/constants";
import FormSelect from "components/form/FormSelect";
import { DeleteIcon } from "components/Icons/DeleteIcon";

export default function ItemsReward({
  item,
  handleChangePoint,
  checkedIds,
  setCheckedIds,
  setValue,
}) {
  const textColor = useColorModeValue("#000000", "white");

  const handleDelete = (item) => {
    const dataFilter = checkedIds?.filter((check) => check?.id !== item?.id)
    setCheckedIds(dataFilter)
    setValue("rewards", dataFilter)
  }

  return (
    <Tr>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.id}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.name}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Image
            boxSize="30px"
            src={getChainIcon(item?.chain_id).src}
            alt={getChainIcon(item?.chain_id).alt}
          />
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Flex alignItems="center" gap="4px" flexWrap="wrap">
            {item?.gacha_items?.map((gacha, index) => (
              index < 3 && <Text key={index} p="4px 6px" borderRadius="16px" bg="#95ff87">
                {gacha.name}
              </Text>
            ))}
            {item?.gacha_items?.length > 3 && <Text>...</Text>}
          </Flex>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.weight}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <FormSelect
            name="contract"
            value={item?.grand_prize}
            options={LIST_GRAND_PRIZE}
            w="100%"
            onChange={(e) => handleChangePoint(item.id, 'grand_prize', e.target.value)}
          />
        </Stack>
      </Td>
      <Td>
        <Flex gap={6} align="center">
          <Icon
            onClick={() => handleDelete(item)}
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={DeleteIcon}
          />
        </Flex>
      </Td>
    </Tr>
  );
}
