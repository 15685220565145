import {
  Flex,
  Icon,
  Image,
  Stack,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { useMemo } from "react";
import { EditIcon } from "components/Icons/EditIcon";

const MachineRow = ({
  machine,
  checkedGroups,
  setCheckedGroups,
  handleOpenCreateOrUpdate,
}) => {
  const textColor = useColorModeValue("#000000", "white");
  const isMachineChecked = useMemo(() => {
    return checkedGroups?.some((item) => item?.id === machine?.id);
  }, [machine?.id, checkedGroups]);

  const handleCheckboxChange = () => {
    if (isMachineChecked) {
      setCheckedGroups(checkedGroups.filter((item) => item?.id !== machine?.id));
    } else {
      setCheckedGroups([...checkedGroups, machine]);
    }
  };

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isMachineChecked}
          onChange={() => handleCheckboxChange()}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.card_name}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Image
            src={machine?.url_image}
            w="100px"
            alt={machine?.url_image}
          />
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.event_name}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.event_status}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.is_prize_attached ? "No" : "Yes"}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.prize_status}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Flex gap={6} align="center">
          <Icon
            onClick={() => (!machine?.event_status || machine?.event_status === 'OPEN' ) && machine?.prize_status === 'INACTIVE' ? handleOpenCreateOrUpdate() : null}
            fontSize="30px"
            borderRadius="8px"
            p="7px"
            cursor="pointer"
            bg="#1480FF"
            as={EditIcon}
            opacity={(!machine?.event_status || machine?.event_status === 'OPEN' ) && machine?.prize_status === 'INACTIVE' ? 1 : 0.5}
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default MachineRow;
