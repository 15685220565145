import * as yup from 'yup'

function calculateHWFWLength(value) {
  if (!value) return 0;
  return [...value.trim()].reduce((sum, char) => {
    const code = char.charCodeAt(0);
    const isFullWidth = code >= 0xFF00 && code <= 0xFFEF;
    return sum + (isFullWidth ? 2 : 1);
  }, 0);
}

const schemaPresentBox = () =>
  yup.object().shape({
    mgmt_name: yup
      .string()
      .trim()
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    name_en: yup
      .string()
      .trim()
      .required("Required")
      .test("name-en-length", "Name must be less than 60 characters.", (value) => {
        const length = calculateHWFWLength(value?.replace(/<[^>]+>/g, "").trim());
        return length <= 60;
      }),
    name_jp: yup
      .string()
      .trim()
      .required("Required")
      .test("name-jp-length", "Name must be less than 60 characters.", (value) => {
        const length = calculateHWFWLength(value?.replace(/<[^>]+>/g, "").trim());
        return length <= 60;
      }),
    description_en: yup
      .string()
      .trim()
      .test("html-stripped-length", "Description must be less than 500 characters.", (value) => {
        const length = calculateHWFWLength(value?.replace(/<[^>]+>/g, "").trim());
        return length <= 500;
      }),
    description_jp: yup
      .string()
      .trim()
      .test("html-stripped-length-jp", "Description must be less than 500 characters.", (value) => {
        const length = calculateHWFWLength(value?.replace(/<[^>]+>/g, "").trim());
        return length <= 500;
      }),
    distribution_start_date: yup
      .date()
      .required("Required")
      .when('distribute_type', (distribute_type, schema) => {
        if (distribute_type.includes("STANDARD")) {
          return schema.test(
            "is-future-minute",
            "Public date must be in the future",
            function (value) {
              const now = new Date();
              now.setSeconds(0, 0);
              const inputTime = new Date(value);
              inputTime.setSeconds(0, 0);
              return inputTime.getTime() >= now.getTime();
            }
          );
        } else if (distribute_type.includes("CONDITION")) {
          return schema.when('distribute_condition', (distribute_condition, schemaChild) => {
            if (distribute_condition.includes("EVENT_TOP_RANK")) {
              return schemaChild.test(
                "greater-or-equal-event-end-time",
                "Distribution start date must be greater than or equal to event end time",
                function (value) {
                  const { event_end_time } = this.parent;
                  if (!value || !event_end_time) return false;
                  const distributionDate = new Date(value).getTime();
                  const eventEndDate = new Date((event_end_time + 15 * 60) * 1000).getTime();
                  return distributionDate >= eventEndDate;
                }
              );
            } else if (distribute_condition.includes("EVENT_INVITATION_CARD")) {
              return schemaChild.test(
                "greater-or-equal-event-start-time",
                "Distribution start date must be less than to event end time",
                function (value) {
                  const { event_end_time } = this.parent;
                  if (!value || !event_end_time) return false;
                  const distributionDate = new Date(value).getTime();
                  const eventEndDate = new Date(event_end_time * 1000).getTime();
                  return distributionDate < eventEndDate;
                }
              );
            }
          });
        }
      }),
    receivers: yup
      .array()
      .nullable()
      .when("recipient_type", {
        is: "ALL",
        then: () => yup.array().nullable(),
        otherwise: () =>
          yup.array().of(yup
            .mixed()
            .test(
              "is-object-or-string",
              "Each item must be an object or a string",
              (value) => typeof value === "string" || typeof value === "object"
            )).min(1, "Required"),
      }),
    distribute_type: yup
      .string()
      .required("Please select an option"),
    distribute_condition: yup
      .string()
      .when("distribute_type", {
        is: "CONDITION",
        then: () =>
          yup.
            string().
            required("Distribute condtion is required when Conditional is selected"),
        otherwise: () =>
          yup.string().nullable(),
      }),
    event: yup
      .string()
      .when("distribute_type", {
        is: "CONDITION",
        then: () => yup.string().required("Event is required when Conditional is selected"),
        otherwise: () => yup.string().nullable(),
      }),
    from_rank: yup
      .number()
      .nullable()
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .when("distribute_condition", {
        is: "EVENT_TOP_RANK",
        then: () => yup
          .number()
          .required("Required")
          .transform((_, val) => {
            if (val) {
              return Number(val) || Number(val) === 0 ? Number(val) : null;
            }
            return null;
          })
          .min(1, "From must be greater than or equal to 1"),
        otherwise: () => yup.number().nullable(),
      }),
    to_rank: yup
      .number()
      .nullable()
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .when("distribute_condition", {
        is: "EVENT_TOP_RANK",
        then: () => yup
          .number()
          .required("Required")
          .transform((_, val) => {
            if (val) {
              return Number(val) || Number(val) === 0 ? Number(val) : null;
            }
            return null;
          })
          .min(1, "From must be greater than or equal to 1")
          .test(
            "greaterThanFrom",
            "To must be greater than or equal to From",
            function (value) {
              const { from_rank } = this.parent;
              return value === undefined || value >= (from_rank || 0);
            }
          ),
        otherwise: () => yup.number().nullable(),
      }),
    resource: yup
      .object()
      .nullable(),
    details: yup
      .array()
      .of(yup.object())
      .nullable(),
    is_no_expiration: yup
      .boolean().required(),
    expired_date: yup
      .number()
      .nullable()
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("Must be an integer")
      .when("is_no_expiration", {
        is: false,
        then: () => yup
          .number()
          .transform((_, val) => {
            if (val) {
              return Number(val) || Number(val) === 0 ? Number(val) : null;
            }
            return null;
          })
          .required("Expiration days are required")
          .positive("Expiration days must be greater than 0")
          .integer("Expiration days must be an integer"),
        otherwise: () => yup.number().nullable(),
      }),
  })
  .test({
    name: "resource-or-detail",
    message: "Required",
    test: function (value) {
      if (
        !value ||
        (
          (!value.resource || Object.keys(value.resource).length === 0) &&
          (!value.details || value.details.length === 0)
        )
      ) {
        return this.createError({
          path: "resource",
          message: "Required",
        });
      }
      return true;
    },
  });

export default schemaPresentBox
