export const SITE_LABEL = {
  EMAIL: 'Email',
  GMAIL: 'Gmail',
  DM2: 'Oasys',
  IMMUTABLE: 'Immutable',
}

export const ERROR_SYSTEM = {
  INVALID_ACCOUNT: "Invalid Account"
}
