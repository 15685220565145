import { useCallback, useEffect, useState } from "react";
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import LeaderBoardRow from "./row";
import ConditionLeaderBoard from "./condition";
import { listPrizesGameModeApi } from "stores/prize/action";
import { useSelector } from "react-redux";
import { prizeSelector } from "stores/prize/selector";
import { useDispatch } from "react-redux";

export default function ModalListPrize({
  isOpen,
  onClose,
  dataDetail,
  type,
  prizeTypes,
}) {
  const labels = [
    "Name",
    "Image",
    "Type",
    "Max. Attempt",
    "Count",
    "Sale Status",
    "Sale Start Time",
    "Sale End Time",
    // "Action",
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [listPrizes, setListPrizes] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    mode_type: type,
    game_mode_id: dataDetail?.id
  });
  const textColor = useColorModeValue("#000000", "white");
  const dispatch = useDispatch();
  const { listPrizesGameMode } = useSelector(prizeSelector);

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };
  
  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  // const getPrizeByGameMode = useCallback(
  //   async (newParams = {}) => {
  //     try {
  //       setIsLoading(true);
  //       const { data } = await getPrizeGameMode({
  //         ...params,
  //         ...newParams,
  //       });
  //       if (data?.success) {
  //         const res = data?.data;
  //         setListPrizes(res?.records);
  //         setTotalPage(res?.total_page);
  //       }
  //     } catch (error) {
  //       toast({
  //         description: error?.message || error?.messages[0],
  //         status: "error",
  //         position: "bottom-right",
  //       });
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   },
  //   [params]
  // );

  const getPrizeByGameMode = () => {
    dispatch(listPrizesGameModeApi(params));
  };

  useEffect(() => {
    if (listPrizesGameMode) {
      setListPrizes(() => listPrizesGameMode?.records || []);
      setTotalPage(listPrizesGameMode?.total_page || 0);
    }
  }, [listPrizesGameMode]);

  useEffect(() => {
    getPrizeByGameMode();
  }, [params]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xxl">
      <ModalOverlay />
      <ModalContent maxW="900px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {dataDetail?.mode_name} - List of Prizes
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">
          <Flex flexDirection="column">
            <Card px="0px">
              {/* <CardHeader display="flex" flexDirection="column" gap="12px" mb="24px">
                <ConditionLeaderBoard
                  isLoading={isLoading}
                  setParams={setParams}
                  params={params}
                  prizeTypes={prizeTypes}
                />
              </CardHeader> */}
              <CardBody overflowX="auto">
                <TableCustom
                  isCheck={false}
                  labels={labels}
                  isLoading={isLoading}
                  dataRow={listPrizes}
                >
                  <>
                    {listPrizes?.map((item, index) => {
                      return (
                        <LeaderBoardRow
                          key={item?.id}
                          item={item}
                          index={index}
                        />
                      );
                    })}
                  </>
                </TableCustom>
              </CardBody>
              <Paginate
                page={params?.page}
                pageCount={totalPage}
                limit={params.limit}
                onChangeLimit={onChangeLimit}
                handleChangePage={handleChangePage}
              />
            </Card>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
  