import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getPrize } from "api/prize.api";
import ButtonType from "components/Button";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import { FIRST_PAGE, LIMIT_PAGE } from "constants/constants";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getPrizeByIdApi } from "stores/prize/action";
import { convertParams } from "utils/object";
import PrizeDetail from "views/PrizeSettings/components/detail";
import SelectPrizeRow from "./row";

export default function SelectPrizeModal({
  isOpen,
  onClose,
  handleSelectPrize,
  checkedPrize,
}) {
  const { t } = useTranslation("prize");
  const dispatch = useDispatch();
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: FIRST_PAGE,
    limit: LIMIT_PAGE,
  });
  const [checkedIds, setCheckedIds] = useState(checkedPrize || []);

  const {
    isOpen: isOpenDetail,
    onOpen: onOpenDetail,
    onClose: onCloseDetail,
  } = useDisclosure();

  const getListPrize = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const paramsPrize = convertParams({
          ...params,
          ...newParams,
        });
        const { data } = await getPrize(paramsPrize);
        if (data?.success) {
          const res = data?.data;
          setDataTable(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const labels = [
    t("label_name"),
    t("label_image"),
    t("label_type"),
    "Play Fee",
    "MIN.ATTEMPT",
    "Sale Status",
    "Sale start time",
    "Sale end time",
    "Action",
  ];

  const handleSetDataDetail = (id = "") => {
    dispatch(getPrizeByIdApi(id));
    onOpenDetail();
  };

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };
  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedIds(dataTable);
    } else {
      setCheckedIds([]);
    }
  };

  const onSelectPrize = () => {
    handleSelectPrize(checkedIds);
    onClose();
  };

  useEffect(() => {
    getListPrize();
  }, [getListPrize]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxW="700px" overflowY="auto">
          <ModalHeader
            borderBottom="1px solid #EEEFF2"
            w="100%"
            textAlign="center"
            margin="auto"
            color={textColor}
          >
            {"Select Prize(s)"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p="24px">
            <TableCustom
              labels={labels}
              isLoading={isLoading}
              isCheck
              isChecked={
                dataTable?.length > 0 && checkedIds.length === dataTable?.length
              }
              onChangeChecked={onChangeChecked}
              dataRow={dataTable}
            >
              <>
                {dataTable?.map((prize, index) => {
                  return (
                    <SelectPrizeRow
                      key={index}
                      setCheckedIds={setCheckedIds}
                      checkedIds={checkedIds}
                      handleSetDataDetail={handleSetDataDetail}
                      isHiddenEdit
                      listPrize={prize}
                    />
                  );
                })}
              </>
            </TableCustom>
            {(totalPage && (
              <Paginate
                page={params?.page}
                pageCount={totalPage}
                limit={params.limit}
                onChangeLimit={onChangeLimit}
                handleChangePage={handleChangePage}
              />
            )) ||
              ""}
          </ModalBody>
          <ModalFooter
            borderTop="1px solid #EEEFF2"
            w="30%"
            textAlign="center"
            margin="auto"
            gap="20px"
          >
            <ButtonType
              mt={4}
              m="auto"
              w="100%"
              h="46px"
              borderRadius="5px"
              onClick={onSelectPrize}
            >
              SELECT
            </ButtonType>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isOpenDetail && (
        <PrizeDetail isOpen={isOpenDetail} onClose={onCloseDetail} />
      )}
    </>
  );
}
