import { useEffect, useMemo, useState} from "react";
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  Text,
  Grid,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import ButtonType from "components/Button";
import FormSelect from 'components/form/FormSelect';
import { TYPE_CURRENCY } from '../../constant'
import { FormProvider, useForm, } from "react-hook-form";
import CurrencyItem from "./currencyItem";
import CardItem from "./cardItem";
import RowValue from "./rowValue";
import FormInput from "components/form/FormInput";
import TableCustom from "components/Tables/CustomTable";
import { FIRST_PAGE, LIMIT_PAGE } from "constants/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import SchemaPresentBoxItem from "./schema"

export default function ModalSelectItem({
  isOpen,
  onClose,
  setValueParent,
  checkedIds,
  setCheckedIds,
  event,
  getValuesParent,
  clearErrorsParent,
}) {
  const form = useForm({
    resolver: yupResolver(SchemaPresentBoxItem()),
    defaultValues: {
      type: "",
      orb: "",
      bcoin: "",
      sphere: "",
      ap: "",
      xp: "",
      ticket_free: "",
      ticket100_bc: "",
      ticket500_bc: "",
      ticket1000_bc: "",
      ticket100000_bc: "",
      ticket_free_coop: "",
      ticket_premium_pass: "",
      amount: ""
    },
  });
  const labels = [
    "NAME",
    "IMAGE",
    "VALUE",
  ];
  const { handleSubmit, watch, setValue, getValues, formState: { errors, isSubmitting }, } = form;
  const {
    isOpen: isOpenCardEvent,
    onOpen: onOpenCardEvent,
    onClose: onCloseCardEvent,
  } = useDisclosure();

  const textColor = useColorModeValue("#000000", "white");
  const [params, setParams] = useState({
    page: FIRST_PAGE,
    limit: LIMIT_PAGE,
  });
  const [cardData, setCardData] = useState([]);
  const [filteredTypeOptions, setFilteredTypeOptions] = useState(TYPE_CURRENCY.filter((option) => option.value === "point_currency"));
  const handleClose = () => {
    onClose();
    setCardData([])
  }
  const cardItems = useMemo(() => {
    const card = getValues("card_items") ? getValues("card_items") : []
    const cd = card.map((item) => ({ ...item, amount: item?.amount ? item?.amount : 1 }))
    setCardData(cd)
    return cd;
  }, [watch("card_items")]);

  const values = watch();
  const { type, card_items, amount, ...fieldsToCheck } = values;

  const isButtonEnabled = useMemo(() => {
    if(watch("type") === "point_currency") {
      return Object.values(fieldsToCheck).some(value => value && value !== 0)
    }

    return cardData.length > 0
  }, [cardData, watch("type"), fieldsToCheck]);

  const onSubmit = (data) => {
    setValueParent("type", data.type)
    if(data.type === "point_currency") {
      const filteredData = Object.fromEntries(
        Object.entries(data).filter(([key, value]) => value > 0)
      );
      setValueParent("resource", filteredData)
    } else {
      setValueParent("details", cardData)
    }
    clearErrorsParent("resource")
    onClose()
    setCardData([])
    setCheckedIds([])
  };

  useEffect(() => {
    const resource = getValuesParent("resource");
    const card = getValuesParent("details");
    if (resource) {
      Object.entries(resource).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
    if(card) {
      setValue("card_items", card);
      setCheckedIds(card)
    }
  }, [getValuesParent("resource"), getValuesParent("details")]);

  useEffect(() => {
    if (
      getValuesParent("distribute_condition") === "EVENT_INVITATION_CARD" &&
      getValuesParent("distribute_type") === "CONDITION"
    ) {
      setFilteredTypeOptions(TYPE_CURRENCY.filter((option) => option.value === "invitation_card"));
      setValue("type", "");
    } else {
      setFilteredTypeOptions(TYPE_CURRENCY.filter((option) => option.value === "point_currency"));
    }
  }, [getValuesParent("distribute_condition"), getValuesParent("distribute_type")]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} closeOnOverlayClick={false} size="xxl">
      <ModalOverlay />
      <ModalContent maxW="900px" >
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          SELECT ITEMS
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px" height="calc(100% - 150px)" overflowY="auto">
          <Flex flexDirection="column">
            <Card px="0px">
              <CardBody>
                <FormProvider {...form}>
                  <form id="select-item-form" onSubmit={handleSubmit(onSubmit)}
                    style={{
                      maxWidth: "100%",
                      width: "100%"
                    }}
                  >
                    <Grid templateColumns="repeat(1, 1fr)" gap={4}>
                      <FormSelect
                        wrapperProps={{
                          w: "100%",
                        }}
                        name="type"
                        label="Select Type"
                        options={[{ label: "Select item type", value: "" }, ...filteredTypeOptions]}
                      />
                      { watch("type") === "point_currency" && (
                        <CurrencyItem
                          setValue={setValue}
                        />
                      )}
                      { watch("type") === "invitation_card" && (
                        <>
                          <FormInput
                            maxW="100%"
                            name="card_items"
                            value=""
                            isRequired
                            label="Select item(s)"
                            placeholder="Select item(s)"
                            onClick={() => onOpenCardEvent()}
                          />
                          <Text fontWeight="medium">Selected items </Text>
                          <TableCustom
                            labels={labels}
                            dataRow={cardItems}
                            isCheck={false}
                          >
                            {cardItems?.map((item, index) => {
                              return (
                                <RowValue
                                  key={index}
                                  item={item}
                                  index={index}
                                  cardData={cardData}
                                  setCardData={setCardData}
                                />
                              );
                            })}
                          </TableCustom>
                        </>
                      )}
                    </Grid>
                  </form>
                </FormProvider>
              </CardBody>
            </Card>
          </Flex>
        </ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            m="auto"
            w="160px"
            h="46px"
            form="select-item-form"
            borderRadius="5px"
            disabled={!isButtonEnabled}
            type="submit"
          >
            ADD
          </ButtonType>
        </ModalFooter>
      </ModalContent>
      {isOpenCardEvent && (
        <CardItem
          isOpen={isOpenCardEvent}
          onClose={onCloseCardEvent}
          setValue={setValue}
          checkedIds={checkedIds}
          setCheckedIds={setCheckedIds}
          watch={watch}
          event={event}
        />
      )}
    </Modal>
  );
}
