import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import NFTsCardHeader from "views/NFTsManagement/components/panel/NFTsCardHeader";
import StandardEvent from "./StandardEvent";
import SkillRankEvent from "./SkillRankEvent";
import { useState } from "react";
import ManageInvitationCard from "./ManageInvitationCard";
import ButtonType from "components/Button";

const TAB_NAMES = ["STANDARD EVENTS", "SKILL RANK EVENTS"];

export default function EventManagement() {
  const tabBg = useColorModeValue("gray.50", "gray.800");
  const activeTabBg = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const activeTabColor = useColorModeValue("blue.600", "blue.300");

  const [showModal, setShowModal] = useState({
    invitation: false,
  })
  
  const toggle = (type) => {
    setShowModal((prev) => ({
      ...prev,
      [type]: !showModal[type],
    }))
  }

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      {showModal?.invitation ? (
        <ManageInvitationCard 
          toggle={toggle}
        />
      ) : (
        <Card px="24px">
          <Flex alignItems="center" justifyContent="space-between">
            <NFTsCardHeader title="Event Management" />
            <ButtonType
              text="INV. CARD"
              fontSize="16px"
              w="140px"
              onClick={() => toggle("invitation")}
            />
          </Flex>
          <Tabs
            isLazy
            variant="enclosed"
          >
            <TabList borderBottom={`1px solid ${borderColor}`}>
              {TAB_NAMES.map((name) => (
                <Tab
                  key={name}
                  fontWeight="semibold"
                  _selected={{
                    color: activeTabColor,
                    bg: activeTabBg,
                    borderBottom: "3px solid",
                    borderBottomColor: activeTabColor,
                    marginBottom: "-1px",
                  }}
                  _hover={{
                    color: activeTabColor,
                  }}
                  bg={tabBg}
                  borderTopRadius="md"
                  mr={1}
                  py={3}
                  px={5}
                >
                  {name}
                </Tab>
              ))}
            </TabList>
            <Box mt={4}>
              <TabPanels>
                <TabPanel padding="24px 0">
                  <StandardEvent />
                </TabPanel>
                <TabPanel padding="24px 0">
                  <SkillRankEvent />
                </TabPanel>
              </TabPanels>
            </Box>
          </Tabs>
        </Card>
      )}
    </Flex>
  );
}
