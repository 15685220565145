import {
  Flex,
  Icon,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { createRef, useEffect, useRef, useState } from "react";
import CardHeader from "components/Card/CardHeader";
import { FormProvider, useForm } from "react-hook-form";
import ButtonType from "components/Button";
import { getSystemMailTemplate, testSendMail, updateSystemMailTemplate } from "api/system";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import MceEditor from "components/mail-editor";
import { MdOutlineAdd } from "react-icons/md";
import CreateEmailTemplate from "./CreateEmailTemplate";

export default function EmailTemplate(params) {
  const { title } = params
  const [isEdit, setIsEdit] = useState(false);
  const [activeTemp, setActiveTemp] = useState(0)
  const [dataTemplate, setDataTemplate] = useState([]);
  const toast = useToast();
  const editorRefs = useRef([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    editorRefs.current = dataTemplate?.length > 0 && dataTemplate?.map((_, i) => editorRefs.current[i+1] ?? createRef())
  }, [dataTemplate])

  const form = useForm({
    defaultValues: {}
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isSubmitting },
  } = form;

  const onSubmit = async (data, e) => {
    try {
      const param = {
        "name": dataTemplate[activeTemp]?.name,
        "subject": data?.title,
        "content": editorRefs.current[activeTemp].getContent(),
        "language": dataTemplate[activeTemp]?.language,
      }
      const res = await updateSystemMailTemplate(dataTemplate[activeTemp]?.id, param);
      if (res?.data?.success) {
        toast({
          title: "Contact updated.",
          description: "Contact updated.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setIsEdit(false)
        getSystemMaintenanceMail()
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const getSystemMaintenanceMail = async () => {
    try {
      const { data } = await getSystemMailTemplate();
      if (data?.success) {
        const listTemplates = data?.data?.map((item, index) => ({
          ...item,
          value: index,
          label: item?.name,
        }))
        reset({
          title: listTemplates[0]?.subject,
          content: listTemplates[0]?.content,
          template: 0,
        });
        setDataTemplate(listTemplates)
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } 
  };

  useEffect(() => {
    getSystemMaintenanceMail()
  }, [])

  
  const testSendEmail = async (mail) => {
    try {
      const params = {
        "to": [mail],
        "id": dataTemplate[activeTemp]?.id
      }
      const res = await testSendMail(params);
      if (res?.data?.success) {
        toast({
          title: "Contact updated.",
          description: "Contact updated.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setIsEdit(false)
        getSystemMaintenanceMail()
      } else {
        toast({
          description: res?.data?.message || res?.data?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="email-template" style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          <Flex alignItems="flex-start" flexDirection='column' gap="16px" w="100%" mt="10px">
            {dataTemplate?.length > 0 && dataTemplate?.map((temp, index) => (
              <Flex key={index} display={index === activeTemp ? "flex" : "none"} flexDirection='column' gap='12px' w="100%">
                <FormSelect
                  name="template"
                  label="Email Template"
                  options={dataTemplate}
                  onChange={(e) => {
                    reset({
                      title: dataTemplate[e?.target?.value]?.subject,
                      content: dataTemplate[e?.target?.value]?.content,
                      template: e?.target?.value,
                    });
                    setActiveTemp(Number(e?.target?.value))
                    setValue('mail', '')
                  }}
                />
                <FormInput
                  wrapperProps={{
                    w: "100%",
                  }}
                  maxW="100%"
                  type="text"
                  name="title"
                  label='Title'
                />
                <MceEditor
                  mailContent={watch('content')}
                  height={400}
                  ref={editorRefs}
                  index={index}
                />
              </Flex>
            ))}
            <Flex alignItems="flex-end" gap="12px">
              <FormInput
                wrapperProps={{
                  w: "100%",
                }}
                maxW="100%"
                name="mail"
                label='Test email'
                placeholder='Enter an email'
              />
              <ButtonType
                text="SEND"
                w="100px"
                fontSize="12px"
                onClick={(e) => {
                  e.preventDefault()
                  testSendEmail(watch("mail"))
                }}
                disabled={!watch("mail")}
              />
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Flex flexDirection="column" w="100%">
      <Card px="22px" maxW="80%">
        <CardHeader mb="20px" w="100%" display="flex" alignItems="center" justifyContent="space-between">
          <Flex alignItems="center" gap="12px">
            <Text
              fontSize="xl"
              fontWeight="bold"
              whiteSpace="nowrap"
            >
              {title}
            </Text>
            <Icon
              fontSize="30px"
              borderRadius="8px"
              border="2px solid #ccc"
              cursor="pointer"
              p="7px"
              bg="#fff"
              as={MdOutlineAdd}
              onClick={onOpen}
            />
          </Flex>
          {!isEdit ? (
            <ButtonType
              text="EDIT"
              w="100px"
              fontSize="12px"
              onClick={(e) => {
                e.preventDefault()
                setIsEdit(!isEdit)
              }}
            />
          ) : (
            <ButtonType
              text="SAVE"
              w="100px"
              fontSize="12px"
              type="submit"
              form="email-template"
              isLoading={isSubmitting}      
            />
          )}
        </CardHeader>
        <CardBody 
          overflowX="auto"
          opacity={isEdit ? 1 : 0.5}
          pointerEvents={isEdit ? 'auto' : 'none'}
          overflow="visible"
        >
          {renderForm()}
        </CardBody>
      </Card>
      {isOpen && (
        <CreateEmailTemplate
          isOpen={isOpen}
          onClose={onClose}
          getSystemMaintenanceMail={getSystemMaintenanceMail}
          // handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
        />
      )}
    </Flex>
  );
}
