import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import ButtonType from "components/Button";
import FormInput from "components/form/FormInput";
import FormUpload from "components/form/FormUpload";
import { uploadImageToServer } from "utils/uploadImage";
import { NUMBER } from "constants/enum";
import schemaInvitationCard from "./schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { createInvitation, updateInvitation } from "api/invitation.api";
import { ERROR_INVITATION_CARD } from "../constant";
import styled from "styled-components";

const TextError = styled.div`
  padding: 0 24px;
  color: #E53E3E;
  font-size: 16px;
`

const TRANS_LABEL = {
	en: {
		name: "Card Name",
		enterName: "Enter new card name",
    description: "Description",
    enterDescription: "Enter description",
		image: "Image",
	},
	jp: {
		name: "カード名",
		enterName: "新しいカード名を入力してください",
    description: "説明",
    enterDescription: "説明を入力",
		image: "画像",
	},
};


export default function CreateAndUpdateGroup({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const toast = useToast();
  const [tabIndex, setTabIndex] = useState(0);
  const [trans, setTrans] = useState("en");
  const [nameImage, setNameImage] = useState("");
  const textColor = useColorModeValue("#000000", "white");
  const form = useForm({
    resolver: yupResolver(schemaInvitationCard()),
  });

  const transLabel = (label) => {
    return TRANS_LABEL[trans][label];
  };

  const {
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = form;

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    if (index === 1) {
      setTrans("jp");
    } else {
      setTrans("en");
    }
  };
  
  const onSubmit = async (data, e) => {
    e.preventDefault();
    const dataSubmit = {
      url_image: data?.url_image,
      name: data?.name,
      translations: [
        {
          lang_key: "EN",
          name: data?.name,
          description: data?.description,
        },
        {
          lang_key: "JP",
          name: data?.name_jp,
          description: data?.description_jp,
        },
      ]
    };
    try {
      if (idEdit) {
        const res = await updateInvitation(dataDetail?.id, dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Invitation updated successfully!.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
        } else {
          toast({
            description: ERROR_INVITATION_CARD[res?.data?.message] || ERROR_INVITATION_CARD[res?.data?.messages[0]],
            status: "error",
            position: "bottom-right",
          });
        }
      } else {
        const res = await createInvitation(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Invitation created successfully!.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
        } else {
          toast({
            description: ERROR_INVITATION_CARD[res?.data?.message] || ERROR_INVITATION_CARD[res?.data?.messages[0]],
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      handleCreateOrUpdateSuccess()
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleImageChange = async (event) => {
    const image = event[0];
    await uploadImageToServer(image, toast, setValue, "url_image", setNameImage);
  };

    const handleDragImage = async (file) => {
    if (file.length > NUMBER.ONE) {
      toast({
        description: "The number of uploaded images is greater than one.",
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    const image = file && file[0];
    await uploadImageToServer(image, toast, setValue, "url_image", setNameImage);
  };

  const deleteImage = () => {
    setValue('url_image', null)
  }

  useEffect(() => {
    if (dataDetail?.id) {
      const itemJP = dataDetail?.translations?.find(
        (item) => item?.lang_key === "JP"
      );
      const itemEN = dataDetail?.translations?.find(
        (item) => item?.lang_key === "EN"
      );
      reset({
        name: dataDetail?.card_name,
        name_jp: itemJP?.name,
        description: itemEN?.description,
        description_jp: itemJP?.description,
        url_image: dataDetail?.url_image,
      });
    } else {
      reset({});
    }
  }, [reset, dataDetail]);

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            mb={4}
            index={tabIndex}
            variant="unstyled"
            onChange={handleTabsChange}
          >
            <TabList gap={2}>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                English
              </Tab>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                Japanese
              </Tab>
            </TabList>
          </Tabs>
          <Flex direction="column" w="100%" gap={4}>
            <Grid emplateColumns="repeat(1, 1fr)" gap={4}>
              <Flex direction="column" gap={4}>
                {trans === "en" ? (
                  <Box display="flex" flexDirection="column" gap="8px">
                    <FormInput
                      maxW="100%"
                      name="name"
                      label={transLabel("name")}
                      placeholder={transLabel("enterName")}
                      isRequired
                    />
                    <FormInput
                      maxW="100%"
                      name="description"
                      label={transLabel("description")}
                      placeholder={transLabel("enterDescription")}
                    />
                  </Box>
                ) : (
                  <Flex flexDirection="column" gap="8px">
                    <FormInput
                      maxW="100%"
                      name="name_jp"
                      label={transLabel("name")}
                      placeholder={transLabel("enterName")}
                      isRequired
                    />
                    <FormInput
                      maxW="100%"
                      name="description_jp"
                      label={transLabel("description")}
                      placeholder={transLabel("enterDescription")}
                    />
                  </Flex>
                )}
                <FormUpload
                  label="Select image file to upload"
                  title={transLabel("image")}
                  name="url_image"
                  type='IMAGE'
                  accept={"image/*"}
                  textButton={nameImage}
                  handleOnChange={(e) =>
                    handleImageChange(e)
                  }
                  handleDragImage={(val) =>
                    handleDragImage(val)
                  }
                  deleteImage={() => deleteImage()}
                  isRequired
                />
              </Flex>
            </Grid>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "EDIT INVITATION CARD" : "NEW INVITATION CARD"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        {errors?.name_jp?.message && trans === "en" && <TextError>Invitation card name Japan is {errors?.name_jp?.message}</TextError>}
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
            // onClick={() => {
            //   handleSubmit(onSubmit);
            // }}
          >
            {idEdit ? "SAVE" : "ADD"}
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
