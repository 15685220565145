import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import { getHourlySummary } from "api/dashboad.api";
import { useCallback, useEffect, useState } from "react";
import ReactECharts from 'echarts-for-react'
import { useCronJob } from "hooks/useCronJob";
import { formatTimestamp, convertTime } from "utils/time";

export default function ActiveUsers() {
  const toast = useToast();
  const [dataActiveUsers, setDataActiveUsers] = useState()

  const option = {
    xAxis: {
      type: 'category',
      data: dataActiveUsers?.xaxis,
      axisLabel: {
        formatter: function (value, idx) {
          return formatTimestamp(value)
        },
        interval: 2,
      },
      axisTick: { 
        show: false 
      }
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        name: 'Series 1',
        data: dataActiveUsers?.data,
        type: 'line'
      }
    ],
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        return (`
          <div
            style="background-color: #2e343d; padding: 8px; border-radius: 8px; box-shadow: 2px 4px 4px #7c6e6e; display: flex; flex-direction: column;"
          >
            <span style="color: #fff;">${convertTime(params[0]?.name * 1000)}</span>
            <div style="display: flex; align-items: center; gap: 4px;">
              <div style="width: 16px; height: 16px; border-radius: 50%; background-color: #2b5d86;"></div>
              <span style="color: #fff;">Active users: ${params[0]?.data}</span>
            </div>
          </div>
        `)
      },
      axisPointer: {
        type: 'line',
        lineStyle: {
          type: 'dashed',
          color: 'transparent',
        },
        shadowStyle: {
          color: 'transparent',
        },
      },
    }
  };

  const getActiveUsers = useCallback(
    async (newParams = {}, isReStartJon = true) => {
      try {
        if (isReStartJon) {
          stopJob();
        }
        const { data } = await getHourlySummary({
          ...newParams,
        });
        if (data?.success) {
          setDataActiveUsers(data?.data);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        if (isReStartJon) {
          startJob();
        }
      }
    },
    []
  );

  useEffect(() => {
    getActiveUsers()
    return () => {
      stopJob();
    };
  }, [getActiveUsers])

  const { startJob, stopJob } = useCronJob({
    timeCron: 60000,
    task: () => {
      getActiveUsers({}, false);
    },
  });

  return (
    <Flex 
      borderRadius='4px'
      border='1px solid #e9e9e9'
      direction='column'
      background='#fff'
      w='100%'
    >
      <Flex alignItems='center' justifyContent='space-between' padding='8px 20px' gap="2px" borderBottom='1px solid #e9e9e9'>
        <Flex direction='column' gap="2px">
          <Text fontSize="lg" fontWeight='600'>Active Users</Text>
          <Text fontSize='14px' fontWeight='600' color='#ccc'>Updated 1 minutes ago</Text>
        </Flex>
        <Flex alignItems='center' gap={2}>
          <Box width='12px' height='12px' backgroundColor='#346d9b'></Box>
          <Text fontSize="lg" >Users</Text>
        </Flex>
      </Flex>
      <ReactECharts option={option}/>
    </Flex>
  );
}
