import {
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import FormLabelComponent from "components/form/FormLabel";
import SwitchType from "components/Switch";

export default function ModalDetail({
  isOpen,
  onClose,
  dataDetail,
}) {
  const textColor = useColorModeValue("#000000", "white");

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xxl">
      <ModalOverlay />
      <ModalContent maxW="600px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {dataDetail?.name}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">
          <Flex flexDirection="column" gap="12px">
            <Flex alignItems="center" gap="12px" w="100%" padding="8px 0">
              <FormLabelComponent
                m="0px"
                title="Status"
              />
              <SwitchType
                color="#e04995"
                colorThumb="#fff"
                size="lg"
                colorScheme="teal"
                position="relative"
                isChecked={dataDetail?.status === "ACTIVE"}
              />
            </Flex>
            <Image w="100%" lazyLoad={true} src={dataDetail?.image_url} alt="NFT" />
            <Flex flexDirection="column" gap="8px">
              <FormLabelComponent
                m="0px"
                title="Prize Item(s)"
              />
              <Flex alignItems="center" gap="4px" flexWrap="wrap">
                {dataDetail?.prize_items?.map((gacha, index) => (
                  <Text key={index} p="6px 8px" borderRadius="16px" color="#fff" bg="#8bd5ff">
                    {gacha.name}
                  </Text>
                ))}
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
  