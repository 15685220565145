import { containSpecialUserNameRegex } from "utils/regex";
import * as yup from "yup";
import { DESTINATION_KEY } from "../constants";

const schemaBanner = () =>
  yup.object().shape({
    start_date: yup
      .date()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .required("Required"),
    end_date: yup
      .date()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .test(
        "is-greater-start_date",
        "End date must be after start date",
        function (value) {
          const { start_date } = this.parent;
          return start_date && value && new Date(value) > new Date(start_date);
        }
      )
      .required("Required"),
    placement: yup
      .string()
      .trim()
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    image: yup.string().trim().required("Required"),
    destination: yup
      .string()
      .trim()
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    trigger: yup
      .string()
      .trim()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .nullable()
      .max(255, "Maximum limit of 255 characters."),
    point: yup
      .string()
      .trim()
      .max(255, "Maximum limit of 255 characters.")
      .when("destination", {
        is: (destination) => destination === DESTINATION_KEY.SHOP,
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema,
      }),
    gacha_id: yup
      .string()
      .trim()
      .max(255, "Maximum limit of 255 characters.")
      .when("destination", {
        is: (destination) => destination === DESTINATION_KEY.GACHA,
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema,
      }),
    leader_board: yup
      .string()
      .trim()
      .max(255, "Maximum limit of 255 characters.")
      .when("destination", {
        is: (destination) => destination === DESTINATION_KEY.RANKING,
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema,
      }),
    link: yup
      .string()
      .trim()
      .max(255, "Maximum limit of 255 characters.")
      .when("destination", {
        is: (destination) =>
          destination === DESTINATION_KEY.URL ||
          destination === DESTINATION_KEY.MARKET_PLACE,
        then: (schema) =>
          schema
            .required("Required")
            .matches(
              /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/,
              "Invalid URL format."
            ),
        otherwise: (schema) => schema,
      }),
    modal_title: yup
      .string()
      .nullable()
      .trim()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .matches(
        containSpecialUserNameRegex,
        "Name should not contain special characters"
      )
      .max(255, "Maximum limit of 255 characters.")
      .when("destination", {
        is: (destination) => destination === DESTINATION_KEY.MODAL,
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema,
      }),
    modal_background: yup
      .string()
      .nullable()
      .trim()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .when("destination", {
        is: (destination) => destination === DESTINATION_KEY.MODAL,
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema,
      }),
    prizes: yup
      .string()
      .nullable()
      .trim()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .when("destination", {
        is: (destination) => destination === DESTINATION_KEY.PVE,
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema,
      }),
    event_id: yup
      .string()
      .nullable()
      .trim()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .when("destination", {
        is: (destination) => destination === DESTINATION_KEY.EVENT,
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema,
      }),

    // TRIGGER
    free_ticket: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .typeError("Value must be a number")
      .integer("Value must be an integer")
      .positive("Value must be a positive number")
      .max(9999999, "Value must be at most 7 digits long"),
    invitation_card: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .typeError("Value must be a number")
      .integer("Value must be an integer")
      .positive("Value must be a positive number")
      .max(9999999, "Value must be at most 7 digits long"),
    sphere: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .typeError("Value must be a number")
      .integer("Value must be an integer")
      .min(0, "Value must be a positive number or zero")
      .max(9999999, "Value must be at most 7 digits long"),
    orb: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .typeError("Value must be a number")
      .integer("Value must be an integer")
      .min(0, "Value must be a positive number or zero")
      .max(9999999, "Value must be at most 7 digits long"),
    cp: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .typeError("Value must be a number")
      .integer("Value must be an integer")
      .min(0, "Value must be a positive number or zero")
      .max(9999999, "Value must be at most 7 digits long"),
  });

export default schemaBanner;
