import { convertParams, omitParams } from 'utils/object';
import END_POINT from './constants'
import http from './http'

export function getListInvitation(p) {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.INVITATION}?${params}`)
}

export function getListInvitationIncoming(p) {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.INVITATION}/incoming-event-cards?${params}`)
}

export function getListInvitationUnused(id) {
  return http.get(`${END_POINT.INVITATION}/unused-cards?include_card_id=${id}`)
}

export const createInvitation = (data, option = {}) => {
  return http.post(END_POINT.INVITATION, data, option);
};

export const updateInvitation = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.INVITATION}/${idEdit}`, data, option);
};

export const deleteInvitations = (data) => {
  return http.delete(`${END_POINT.INVITATION}`, data);
};
