import {
  Box,
  Flex,
  Icon,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import ImageDefault from "assets/img/empty-image.png";
import CheckBoxType from "components/Checkbox";
import { ViewIcon } from "components/Icons/ViewIcon";
import { TYPE_PRIZE } from "constants/constants";
import { useMemo } from "react";
import { convertShortDateLocal } from "utils/time";

const SelectPrizeRow = ({
  checkedIds,
  setCheckedIds,
  handleSetDataDetail,
  listPrize,
}) => {
  const textColor = useColorModeValue("#000000", "white");

  const isMachineChecked = useMemo(() => {
    return checkedIds.some((item) => item?.id === listPrize?.id);
  }, [listPrize?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isMachineChecked) {
      setCheckedIds(
        checkedIds.filter((checkedId) => checkedId?.id !== listPrize?.id)
      );
    } else {
      setCheckedIds([...checkedIds, listPrize]);
    }
  };

  return (
    <Tr key={listPrize?.id}>
      <Td width="10px">
        <CheckBoxType
          isChecked={isMachineChecked}
          onChange={() => handleCheckboxChange()}
          colorScheme="teal"
        />
      </Td>
      <Td minW="180px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.name || "---"}
        </Text>
      </Td>
      <Td p="2" minW="60px" padding={"0 28px"}>
        <Box
          bgImage={listPrize?.image_url ? listPrize?.image_url : ImageDefault}
          w="50px"
          h="50px"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
        />
      </Td>
      <Td minW="150px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.prize_type ? TYPE_PRIZE[listPrize?.prize_type] : "---"}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.play_fee?.name || "---"}
        </Text>
      </Td>
      <Td minW="150px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.assist_gauge_limit || "---"}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.sale_period ? "Yes" : "No" || "None"}
        </Text>
      </Td>
      <Td minW="200px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.start_sale_period
            ? convertShortDateLocal(listPrize?.start_sale_period * 1000)
            : "n/a"}
        </Text>
      </Td>
      <Td minW="200px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.end_sale_period
            ? convertShortDateLocal(listPrize?.end_sale_period * 1000)
            : "n/a"}
        </Text>
      </Td>
      <Td>
        <Flex gap={6} align="center">
          <Icon
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={ViewIcon}
            onClick={() => handleSetDataDetail(listPrize?.id)}
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default SelectPrizeRow;
