import { containSpecialUserNameRegex, containSpecialUserNameJapanRegex } from "utils/regex";
import * as yup from "yup";

const schemaCreateGacha = () =>
  yup.object().shape({
    gacha_name: yup
      .string()
      .trim()
      .matches(containSpecialUserNameRegex, "Name should not contain special characters")
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    gacha_name_jp: yup
      .string()
      .trim()
      .required("Required")
      .matches(containSpecialUserNameJapanRegex, "Name should not contain special characters")
      .max(255, "Maximum limit of 255 characters."),
    description: yup
      .string()
      .trim()
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    description_jp: yup
      .string()
      .trim()
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    banner_image_url: yup
      .string()
      .trim()
      .required("Required"),
    slide_image_url: yup
      .string()
      .trim()
      .required("Required"),
    ceiling_value: yup
      .number()
      .min(0)
      .nullable(true)
      .max(99999999, "Ceiling value must be at most 8 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("Must be an integer")
      .positive("Ceiling value must be greater than 0"),
    free_orb_cost1: yup
      .number()
      .min(0)
      .max(99999999, "Cost must be at most 8 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .required("Required"),
    free_orb_limit_per_date1: yup
      .number()
      .min(1)
      .nullable(true)
      .max(99999999, "Play limit per day must be at most 8 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("Must be an integer")
      .positive("Play limit per day must be greater than 1"),
    free_orb_cost10: yup
      .number()
      .min(0)
      .max(99999999, "Cost must be at most 8 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .required("Required"),
    free_orb_limit_per_date10: yup
      .number()
      .min(1)
      .nullable(true)
      .max(99999999, "Play limit per day must be at most 8 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("Must be an integer")
      .positive("Play limit per day must be greater than 1"),
    paid_orb_cost1: yup
      .number()
      .min(0)
      .max(99999999, "Cost must be at most 8 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .required("Required"),
    paid_orb_limit_per_date1: yup
      .number()
      .min(1)
      .nullable(true)
      .max(99999999, "Play limit per day must be at most 8 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("Must be an integer")
      .positive("Play limit per day must be greater than 1"),
    paid_orb_cost10: yup
      .number()
      .min(0)
      .max(99999999, "Cost must be at most 8 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .required("Required"),
    paid_orb_limit_per_date10: yup
      .number()
      .min(1)
      .nullable(true)
      .max(99999999, "Play limit per day must be at most 8 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("Must be an integer")
      .positive("Play limit per day must be greater than 1"),
    rewards: yup
      .array()
      .min(1, 'Required'),
    start_time: yup
      .date()
      .required(),
    end_time: yup
      .date()
      .test(
        "is-greater-start_time",
        "Event end time must be after event start time",
        function (value) {
          const { start_time } = this.parent;
          return (
            start_time &&
            value &&
            new Date(value) > new Date(start_time)
          );
        }
      )
      .required(),
  });

export default schemaCreateGacha;
