import {
  Box,
  Button,
  Flex,
  FormErrorMessage,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createNewEventStandard, editEventStandard } from "api/event.api";
import FormInput from "components/form/FormInput";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import schemaMachine from "./schema";
import ButtonType from "components/Button";
import FormTextAria from "components/form/FormTextAria";
import FormUpload from "components/form/FormUpload";
import { NUMBER } from "constants/enum";
import { uploadImageToServer } from "utils/uploadImage";
import FormLabelComponent from "components/form/FormLabel";
import FormDatePicker from "components/form/FormDatePicker";
import { convertToIsoString, convertToUTCTime, dateAfterNow } from "views/Event/help";
import moment from "moment";
import { MODE_TYPE } from "views/Machine/MachineSettings/constants";
import FormCheckbox from "components/form/FormCheckbox";
import { ERROR_CREATE_EVENT } from "../../constant";
import styled from "styled-components";

const TextError = styled.div`
  padding: 0 24px;
  color: #E53E3E;
  font-size: 16px;
`

const TRANS_LABEL = {
	en: {
		name: "Event name",
		enterName: "Enter new event name",
		image: "Image",
		description: "Description",
		enterDesc: "Enter description",
    modeType: "Mode type",
		winPoint: "Win point",
		enterWinPoint: "Enter win point value",
    preStart: "Pre-registration Start",
    eventStart: "Event Start",
    eventEnd: "Event End",
    resultEnd: "Result End",
	},
	jp: {
		name: "名前",
		enterName: "名前を入力",
		image: "画像",
		description: "説明",
		enterDesc: "説明を入力してください",
    modeType: "モードタイプ",
		winPoint: "勝利ポイント",
		enterWinPoint: "勝ち点の値を入力してください",
    preStart: "事前登録開始",
    eventStart: "イベント開始",
    eventEnd: "イベント終了",
    resultEnd: "結果終了",
	},
};
const dateFormatMoment = "DD MMM. YYYY HH:mm:ss";

export default function CreateAndUpdateStandard({
  isOpen,
  onClose,
  detailEvent,
  handleCreateOrUpdateSuccess,
}) {
  const formatResult = "YYYY-MM-DD HH:mm:ss";
  const dateFormat = "dd MMM. YYYY HH:mm:ss";
  const [tabIndex, setTabIndex] = useState(0);
  const [trans, setTrans] = useState("en");
  const [nameImage, setNameImage] = useState("");
  const [listModeType, setListModeType] = useState(() => {
    return MODE_TYPE?.reduce((a, v) => ({ ...a, [v.value]: true }), {}) 
  });
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const form = useForm({
    resolver: yupResolver(schemaMachine())
  });
  const {
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = form;

  const disableWhenJP = useMemo(() => {
    return trans === "jp";
  }, [trans]);

  const idEdit = useMemo(() => {
    return detailEvent?.id;
  }, [detailEvent]);

  useEffect(() => {
    if (detailEvent) {
      reset({
        ...detailEvent,
      });
    } else {
      reset();
    }
  }, [detailEvent]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    if (index === 1) {
      setTrans("jp");
    } else {
      setTrans("en");
    }
  };

  const transLabel = (label) => {
    return TRANS_LABEL[trans][label];
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const listModes = MODE_TYPE?.filter((mode) => listModeType[mode.value])?.map(item => item?.value)
    const dataSubmit = {
      name: data?.event_name,
      event_start_time: convertToIsoString(data?.event_start_time),
      event_end_time: convertToIsoString(data?.event_end_time),
      image_url: data?.icon_url,
      mode_types: listModes,
      event_type: "STANDARD_EVENT",
      status: "OPEN",
      translations: [
        {
          lang_key: "EN",
          name: data?.event_name,
          description: data?.description,
        },
        {
          lang_key: "JP",
          name: data?.event_name_jp,
          description: data?.description_jp,
        }
      ],
      win_point: data?.win_point
    }
    try {
      if (idEdit) {
        const res = await editEventStandard(detailEvent?.id, dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Event Standard Edited Successfully.",
            description: "Event Standard Edited Successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose()
        } else {
          toast({
            description: ERROR_CREATE_EVENT[res?.data?.message] || ERROR_CREATE_EVENT[res?.data?.messages[0]] || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      } else {
        const res = await createNewEventStandard(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Event Standard created.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose()
        } else {
          toast({
            description: ERROR_CREATE_EVENT[res?.data?.message] || ERROR_CREATE_EVENT[res?.data?.messages[0]] || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleImageChange = async (event, type) => {
    const image = event[0];
    await uploadImageToServer(image, toast, setValue, "icon_url", setNameImage);
  };

   const handleDragImage = async (file, type) => {
    if (file.length > NUMBER.ONE) {
      toast({
        description: "The number of uploaded images is greater than one.",
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    const image = file && file[0];
    await uploadImageToServer(image, toast, setValue, "icon_url", setNameImage);
  };

  const deleteImage = (type) => {
    setValue('icon_url', null)
  }

  useEffect(() => {
    if (detailEvent?.id) {
      const itemJP = detailEvent?.translations?.find(
        (item) => item?.lang_key === "JP"
      );
      reset({
        event_start_time: detailEvent?.event_start_time
          ? convertToUTCTime(detailEvent?.event_start_time)
          : dateAfterNow(0),
        event_end_time: detailEvent?.event_end_time
          ? convertToUTCTime(detailEvent?.event_end_time)
          : dateAfterNow(3),
        event_name: detailEvent?.name,
        event_name_jp: itemJP?.name,
        description: detailEvent?.description,
        description_jp: itemJP?.description,
        win_point: detailEvent?.win_point,
        icon_url: detailEvent?.image_url,
      });
    } else {
      reset({
        event_start_time: dateAfterNow(0),
        event_end_time: dateAfterNow(3),
      });
    }
  }, [reset, detailEvent]);

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            mb={4}
            index={tabIndex}
            variant="unstyled"
            onChange={handleTabsChange}
          >
            <TabList gap={2}>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                English
              </Tab>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                Japanese
              </Tab>
            </TabList>
          </Tabs>
          <Flex direction="column" w="100%" gap={4}>
            <Grid templateColumns="repeat(1, 1fr)" gap={4}>
              <Flex direction="column" gap={4}>
                {trans === "en" ? (
                  <Flex flexDirection="column" gap="8px">
                    <FormInput
                      maxW="100%"
                      name="event_name"
                      label={transLabel("name")}
                      placeholder={transLabel("enterName")}
                      isRequired
                    />
                    <FormUpload
                      label="Select image file to upload"
                      title="Image"
                      name="icon_url"
                      type='IMAGE'
                      accept={"image/*"}
                      textButton={nameImage}
                      isRequired
                      handleOnChange={(e) =>
                        handleImageChange(e, "en")
                      }
                      handleDragImage={(val) =>
                        handleDragImage(val, "en")
                      }
                      deleteImage={() => deleteImage("en")}
                    />
                    <FormTextAria
                      label={transLabel("description")}
                      placeholder={transLabel("enterDesc")}
                      name="description"
                      defaultValue=''
                      h="100px"
                    />
                    {/* <Flex alignItems="center" gap="12px" w="100%" padding="8px 0" position="relative">
                      <FormLabelComponent
                        m="0px"
                        title={transLabel("whitelist")}
                      />
                      <SwitchType
                        color="#e04995"
                        colorThumb="#fff"
                        size="lg"
                        position="absolute"
                        left="80px"
                        colorScheme="teal"
                        isChecked={watch('whitelist')}
                        onChange={(e) => {
                          setValue("whitelist", e.target?.checked)
                        }}
                      >
                        <Box
                          as="span"
                          position="absolute"
                          left="25%"
                          top="50%"
                          transform="translate(-50%, -50%)"
                          color="white"
                          fontWeight="bold"
                          fontSize="12px"
                          pointerEvents="none" // Prevents pointer events from interfering with the switch
                        >
                          On
                        </Box>
                        {!watch('whitelist') && (
                          <Box
                            as="span"
                            position="absolute"
                            left="64%"
                            top="50%"
                            transform="translate(-50%, -50%)"
                            fontWeight="bold"
                            color="#4a4a4a"
                            fontSize="12px"
                            pointerEvents="none" // Prevents pointer events from interfering with the switch
                          >
                            Off
                          </Box>
                        )}
                      </SwitchType>
                    </Flex> */}
                    <Flex direction="column" gap="12px" w="100%" padding="8px 0">
                      <FormLabelComponent
                        m="0px"
                        title={transLabel("modeType")}
                      />
                      <Grid templateColumns='repeat(2, 1fr)' gap='16px'>
                        {MODE_TYPE?.map((mode, index) => (
                          <FormCheckbox
                            key={index}
                            name={`${mode?.value}`}
                            label={mode.label}
                            isChecked={listModeType[mode.value]}
                            onChange={(e) => {
                              setListModeType(prev => ({
                                ...prev,
                                [mode.value]: e.target.checked 
                              }))
                            }}
                            wrapperProps={{
                              display: "flex",
                              alignItems: "center",
                              margin: "0"
                            }}
                            disabled
                          />)
                        )}
                      </Grid>
                    </Flex>
                    <FormInput
                      maxW="100%"
                      name="win_point"
                      min={0}
                      type="number"
                      label={transLabel("winPoint")}
                      placeholder={transLabel("enterWinPoint")}
                      isRequired
                      onWheel={event => event.currentTarget.blur()} 
                    />
                    <Flex alignItems="flex-start" gap="16px" w="100%">
                      <Flex direction="column" gap="8px" w="100%">
                        <FormLabelComponent
                          m="0px"
                          title={transLabel("eventStart")}
                          isRequired
                        />
                        <FormDatePicker
                          disabled={disableWhenJP || detailEvent?.new_status === "IN_EVENT"}
                          name="event_start_time"
                          showTimeSelect
                          dateFormat={dateFormat}
                          h="40px"
                          onChange={(e) => {
                            const time = moment(e).format(formatResult);
                            setValue("event_start_time", time);
                          }}
                        />
                      </Flex>
                      <Flex direction="column" gap="8px" w="100%">
                        <FormLabelComponent
                          m="0px"
                          title={transLabel("eventEnd")}
                          isRequired
                        />
                        <FormDatePicker
                          disabled={disableWhenJP}
                          name="event_end_time"
                          showTimeSelect
                          dateFormat={dateFormat}
                          h="40px"
                          onChange={(e) => {
                            const time = moment(e).format(formatResult);
                            setValue("event_end_time", time);
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                ) : (
                  <Box display="flex" flexDirection="column" gap="8px">
                    <FormInput
                      maxW="100%"
                      name="event_name_jp"
                      label={transLabel("name")}
                      placeholder={transLabel("enterName")}
                      isRequired
                    />
                    <FormUpload
                      label="Select image file to upload"
                      title={transLabel("image")}
                      name="icon_url"
                      type='IMAGE'
                      accept={"image/*"}
                      textButton={nameImage}
                      handleOnChange={(e) =>
                        handleImageChange(e, "en")
                      }
                      handleDragImage={(val) =>
                        handleDragImage(val, "en")
                      }
                      deleteImage={() => deleteImage("en")}
                    />
                    <FormTextAria
                      label={transLabel("description")}
                      placeholder={transLabel("enterDesc")}
                      name="description_jp"
                      h="100px"
                    />
                    {/* <Flex alignItems="center" gap="12px" w="100%" padding="8px 0">
                      <FormLabelComponent
                        m="0px"
                        title={transLabel("whitelist")}
                      />
                      <SwitchType
                        color="#e04995"
                        colorThumb="#fff"
                        size="lg"
                        colorScheme="teal"
                        position="relative"
                        isChecked={watch('whitelist')}
                        onChange={(e) => setValue("whitelist", e.target?.checked)}
                        disabled
                      />
                    </Flex> */}
                    <Flex direction="column" gap="12px" w="100%" padding="8px 0">
                      <FormLabelComponent
                        m="0px"
                        title={transLabel("modeType")}
                      />
                      <Grid templateColumns='repeat(2, 1fr)' gap='16px'>
                        {MODE_TYPE?.map((mode, index) => (
                          <FormCheckbox
                            key={index}
                            name={`${mode?.value}`}
                            label={mode.label}
                            isChecked={listModeType[mode.value]}
                            disabled
                            wrapperProps={{
                              display: "flex",
                              alignItems: "center",
                              margin: "0"
                            }}
                          />)
                        )}
                      </Grid>
                    </Flex>
                    <FormInput
                      maxW="100%"
                      name="win_point"
                      label={transLabel("winPoint")}
                      placeholder={transLabel("enterWinPoint")}
                    />
                    <Flex alignItems="flex-start" gap="16px" w="100%">
                      <Flex direction="column" gap="8px" w="100%">
                        <FormLabelComponent
                          m="0px"
                          title={transLabel("eventStart")}
                          isRequired
                        />
                        <FormDatePicker
                          disabled={disableWhenJP}
                          name="event_start_time"
                          showTimeSelect
                          dateFormat={dateFormat}
                          h="40px"
                          onChange={(e) => {
                            const time = moment(e).format(formatResult);
                            setValue("event_start_time", time);
                          }}
                        />
                      </Flex>
                      <Flex direction="column" gap="8px" w="100%">
                        <FormLabelComponent
                          m="0px"
                          title={transLabel("eventEnd")}
                          isRequired
                        />
                        <FormDatePicker
                          disabled={disableWhenJP}
                          name="event_end_time"
                          showTimeSelect
                          dateFormat={dateFormat}
                          h="40px"
                          onChange={(e) => {
                            const time = moment(e).format(formatResult);
                            setValue("event_end_time", time);
                            setValue("result_start_time", time);
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Box>
                )}
              </Flex>
            </Grid>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="700px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "EDIT STANDARD EVENT" : "NEW STANDARD EVENT"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        {errors?.event_name_jp?.message && trans === "en" && <TextError>Event name Japan is {errors?.event_name_jp?.message}</TextError>}
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
