import { FormProvider, useForm } from "react-hook-form";
import {
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import ButtonType from "components/Button";
import FormInput from "components/form/FormInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { createInvitation} from "api/invitation.api";
import { loginValidate } from "views/Authentication/SignIn/schema";
import { ERROR_SYSTEM } from "../constants";

export default function ValidateShowPassword({
  isOpen,
  onClose,
  type,
  getData,
  setIsShowAll,
}) {
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const form = useForm({
    resolver: yupResolver(loginValidate()),
  });

  const {
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting },
  } = form;

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const dataSubmit = {
      get_sensitive_info: true,
      email: data?.email,
      password: data?.password,
    };
    try {
      const res = await getData(dataSubmit);
      if (res?.success) {
        toast({
          title: "Entry successfully!.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        onClose()
        setIsShowAll(prev => ({ ...prev, [type]: !prev[type] }))
      } else {
        toast({
          description: ERROR_SYSTEM[res?.message] || ERROR_SYSTEM[res?.messages[0]],
          status: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="form-show" onSubmit={handleSubmit(onSubmit)}>
          <Flex direction="column" w="100%" gap={4}>
            <Grid templateColumns="repeat(1, 1fr)" gap={4}>
              <Flex direction="column" gap={4}>
                <Flex flexDirection="column" gap="8px">
                  <FormInput
                    maxW="100%"
                    name="email"
                    label='Email'
                    placeholder='Email'
                    isRequired
                  />
                </Flex>
                <Flex flexDirection="column" gap="8px">
                  <FormInput
                    maxW="100%"
                    name="password"
                    type="password"
                    label='Password'
                    placeholder='Password'
                    isRequired
                  />
                </Flex>
              </Flex>
            </Grid>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          Password Entry
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">
          <Text mb="8px" fontWeight="600">This function is only available for accounts with administrator authority. Please enter the ID and password of the account with administrator authority.</Text>
          {renderForm()}
        </ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="form-show"
            isLoading={isSubmitting}
          >
            ENTER
          </ButtonType>
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
