import React from "react";
import { Flex, VStack, Text, useColorModeValue, Grid, Box, HStack, Image } from "@chakra-ui/react";
import ModalBase from "components/Modal/ModalBase";
import ButtonType from "components/Button";
import { trimCharacter } from "utils/string";

export default function DetailViewBg({ item, isOpen, onClose, bgFeature }) {
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <ModalBase
      isOpen={isOpen}
      onClose={onClose}
      showBtn={false}
      titleHeader={item?.name}
    >
      <VStack spacing={6} align="stretch">
        <VStack spacing={4} align="stretch">
        <Grid templateColumns="1fr 1fr" gap={4}>
          <Box
            position="relative"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="contain"
            borderRadius="md"
            overflow="hidden"
          >
            <Image
              src={item?.image_url}
              alt={item?.nft_information?.name}
              objectFit="contain"
              w="60%"
              h="100%"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
            />
          </Box>
          <VStack align="start">
            <Text
              fontWeight="bold"
              color={"blue.500"}
            >
              {item?.name}
            </Text>
            <Text fontWeight="bold" color={textColor}>
              Related DF:{" "}
              <Text
                as="span"
                color={"blue.500"}
              >
                {item?.digital_figure?.name || "--"}
              </Text>
            </Text>
            <Text fontWeight="bold" color={textColor}>
              Partner:{" "}
              <Text
                as="span"
                color={"blue.500"}
              >
                {item?.digital_figure?.partner?.name || "--"}
              </Text>
            </Text>
            <Text fontWeight="bold" color={textColor}>
              Count:{" "}
              <Text
                as="span"
                color={"red.500"}
              >
                {item?.count}
              </Text>
            </Text>
            <Text fontWeight="bold" color={textColor}>
              Available in:{" "}
              <Text
                as="span"
                color={"red.500"}
              >
                {item?.digital_figure?.country_targets?.length || 0} {" "}
              </Text>
              <Text
                as="span"
                color={"black"}
              >
                countries
              </Text>
            </Text>
            <Text fontWeight="bold" color={textColor}>
              Allocation:{" "}
              <Text
                as="span"
                color={"green.500"}
              >
                {item?.allocation}
              </Text>
            </Text>
          </VStack>
        </Grid>
      </VStack>
        <Flex justifyContent="center">
          <ButtonType
            w="28%"
            mt={4}
            type="button"
            text="OK"
            colorScheme="blue"
            onClick={onClose}
          />
        </Flex>
      </VStack>
    </ModalBase>
  );
}
