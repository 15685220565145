import { Image, Stack, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import { STATUS } from "constants/constants";
import { STATE_SHOW } from "views/Machine/MachineSettings/constants";

const TYPE_OPTIONS = {
  ALL: "All",
  TWO_CLAWS: "2 Claws",
  THREE_CLAWS: "3 Claws",
};

const GameModeRow = ({
  item,
}) => {
  const textColor = useColorModeValue("#000000", "white");

  return (
    <Tr>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {item?.mac}
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {item?.group_label}-{item?.group_number}
        </Text>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Image
            src={item?.icon_url}
            w="120px"
            alt={item?.gameplay_type?.name}
          />
        </Stack>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {STATUS[item?.state]}
        </Text>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {TYPE_OPTIONS[item?.machine_type]}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.gameplay_type?.name}
        </Text>
      </Td>
      <Td>
        <Text 
          fontSize="sm" 
          fontWeight={400}
          padding="4px 8px"
          borderRadius="8px"
          color="#fff"
          maxWidth='min-content'
          background={`${STATE_SHOW[item?.status] === "Connected" ? "#12b012ba" : "#ff4646"}`}
        >
          {STATE_SHOW[item?.status]}
        </Text>
      </Td>
    </Tr>
  );
};

export default GameModeRow;
