import { Flex, Box, Text, Image, useColorModeValue, Icon } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { EditIcon } from "components/Icons/EditIcon";
import { ViewIcon } from "components/Icons/ViewIcon";
import CheckBoxType from "components/Checkbox";
import { convertShortDateLocal } from "utils/time";

export default function ItemNotice({
  handleOpenCreateOrUpdate,
  handleSetDataDetail,
  notice,
  checkedIds,
  setCheckedIds,
  categories,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const [backgroundCategory, setBackgroundCategory] = useState('#ffffff');

  useEffect(() => {
    const itemCategory = categories.find(
      (category) => category.id === notice.categories[0]
    );
    if (itemCategory) {
      switch (itemCategory.type) {
        case 'EVENT':
          setBackgroundCategory('#ffc107');
          break;
        case 'ANNOUNCEMENT':
        case 'UPDATE':
          setBackgroundCategory('#03a9f4');
          break;
        case 'MAINTENANCE':
          setBackgroundCategory('#ff5722');
          break;
        default:
          setBackgroundCategory('#ffffff');
      }
    } else {
      setBackgroundCategory('#ffffff');
    }
  }, [categories, notice]);

  const handleCheckboxChange = (isChecked) => {
    if (isChecked) {
      setCheckedIds([...checkedIds, notice.id]);
    } else {
      setCheckedIds((checkedIds) => checkedIds.filter(checkedId => checkedId !== notice.id));
    }
  };

  const nameCategory = () => {
    const itemCategory = categories.find(
      (category) => category.id === notice.categories[0]
    );
    return itemCategory ? itemCategory.name : 'Unknown';
  };

  return (
    <Box
      p={4}
      mb={4}
      w="100%"
      alignItems="center"
      borderRadius="md"
      boxShadow={`0 0 10px #ccc`}
      transition="all 0.3s"
    >
      <Flex justifyContent="flex-start" alignItems="center" gap="5px">
        <CheckBoxType
          colorScheme="teal"
          borderColor="#b3b3b3"
          isChecked={checkedIds.includes(notice.id)}
          onChange={(e) => {
            const isChecked = e.target.checked;
            handleCheckboxChange(isChecked);
          }}
          disabled={notice.is_published}
        ></CheckBoxType>
        <Text mr="2" fontSize="md" fontWeight="medium" color="#0b57d0">
          {convertShortDateLocal(notice.publish_at * 1000)}
        </Text>
        <Text fontSize="sm" color={textColor} background={backgroundCategory} borderRadius="12px" px="2" py="1">
          {nameCategory()}
        </Text>
      </Flex>
      <Flex justifyContent="space-between" my="2">
        <Image src={notice.banner_url_en} borderRadius="12px" max-width="600px" height="100px" mr={4} alt="banner" />
        <Flex justifyContent="flex-end">
          <Icon
            onClick={() => handleSetDataDetail()}
            mr="2"
            fontSize="40px"
            borderRadius="8px"
            cursor="pointer"
            as={ViewIcon}
          />
          <Icon
            onClick={() => handleOpenCreateOrUpdate()}
            fontSize="40px"
            borderRadius="8px"
            p="7px"
            cursor={notice.is_published ? "not-allowed" : "pointer"}
            bg="#1480FF"
            as={EditIcon}
          />
        </Flex>
      </Flex>
      <Flex>
        <Text fontSize="md" fontWeight="medium" color="#0b57d0" mr="2" >Header: </Text>
        <Text>{notice.header_en}</Text>
      </Flex>
    </Box>
  );
}
