import {
  Flex,
  Grid,
  Text,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { useMemo, useState } from "react";
import CardHeader from "components/Card/CardHeader";
import { FormProvider, useForm } from "react-hook-form";
import FormLabelComponent from "components/form/FormLabel";
import ButtonType from "components/Button";
import { updateSystemMaintenance } from "api/system";
import FormCheckbox from "components/form/FormCheckbox";
import { SITE_LABEL } from "../constants";

export default function LoginAccess(params) {
  const { title, dataMaintenance, itemLogin, getListMaintenance } = params
  
  const [isEdit, setIsEdit] = useState(false);
  const toast = useToast();

  const form = useForm({
    defaultValues: {}
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isSubmitting },
  } = form;

  const listLoginGameSite = useMemo(() => {
    if (itemLogin?.game_site) {
      return Object.entries(itemLogin?.game_site).map(([key, value]) => {
        setValue(`game_site_${key}`, value)
        return { 
          label: `Log in with ${SITE_LABEL[key]}`,
          value: value,
          name: `game_site_${key}`,
        };
      });
    }
  }, [itemLogin?.game_site])

  const listLoginMkpSite = useMemo(() => {
    if (itemLogin?.marketplace_site) {
      return Object.entries(itemLogin?.marketplace_site).map(([key, value]) => {
        setValue(`mkp_site_${key}`, value)
        return { 
          label: `Log in with ${SITE_LABEL[key]}`,
          value: value,
          name: `mkp_site_${key}`,
          [`mkp_site_${key}`]: key,
        };
      });
    }
  }, [itemLogin?.marketplace_site])

  const onSubmit = async (data, e) => {
    const gameSite = Object.entries(data).reduce((acc, [key, value]) => {
      let type = key.split("game_site_")
      if (key.includes("game_site")) {
        acc[`${type[1]}`] = value;
      }
      return acc;
    }, {});

    const mkpSite = Object.entries(data).reduce((acc, [key, value]) => {
      let type = key.split("mkp_site_")
      if (key.includes("mkp_site")) {
        acc[`${type[1]}`] = value;
      }
      return acc;
    }, {});
    try {
      const dataSubmit = {
        site_maintenance: dataMaintenance?.site_maintenance,
        login_access: {
          game_site: gameSite,
          marketplace_site: mkpSite,
        }
      }
      const res = await updateSystemMaintenance(dataSubmit);
      if (res?.data?.success) {
        toast({
          title: "Contact updated.",
          description: "Contact updated.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setIsEdit(false)
        getListMaintenance()
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="login-access" style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          <Flex alignItems="flex-start" gap="16px" w="100%" mt="10px">
            <Flex direction="column" gap="8px" w="100%">
              <FormLabelComponent
                m="0px"
                title='Game Site'
                isRequired
                textAlign="center"
              />
              <Grid templateColumns='repeat(1, 1fr)' gap='16px'>
                {listLoginGameSite?.map((mode, index) => (
                  <FormCheckbox
                    key={index}
                    name={`${mode?.name}`}
                    position='right'
                    label={mode.label}
                    wrapperProps={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0"
                    }}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setValue(mode?.name, isChecked)
                    }}
                  />)
                )}
              </Grid>
            </Flex>
            <Flex direction="column" gap="8px" w="100%">
              <FormLabelComponent
                m="0px"
                title='Marketplace'
                isRequired
                textAlign="center"
              />
              <Grid templateColumns='repeat(1, 1fr)' gap='16px'>
                {listLoginMkpSite?.map((mode, index) => (
                  <FormCheckbox
                    key={index}
                    name={`${mode?.name}`}
                    label={mode.label}
                    position='right'
                    wrapperProps={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0"
                    }}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setValue(mode?.name, isChecked)
                    }}
                  />)
                )}
              </Grid>
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Flex flexDirection="column" w="100%">
      <Card px="22px" maxW="80%">
        <CardHeader mb="20px" w="100%" display="flex" alignItems="center" justifyContent="space-between">
          <Text
            fontSize="xl"
            fontWeight="bold"
            whiteSpace="nowrap"
          >
            {title}
          </Text>
          {!isEdit ? (
            <ButtonType
              text="EDIT"
              w="100px"
              fontSize="12px"
              onClick={(e) => {
                e.preventDefault()
                setIsEdit(!isEdit)
              }}
            />
          ) : (
            <ButtonType
              text="SAVE"
              w="100px"
              fontSize="12px"
              type="submit"
              form="login-access"
              isLoading={isSubmitting}      
            />
          )}
        </CardHeader>
        <CardBody 
          overflowX="auto"
          opacity={isEdit ? 1 : 0.5}
          pointerEvents={isEdit ? 'auto' : 'none'}
          overflow="visible"
        >
          {renderForm()}
        </CardBody>
      </Card>
    </Flex>
  );
}
