import {
  Box,
  Image,
  Stack,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import SwitchType from "components/Switch";
import { trimCharacter } from "utils/string";

const GameFunctionsRow = ({
  item,
  handleSwitchFunctions
}) => {
  const textColor = useColorModeValue("#000000", "white");

  return (
    <Tr>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.feature_name}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Image
            src={item?.image}
            w="60px"
            alt={item?.image}
          />
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {trimCharacter(
              item?.url,
              6,
              4
            )}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <SwitchType
            isChecked={item?.permission_web === 'ON'}
            onChange={() => {
              const params = {
                permission_web: item?.permission_web === 'ON' ? false : true,
                permission_android: item?.permission_android === 'ON' ? true : false,
                permission_ios: item?.permission_ios === 'ON' ? true : false,
              }
              handleSwitchFunctions(item.id, params)
            }}
          />
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <SwitchType
            isChecked={item?.permission_android === 'ON'}
            onChange={() => {
              const params = {
                permission_web: item?.permission_web === 'ON' ? true : false,
                permission_android: item?.permission_android === 'ON' ? false : true,
                permission_ios: item?.permission_ios === 'ON' ? true : false,
              }
              handleSwitchFunctions(item.id, params)
            }}
          />
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <SwitchType
            isChecked={item?.permission_ios === 'ON'}
            onChange={() => {
              const params = {
                permission_web: item?.permission_web === 'ON' ? true : false,
                permission_android: item?.permission_android === 'ON' ? true : false,
                permission_ios: item?.permission_ios === 'ON' ? false : true,
              }
              handleSwitchFunctions(item.id, params)
            }}
          />
        </Stack>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.device_id}
        </Text>
      </Td>
    </Tr>
  );
};

export default GameFunctionsRow;
