export const DESTINATION_KEY = {
  SHOP: "SHOP",
  GACHA: "GACHA",
  RANKING: "RANKING",
  PVP: "PVP",
  COOP: "COOP",
  PRESENT_BOX: "PRESENT_BOX",
  PVE: "PVE",
  EVENT: "EVENT",
  URL: "URL",
  MODAL: "MODAL",
  MARKET_PLACE: "MARKET_PLACE",
  SPECTATE: "SPECTATE",
};

export const TRIGGER_KEY = {
  GRANT_SPECIFIC_POINT: "GRANT_SPECIFIC_POINT",
  GRANT_SPECIFIC_ITEM: "GRANT_SPECIFIC_ITEM",
};

export const DESTINATION_OPTIONS = [
  { label: "Shop", value: DESTINATION_KEY.SHOP },
  { label: "Gacha", value: DESTINATION_KEY.GACHA },
  { label: "Ranking", value: DESTINATION_KEY.RANKING },
  { label: "PVP", value: DESTINATION_KEY.PVP },
  { label: "Coop", value: DESTINATION_KEY.COOP },
  { label: "Present Box", value: DESTINATION_KEY.PRESENT_BOX },
  { label: "PVE", value: DESTINATION_KEY.PVE },
  { label: "Event", value: DESTINATION_KEY.EVENT },
  { label: "Url", value: DESTINATION_KEY.URL },
  { label: "Modal", value: DESTINATION_KEY.MODAL },
  { label: "Market Place", value: DESTINATION_KEY.MARKET_PLACE },
  // { label: "Spectate", value: DESTINATION_KEY.SPECTATE },
];

export const TRIGGER_OPTIONS = [
  { label: "Grant a specific item", value: TRIGGER_KEY.GRANT_SPECIFIC_ITEM },
  { label: "Grant a specific point", value: TRIGGER_KEY.GRANT_SPECIFIC_POINT },
];

export const SPECIFIC_ITEM_SHOP = [
  { label: "Bcoin", value: "BCOIN" },
  { label: "Sphere", value: "SPHERE" },
  { label: "Orb", value: "ORB" },
];
export const SPECIFIC_ITEM_GACHA = [
  { label: "Ongoing", value: "ONGOING" },
  { label: "Gacha", value: "GACHA" },
];

export const LEADER_BOARD_OPTIONS = [
  { label: "Skill Rank", value: "SKILL_RANK" },
  { label: "Events", value: "EVENTS" },
  { label: "Play", value: "PLAY" },
  { label: "Treasurebox", value: "TREASUREBOX" },
  { label: "Level", value: "LEVEL" },
];

export const SPECIFIC_ITEM_OPTIONS = {
  [DESTINATION_KEY.SHOP]: SPECIFIC_ITEM_SHOP,
  [DESTINATION_KEY.GACHA]: SPECIFIC_ITEM_GACHA,
  [DESTINATION_KEY.RANKING]: LEADER_BOARD_OPTIONS,
};

export const PLACEMENT_OPTIONS = [
  { label: "Above", value: "ABOVE" },
  { label: "Under", value: "UNDER" },
];

export const TRANS_LABEL = {
  en: {
    title_add: "New Banner Ads",
    title_edit: "Edit Banner Ads",
    start_date: "Start Date",
    end_date: "End Date",
    placement: "Placement",
    select_placement: "Select a placement",
    image: "Image",
    select_image: "Select image file to upload",
    tap_actions: "Tap Actions",
    destination: "Destination",
    select_target: "Select a target",
    trigger: "Trigger  (for 1st time)",
    select_trigger: "Select a trigger",
    specific_item: "Specific Item",
    select_specific_currency: "Select a currency",
    select_specific_url: "Enter a URL link",
    select_specific_model: "Enter a modal title",
    select_specific_ranking: "Select a leaderboard",
    select_specific_prize: "Select prize(s)",
    modal_title: "Modal Title",
    modal_background: "Modal Background",
    url: "URL",
    free_ticket: "Free Ticket",
    free_ticket_placeholder: "Enter a number of free ticket",
    invitation_card: "Invitation Card ",
    invitation_card_placeholder: "Enter a number of Invitation card",
    sphere: "Sphere",
    sphere_placeholder: "Enter a number of sphere",
    orb: "Orb",
    orb_placeholder: "Enter a number of Orb",
    cp: "CP",
    cp_placeholder: "Enter a number of CP",
    event_placeholder: "Select an event",

    cancel: "Cancel",
    add: "Add",
    edit: "Save",
  },
  ja: {
    title_add: "新規バナー広告",
    title_edit: "バナー広告を編集",
    start_date: "開始日",
    end_date: "終了日",
    placement: "配置場所",
    select_placement: "配置場所を選択してください",
    image: "画像",
    select_image: "アップロードする画像ファイルを選択",
    tap_actions: "タップアクション",
    destination: "リンク先",
    select_target: "リンク先を選択",
    trigger: "トリガー（初回用）",
    select_trigger: "トリガーを選択してください",
    specific_item: "特定のアイテム",
    select_specific_currency: "通貨を選択",
    select_specific_url: "URLリンクを入力",
    select_specific_model: "モーダルタイトルを入力",
    select_specific_ranking: "リーダーボードを選択",
    select_specific_prize: "賞品を選択",
    modal_title: "モーダルタイトル",
    modal_background: "モーダル背景",
    url: "URL",
    free_ticket: "無料チケット",
    free_ticket_placeholder: "無料チケットの数を入力",
    invitation_card: "招待カード",
    invitation_card_placeholder: "招待カードの数を入力",
    sphere: "スフィア",
    sphere_placeholder: "スフィアの数を入力",
    orb: "オーブ",
    orb_placeholder: "オーブの数を入力",
    cp: "CP",
    cp_placeholder: "CPの数を入力",
    event_placeholder: "イベントを選択",

    cancel: "キャンセル",
    add: "追加",
    edit: "保存",
  },
};
