import { Flex, Icon, Td, Tr, Text } from "@chakra-ui/react";
import { ViewIcon } from "components/Icons/ViewIcon";
import { STATUS_USER_PRESENTBOX } from "constants/constants";
import { convertShortDateLocal } from "utils/time";

export default function PresentBoxHistoryRow ({
    userPresentBox,
  handleSetDataDetail
}) {

  const showBgItems = (status) => {
    switch(status) {
      case "NOT_OPENED":
        return '#ffc107';
      case "OPENED":
        return '#4caf50';
      case "EXPIRED":
        return '#f44336';
      default:
        return '#ffffff';
    }
  };

  return (
    <>
      <Tr>
        <Td>
          {userPresentBox?.mgmt_name}
        </Td>
        <Td minWidth="150px">
          {userPresentBox?.name_en}
        </Td>
        <Td minWidth="130px">
          <Text p="2px 10px" fontSize="12px" background={showBgItems(userPresentBox?.status)} borderRadius="12px">
            {STATUS_USER_PRESENTBOX[userPresentBox?.status]}
          </Text>
        </Td>
        <Td>
          {userPresentBox?.user_name_display}
        </Td>
        <Td>
          {convertShortDateLocal(userPresentBox?.distribution_start_date * 1000)}
        </Td>
        <Td width="12.5%">
          <Flex gap={6} align="center">
            <Icon
              fontSize="30px"
              borderRadius="8px"
              cursor="pointer"
              as={ViewIcon}
              onClick={() => handleSetDataDetail()}
            />
          </Flex>
        </Td>
      </Tr>
    </>
  );
}
