import { Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { deleteBanner } from "api/ads.api";
import ButtonType from "components/Button";
import DeleteModal from "components/Modal/deleteModal";
import useWithToast from "hooks/useWithToast";
import { useTranslation } from "react-i18next";

export default function ActionAds({
handleOpenCreateOrUpdate,
checkedIds,
setCheckedIds,
setParams,
params,
}) {
const toast = useToast();
const { isOpen, onOpen, onClose } = useDisclosure();
const { showToastSuccess, showToastFail } = useWithToast();
  const { t } = useTranslation("error");

const handleDelete = async () => {
  try {
    if (checkedIds?.length) {
      const newParams = {
        ids: checkedIds,
      };
      const res = await deleteBanner(newParams);
      if (res?.data?.success) {
        showToastSuccess({
          title: "Delete Success",
        });
        setCheckedIds([])
        setParams({
          ...params,
          page: 1,
        });
      } else {
        showToastFail({
          title: t(`${res?.data?.messages[0]}`) ,
        });
      }
      onClose();
    }
  } catch (error) {
    toast({
      description: error?.message || error?.messages[0],
      status: "error",
      position: "bottom-right",
    });
  }
};

return (
  <Flex gap={4} flexWrap="wrap" alignItems="flex-end">
    <Flex alignItems="flex-end" gap={6} mb="10px">
      <ButtonType
        text="NEW"
        fontSize="12px"
        width="100px"
        onClick={() => {
          handleOpenCreateOrUpdate();
        }}
      />
      <ButtonType
        text="DELETE"
        width="100px"
        fontSize="12px"
        btnType="danger"
        disabled={checkedIds?.length === 0}
        onClick={onOpen}
      />
    </Flex>
    <DeleteModal
      isOpen={isOpen}
      onClose={onClose}
      handleDelete={handleDelete}
      title="Delete Banner"
      content="Do you want to delete these banners?"
      btnYes="OK"
      btnNo="Cancel"
    />
  </Flex>
);
}
