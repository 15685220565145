import { Flex, Text } from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import { MACHINE_TYPE_OPTIONS, OPTIONS_RARITY } from "constants/constants";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { LIST_CHAIN_TESTNET } from "views/Users/UserManagement/constant";

export default function ConditionNonNft({
  isLoading,
  setParams,
  params,
  listType,
}) {
  const form = useForm();

  const { setValue, handleSubmit, reset } = form;

  const onSubmit = (data) => {
    if (!isLoading) {
      const newParams = {
        ...params,
        ...data,
        page: 1,
      };
      setParams(newParams);
    }
  };

  const handleReset = useCallback(() => {
    reset({ 
      keyword: "", 
      chain_id: "",
      type: "",
      rarity: "",
    });
    setParams((prev) => ({
      ...prev,
      keyword: "", 
      chain_id: "",
      type: "",
      rarity: "",
      page: 1,
    }));
  }, [reset]);

  return (
    <Flex
      gap={4}
      flexWrap="wrap"
      justifyContent="flex-start"
      w="100%"
      alignItems="flex-end"
    >
      <FormProvider {...form}>
        <form
          style={{
            width: "100%",
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            gap: "8px",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Flex w="100%" alignItems="flex-end" gap={4}>
            <FormInput
              w="350px"
              maxW="100%"
              name="keyword"
              label="Search"
              placeholder="Enter keywords"
            />
            <FormSelect
              name="chain_id"
              label="Filter by Chain"
              defaultValue={""}
              maxW='100%'
              wrapperProps={{
                w: "100%",
              }}
              options={[{ label: "All", value: "" }, ...LIST_CHAIN_TESTNET]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("chain_id", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
            <FormSelect
              name="type"
              label="Filter by Type"
              defaultValue={""}
              maxW='100%'
              wrapperProps={{
                w: "100%",
              }}
              options={[{ label: "All", value: "" }, ...listType]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("type", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
            <FormSelect
              maxW='100%'
              wrapperProps={{
                w: "100%",
              }}
              name="rarity"
              label="Filter by Rarity"
              defaultValue={""}
              options={[{ label: "All", value: "" }, ...OPTIONS_RARITY, { label: "Off Chain", value: "OFF_CHAIN" }]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("rarity", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
          </Flex>
          <Flex justifyContent="flex-end" mt={6}>
            <Text onClick={handleReset} fontWeight="500" cursor="pointer" color="#56caff">Reset</Text>
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
}
