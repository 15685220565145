import React from "react";
import { Flex, VStack, Text, useColorModeValue, Grid, Box, HStack, Image } from "@chakra-ui/react";
import ModalBase from "components/Modal/ModalBase";
import ButtonType from "components/Button";
import { trimCharacter } from "utils/string";
import CopyComponent from "components/Copy";

export default function FigureDetailModal({ item, isOpen, onClose, bgFeature }) {
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <ModalBase
      isOpen={isOpen}
      onClose={onClose}
      showBtn={false}
      titleHeader={item?.name}
    >
      <VStack spacing={6} align="stretch">
        <VStack spacing={4} align="stretch">
        <Grid templateColumns="1fr 1fr">
          <Box
            position="relative"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="contain"
            borderRadius="md"
            overflow="hidden"
             mr="12px"
          >
            <Image
              src={item?.image_url}
              alt={item?.name}
              objectFit="contain"
              w="100%"
              h="100%"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
            />
          </Box>
          <VStack align="start" ml="12px">
            <Text
              fontWeight="bold"
              color={"blue.500"}
            >
              {item?.name}
            </Text>
            <Flex gap="4px" flexWrap="wrap">
              {item?.is_ar && (
                <Text color={textColor} fontSize="sm" fontWeight={400} borderRadius="20px" p="4px 8px" bg={bgFeature("AR")} >
                  AR
                </Text>
              )}
              {item?.is_3d && (
                <Text color={textColor} fontSize="sm" fontWeight={400} borderRadius="20px" p="4px 8px" bg={bgFeature("3D")}>
                  3D
                </Text>
              )}
              {item?.is_room && (
                <Text color={textColor} fontSize="sm" fontWeight={400} borderRadius="20px" p="4px 8px" bg={bgFeature("ROOM")}>
                  ROOM
                </Text>
              )}
              {item?.is_holo_model && (
                <Text color={textColor} fontSize="sm" fontWeight={400} borderRadius="20px" p="4px 8px" bg={bgFeature("HoloModel")}>
                  HoloModel
                </Text>
              )}
            </Flex>
            <Text fontWeight="bold" color={textColor}>
              Partner:{" "}
              <Text
                as="span"
                color={"blue.500"}
              >
                {item?.partner?.name || "Admin"}
              </Text>
            </Text>
            <Text fontWeight="bold" color={textColor}>
              Count:{" "}
              <Text
                as="span"
                color={"red.500"}
              >
                {item?.count}
              </Text>
            </Text>
            <Flex gap={4} fontWeight="bold" color={textColor}>
              Storage Link:{" "}
              <Text
                as="span"
                color={"blue.500"}
              >
                {trimCharacter(item?.storage_link, 6, 4)}
              </Text>
              <CopyComponent
                dataCopy={item?.storage_link}
              />
            </Flex>
            <Text fontWeight="bold" color={textColor}>
              Available in:{" "}
              <Text
                as="span"
                color={"red.500"}
              >
                {item?.country_targets?.length} {" "}
              </Text>
              <Text
                as="span"
                color={"black"}
              >
                countries
              </Text>
            </Text>
            <Text fontWeight="bold" color={textColor}>
              Allocation:{" "}
              <Text
                as="span"
                color={"green.500"}
              >
                {item?.allocation}
              </Text>
            </Text>
          </VStack>
        </Grid>
      </VStack>
        <Flex justifyContent="center">
          <ButtonType
            w="28%"
            mt={4}
            type="button"
            text="OK"
            colorScheme="blue"
            onClick={onClose}
          />
        </Flex>
      </VStack>
    </ModalBase>
  );
}
