import {
  Flex,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useCallback, useEffect, useState } from "react";
import ConditionEventManagement from "./condition";
import DetailModePvp from "./detail";
import ButtonType from "components/Button";
import CreateAndUpdateSinglePlay from "./CreateAndUpdateSingleplay";
import ModalListPrize from "./ModalListPrize";
import ModalListMachines from "./ModalListMachines";
import ModalDelete from "./ModalDelete";
import { changeStatusGameMode, getGameMode } from "api/gameMode.api";
import { getPlayFeeApi } from "stores/playFee/action";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { playFeeSelector } from "stores/playFee/selector";
import { getGamePlayTypeApi } from "stores/gamePlay/action";
import { gamePlaySelector } from "stores/gamePlay/selector";
import BannerSetup from "./BannerSetup";
import { STATUS } from "constants/constants";

export default function BattleArena({ type, prizeTypes }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [battleArenas, setBattleArenas] = useState([]);
  const [listPlayFee, setListPlayFee] = useState([]);
  const [listGamePlayType, setListGamePlayType] = useState([]);
  const { listPlayFees } = useSelector(playFeeSelector);
  const { listGamePlay } = useSelector(gamePlaySelector);
  const [params, setParams] = useState({
    page: 1,
    limit: 999,
    mode_type: type,
  });
  const [dataDetail, setDataDetail] = useState(null);
  const toast = useToast();

  const [modal, setModal] = useState({
    addNew: false,
    delete: false,
    listPrize: false,
    listMachines: false,
    bannerSetup: false,
  })
  
  const toggle = (type, data) => {
    setModal(prev => ({ ...prev, [type]: !prev[type] }))
    setDataDetail(data)
  }

  const getModePvp = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getGameMode({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setBattleArenas(res?.records);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  useEffect(() => {
    getModePvp();
  }, [getModePvp]);

  const handleCreateOrUpdateSuccess = useCallback(() => {
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);

  const getListPlayFee = () => {
    dispatch(getPlayFeeApi({
      page: 1,
      limit: 999,
      "payment_type": "ALL",
      status: "ACTIVE",
    }));
  };

  const getListGamePlayType = () => {
    dispatch(getGamePlayTypeApi());
  };

  useEffect(() => {
    getListPlayFee();
    getListGamePlayType();
  }, []);

  useEffect(() => {
    if (listPlayFees?.records?.length > 0) {
      const listFeeFilter = listPlayFees?.records?.map((record) => ({
        ...record,
        label: record?.name,
        value: record?.id,
      }))
      setListPlayFee(listFeeFilter);
    }
  }, [listPlayFees]);

  useEffect(() => {
    if (listGamePlay?.length > 0) {
      const listGameTypeFilter = listGamePlay?.map((record) => ({
        ...record,
        label: record?.name,
        value: record?.id,
      }))
      setListGamePlayType(listGameTypeFilter);
    }
  }, [listGamePlay]);

  const handleSwitchStatus = async (id, data) => {
    try {
      setIsLoading(true);
      const params = {
        status: data === STATUS.ACTIVE ? STATUS.NO_ACTIVE : STATUS.ACTIVE
      }
      const res = await changeStatusGameMode(id, params);
      if (res?.data?.success) {
        toast({
          title: "Update success.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        getModePvp();
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Flex flexDirection="column">
      {modal?.bannerSetup ? (
        <BannerSetup 
          toggleModal={toggle}
          prizeTypes={prizeTypes}
        />
      ) : (
        <Card p="0px">
          <CardHeader mb="20px">
            <Flex alignItems="center" w="100%" justifyContent="space-between" gap={6} mb="10px">
              <ButtonType
                text="Banner Setup"
                w="140px"
                fontSize="16px"
                btnType="primary-outline"
                onClick={() => toggle("bannerSetup", null)}
              />
              <Flex alignItems="center" gap={6}>
                <ButtonType
                  text="New"
                  w="140px"
                  fontSize="16px"
                  onClick={() => toggle("addNew", null)}
                />
                {/* <ButtonType
                  text="Delete"
                  btnType="primary-new-outline"
                  fontSize="16px"
                  w="140px"
                  onClick={() => toggle("delete", null)}
                /> */}
              </Flex>
            </Flex>
          </CardHeader>
          <CardHeader mb="32px">
            <ConditionEventManagement
              isLoading={isLoading}
              setParams={setParams}
              params={params}
              listPlayFee={listPlayFee}
              listGamePlayType={listGamePlayType}
            />
          </CardHeader>
          <CardBody overflowX="auto">
            <Flex flexWrap="wrap" gap="6px">
              {battleArenas?.map((mode, index) => (
                <DetailModePvp 
                  item={mode}
                  key={index}
                  toggle={toggle}
                  handleSwitchStatus={handleSwitchStatus}
                />
              ))}
            </Flex>
          </CardBody>
        </Card>
      )}
      {modal.addNew && (
        <CreateAndUpdateSinglePlay
          isOpen={modal.addNew}
          onClose={() => toggle("addNew", null)}
          dataDetail={dataDetail}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
          listPlayFee={listPlayFee}
          listGamePlayType={listGamePlayType}
          type={type}
          prizeTypes={prizeTypes}
        />
      )}
      {modal.listPrize && (
        <ModalListPrize
          isOpen={modal.listPrize}
          onClose={() => toggle("listPrize", null)}
          dataDetail={dataDetail}
          type={type}
          prizeTypes={prizeTypes}
        />
      )}
      {modal.listMachines && (
        <ModalListMachines
          isOpen={modal.listMachines}
          onClose={() => toggle("listMachines", null)}
          dataDetail={dataDetail}
          type={type}
        />
      )}
      {modal.delete && (
        <ModalDelete
          isOpen={modal.delete}
          onClose={() => toggle("delete", null)}
          dataDetail={dataDetail}
          type={type}
        />
      )}
    </Flex>
  );
}
