import { Flex, Icon, Image, Stack, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { EditIcon } from "components/Icons/EditIcon";
import { ViewIcon } from "components/Icons/ViewIcon";
import { useMemo } from "react";
import { MdOutlineAdd } from "react-icons/md";

const DigitalManagementRow = ({ item, handleOpenUpload, index, checkedIds, setCheckedIds, toggle, bgFeature }) => {
  const textColor = useColorModeValue("#000000", "white");

  const isChecked = useMemo(() => {
    return checkedIds.some((itemCheck) => itemCheck?.id === item?.id);
  }, [item?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isChecked) {
      setCheckedIds(
        checkedIds.filter((itemCheck) => itemCheck?.id !== item?.id)
      );
    } else {
      setCheckedIds([...checkedIds, item]);
    }
  };
  
  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isChecked}
          onChange={handleCheckboxChange}
          colorScheme="teal"
          disabled={item?.allocation !== 'NONE' ? true : false}
        />
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {index}
        </Text>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Image
            src={item?.image_url}
            w="100px"
            alt={item?.image_url}
          />
        </Stack>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.name}
        </Text>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.partner?.name}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Flex gap="4px" flexWrap="wrap">
          {item?.is_ar && (
            <Text color={textColor} fontSize="sm" fontWeight={400} borderRadius="20px" p="4px 8px" bg={bgFeature("AR")} >
              AR
            </Text>
          )}
          {item?.is_3d && (
            <Text color={textColor} fontSize="sm" fontWeight={400} borderRadius="20px" p="4px 8px" bg={bgFeature("3D")}>
              3D
            </Text>
          )}
          {item?.is_room && (
            <Text color={textColor} fontSize="sm" fontWeight={400} borderRadius="20px" p="4px 8px" bg={bgFeature("ROOM")}>
              ROOM
            </Text>
          )}
          {item?.is_holo_model && (
            <Text color={textColor} fontSize="sm" fontWeight={400} borderRadius="20px" p="4px 8px" bg={bgFeature("HoloModel")}>
              HoloModel
            </Text>
          )}
        </Flex>
      </Td>
      <Td>
        <Flex>
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight={400}
            maxW="200px"
          >
            {item?.storage_link}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.count}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.allocation}
        </Text>
      </Td>
      <Td>
        <Flex gap={6} align="center" justifyContent='flex-end'>
          {item?.is_holo_model && (
            <Icon
              fontSize="30px"
              borderRadius="8px"
              cursor="pointer"
              p="7px"
              bg="#2dff6c"
              as={MdOutlineAdd}
              onClick={() => toggle('modalImportSerial', item)}
              pointerEvents={item?.allocation !== "NONE" ? "none" : "auto"}
              opacity={item?.allocation !== "NONE" ? 0.5 : 1}
            />
          )}
          <Icon
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={ViewIcon}
            onClick={() => toggle('modalDetailFigure', item)}
          />
          <Icon
            onClick={() => toggle('modalCreateAndEdit', item)}
            fontSize="30px"
            borderRadius="8px"
            p="7px"
            cursor="pointer"
            bg="#1480FF"
            as={EditIcon}
            pointerEvents={item?.allocation !== "NONE" ? "none" : "auto"}
            opacity={item?.allocation !== "NONE" ? 0.5 : 1}
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default DigitalManagementRow;
