import {
  Box,
  Flex,
  Icon,
  Image,
  Stack,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { EditIcon } from "components/Icons/EditIcon";
import { useMemo } from "react";

const InquiriesRow = ({
  item,
  handleOpenCreateOrUpdate,
  checkedIds,
  setCheckedIds,
}) => {
  const textColor = useColorModeValue("#000000", "white");

  const isMachineChecked = useMemo(() => {
    return checkedIds.some((check) => check?.id === item?.id);
  }, [item?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isMachineChecked) {
      setCheckedIds(checkedIds.filter((check) => check?.id !== item?.id));
    } else {
      setCheckedIds([...checkedIds, item]);
    }
  };

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isMachineChecked}
          onChange={() => handleCheckboxChange()}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.translation_machine_contact_categories[0]?.message}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.translation_machine_contact_categories[1]?.message}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Flex gap="10px">
          <Icon
            onClick={handleOpenCreateOrUpdate}
            fontSize="30px"
            borderRadius="8px"
            p="7px"
            cursor="pointer"
            bg="#1480FF"
            as={EditIcon}
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default InquiriesRow;
