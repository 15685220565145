import {
  Flex,
  Icon,
  Image,
  Stack,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { useMemo } from "react";
import { EditIcon } from "components/Icons/EditIcon";
import { ViewIcon } from "components/Icons/ViewIcon";
import { getChainIcon } from "views/NFTsManagement/utils/utils";
  
const RowLargeReward = ({
  item,
  checkedIds,
  setCheckedIds,
  handleOpenCreateOrUpdate,
}) => {
  const textColor = useColorModeValue("#000000", "white");
  const isitemChecked = useMemo(() => {
    return checkedIds.some((check) => check?.id === item?.id);
  }, [item?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isitemChecked) {
      setCheckedIds(checkedIds.filter((item) => item?.id !== item?.id));
    } else {
      setCheckedIds([...checkedIds, item]);
    }
  };

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isitemChecked}
          onChange={() => handleCheckboxChange()}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.id}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.name}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Image
            boxSize="30px"
            src={getChainIcon(item?.chain_id).src}
            alt={getChainIcon(item?.chain_id).alt}
          />
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Flex alignItems="center" gap="4px" flexWrap="wrap">
            {item?.gacha_items?.map((gacha, index) => (
              index < 3 && <Text key={index} p="4px 6px" borderRadius="16px" bg="#95ff87">
                {gacha.name}
              </Text>
            ))}
            {item?.gacha_items?.length > 3 && <Text>...</Text>}
          </Flex>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.weight}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Flex gap={6} align="center">
          <Icon
            fontSize="30px"
            borderRadius="8px"
            p="7px"
            cursor="pointer"
            bg="#1480FF"
            as={EditIcon}
            onClick={() => handleOpenCreateOrUpdate(item)}
          />
          {/* <Icon
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={ViewIcon}
          /> */}
        </Flex>
      </Td>
    </Tr>
  );
};

export default RowLargeReward;
