import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getSystemTicket = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.SYSTEM_TICKETS}?${params}`);
};

export const getSystemMaintenance = (p) => {
  return http.get(`${END_POINT.SYSTEM_MAINTENANCE}`);
};

export const updateSystemMaintenance = (data) => {
  return http.put(`${END_POINT.SYSTEM_MAINTENANCE}`, data);
};

export const getSystemMail = () => {
  return http.get(`${END_POINT.SYSTEM_MAIL}`);
};

export const updateSystemMail = (data) => {
  return http.put(`${END_POINT.SYSTEM_MAIL}`, data);
};

export const getSystemMailTemplate = () => {
  return http.get(`${END_POINT.SYSTEM_MAIL_TEMPLATE}`);
};

export const createSystemMailTemplate = (data) => {
  return http.post(`${END_POINT.SYSTEM_MAIL_TEMPLATE}`, data);
};

export const updateSystemMailTemplate = (id, data) => {
  return http.put(`${END_POINT.SYSTEM_MAIL_TEMPLATE}/${id}`, data);
};

export const testSendMail = (data) => {
  return http.post(`${END_POINT.SYSTEM_MAIL_TEST}`, data);
};

// GAME FUNCTION
export const getGameFunction = () => {
  return http.get(`${END_POINT.FEATURE_TOGGLE}`);
};

export const updateGameFunction = (id, data) => {
  return http.put(`${END_POINT.FEATURE_TOGGLE}/${id}`, data);
};

// Inquiries
export const getInquiries = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.INQUIRIES}?${params}`);
};

export const createInquiries = (data) => {
  return http.post(`${END_POINT.INQUIRIES}`, data);
};

export const updateInquiries = (id, data) => {
  return http.put(`${END_POINT.INQUIRIES}/${id}`, data);
};

export const deleteInquiries = (data) => {
  return http.delete(`${END_POINT.INQUIRIES}`, data);
};

// System
export const getPaymentFincode = (data) => {
  return http.post(`${END_POINT.PAYMENT_FINCODE}`, data);
};

export const updatePaymentFincode = (data) => {
  return http.put(`${END_POINT.PAYMENT_FINCODE}`, data);
};

export const getPaymentSlash = (data) => {
  return http.post(`${END_POINT.PAYMENT_SLASH}`, data);
};

export const updatePaymentSlash = (data) => {
  return http.put(`${END_POINT.PAYMENT_SLASH}`, data);
};
