import * as yup from "yup";

const schemaConsecutiveSetting = () =>
  yup.object().shape({
    free_ticket: yup
      .number()
      .min(0, "Number of Free ticket must be greater or equal to 0")
      .nullable(true)
      .max(9999999, "Maximum 7 digits allowed")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    bcoin: yup
      .number()
      .min(0, "Number of B-coins must be greater or equal to 0")
      .nullable(true)
      .max(9999999, "Maximum 7 digits allowed")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    sphere: yup
      .number()
      .min(0, "Number of Spheres must be greater or equal to 0")
      .nullable(true)
      .max(9999999, "Maximum 7 digits allowed")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    cp: yup
      .number()
      .min(0, "Number of CP must be greater or equal to 0")
      .nullable(true)
      .max(9999999, "Maximum 7 digits allowed")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    ap: yup
      .number()
      .min(0, "Number of AP must be greater or equal to 0")
      .nullable(true)
      .max(9999999, "Maximum 7 digits allowed")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    orb: yup
      .number()
      .min(0, "Number of ORB must be greater or equal to 0")
      .nullable(true)
      .max(9999999, "Maximum 7 digits allowed")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
  });

export default schemaConsecutiveSetting;
