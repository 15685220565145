import CardBody from 'components/Card/CardBody';
import TableCustom from 'components/Tables/CustomTable';
import React from 'react'
import ItemsReward from './row';

export default function TableListReward(props) {
  const { checkedIds, setCheckedIds, handleOpenDetailUserModal, setValue } = props

  const labels = [
    "ID",
    "Name",
    "Chain",
    "Small reward containers",
    "Weight",
    "Grand Prize",
    "",
  ];

  const handleChangePoint = (id, field, value) => {
    setCheckedIds((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  return (
    <CardBody overflowX="auto">
      <TableCustom
        labels={labels}
        dataRow={checkedIds}
        isCheck={false}
      >
        <>
          {checkedIds?.map((item) => {
            return (
              <ItemsReward
                key={item?.id}
                item={item}
                handleOpenDetailUserModal={handleOpenDetailUserModal}
                handleChangePoint={handleChangePoint}
                setCheckedIds={setCheckedIds}
                checkedIds={checkedIds}
                setValue={setValue}
              />
            );
          })}
        </>
      </TableCustom>
    </CardBody>
  )
}
