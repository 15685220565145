import {
  Grid,
} from "@chakra-ui/react";
import FormInput from "components/form/FormInput";

export default function CurrencyItem({
  setValue
}) {

  return (
    <Grid templateColumns="repeat(1, 1fr)" gap={4}>
      <FormInput
        label="Number of B-Coins"
        name="bcoin"
        type="number"
        placeholder="Enter a value"
        onBlur={(e) => {
          setValue("bcoin", Number(e?.target?.value) >= 0 ? e?.target?.value : 0)
        }}
      />
      <FormInput
        label="Number of Spheres"
        name="sphere"
        type="number"
        placeholder="Enter a value"
        onBlur={(e) => {
          setValue("sphere", Number(e?.target?.value) >= 0 ? e?.target?.value : 0)
        }}
      />
      <FormInput
        label="Number of Orbs"
        name="orb"
        type="number"
        placeholder="Enter a value"
        onBlur={(e) => {
          setValue("orb", Number(e?.target?.value) >= 0 ? e?.target?.value : 0)
        }}
      />
      <FormInput
        label="Number of AP"
        name="ap"
        type="number"
        placeholder="Enter a value"
        onBlur={(e) => {
          setValue("ap", Number(e?.target?.value) >= 0 ? e?.target?.value : 0)
        }}
      />
      <FormInput
        label="Number of XP"
        name="xp"
        type="number"
        placeholder="Enter a value"
        onBlur={(e) => {
          setValue("xp", Number(e?.target?.value) >= 0 ? e?.target?.value : 0)
        }}
      />
      <FormInput
        label="Number of Free Ticket"
        name="ticket_free"
        type="number"
        placeholder="Enter a value"
        onBlur={(e) => {
          setValue("ticket_free", Number(e?.target?.value) >= 0 ? e?.target?.value : 0)
        }}
      />
      <FormInput
        label="Number of 100 BC Ticket"
        name="ticket100_bc"
        type="number"
        placeholder="Enter a value"
        onBlur={(e) => {
          setValue("ticket100_bc", Number(e?.target?.value) >= 0 ? e?.target?.value : 0)
        }}
      />
      <FormInput
        label="Number of 500 BC Ticket"
        name="ticket500_bc"
        type="number"
        placeholder="Enter a value"
        onBlur={(e) => {
          setValue("ticket500_bc", Number(e?.target?.value) >= 0 ? e?.target?.value : 0)
        }}
      />
      <FormInput
        label="Number of 1000 BC Ticket"
        name="ticket1000_bc"
        type="number"
        placeholder="Enter a value"
        onBlur={(e) => {
          setValue("ticket1000_bc", Number(e?.target?.value) >= 0 ? e?.target?.value : 0)
        }}
      />
      <FormInput
        label="Number of 100k BC Ticket"
        name="ticket100000_bc"
        type="number"
        placeholder="Enter a value"
        onBlur={(e) => {
          setValue("ticket100000_bc", Number(e?.target?.value) >= 0 ? e?.target?.value : 0)
        }}
      />
      <FormInput
        label="Number of Co-op FreeTicket"
        name="ticket_free_coop"
        type="number"
        placeholder="Enter a value"
        onBlur={(e) => {
          setValue("ticket_free_coop", Number(e?.target?.value) >= 0 ? e?.target?.value : 0)
        }}
      />
      <FormInput
        label="Number of Premium Pass Ticket"
        name="ticket_premium_pass"
        type="number"
        placeholder="Enter a value"
        onBlur={(e) => {
          setValue("ticket_premium_pass", Number(e?.target?.value) >= 0 ? e?.target?.value : 0)
        }}
      />
    </Grid>
  );
}
