import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  createMachineGroup,
  updateMachineGroup,
} from "api/machine.api";
import ButtonType from "components/Button";
import FormInput from "components/form/FormInput";
import { uploadImageToServer } from "utils/uploadImage";
import FormUpload from "components/form/FormUpload";
import { NUMBER } from "constants/enum";
import ModalSelectPrize from "views/NewGameMode/Common/SelectPrize";
import schemaBannerSetup from "./shema";
import FormLabelComponent from "components/form/FormLabel";
import SwitchType from "components/Switch";
import { createCategoryPrize, updateCategoryPrize } from "api/gameMode.api";
import { yupResolver } from "@hookform/resolvers/yup";
import { STATUS } from "constants/constants";
import { ERROR_BANNER } from "views/NewGameMode/constant";

export default function CreateAndUpdateGroup({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
  prizeTypes
}) {
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const [checkedIds, setCheckedIds] = useState([]);
  
  const form = useForm({
    resolver: yupResolver(schemaBannerSetup()),
  });

  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    clearErrors,
    formState: { errors, isSubmitting },
  } = form;

  const { 
    isOpen: isOpenPrizeItems, 
    onOpen: onOpenPrizeItems, 
    onClose: onClosePrizeItems 
  } = useDisclosure();

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  const handleImageChange = async (event, name) => {
    const image = event[0];
    await uploadImageToServer(image, toast, setValue, name);
  };

    const handleDragImage = async (file, name) => {
    if (file.length > NUMBER.ONE) {
      toast({
        description: "The number of uploaded images is greater than one.",
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    const image = file && file[0];
    await uploadImageToServer(image, toast, setValue, name);
  };

  const deleteImage = (name) => {
    setValue(name, null)
  }

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const listPrizeItems = data?.prize_items?.map((item) => item?.id)
    const dataSubmit = {
      name: data?.name,
      image_url: data?.image_url,
      status: data?.status ? STATUS['ACTIVE'] : STATUS['NO_ACTIVE'],
      prize_ids: listPrizeItems,
    }
    try {
      if (idEdit) {
        const res = await updateCategoryPrize(idEdit, dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Update Category Prize Successfully!",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
        } else {
          toast({
            description: ERROR_BANNER[res?.data?.message] || ERROR_BANNER[res?.data?.messages[0]],
            status: "error",
            position: "bottom-right",
          });
        }
      } else {
        const res = await createCategoryPrize(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Create Category Prize Successfully!",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
        } else {
          toast({
            description: ERROR_BANNER[res?.data?.message] || ERROR_BANNER[res?.data?.messages[0]],
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      handleCreateOrUpdateSuccess()
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    if (dataDetail?.id) {
      const listPrizes = dataDetail?.prize_items?.map((game) => ({
        ...game,
        id: game?.id
      }))
      setCheckedIds(listPrizes)
      reset({
        name: dataDetail?.name,
        prize_items: listPrizes,
        image_url: dataDetail?.image_url,
        status: dataDetail?.status === STATUS["ACTIVE"] ? true : false,
      });
    } else {
      reset({
        prize_items: [],
        status: false,
      });
    }
  }, [reset, dataDetail]);

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Flex flexDirection="column" gap="12px">
            <FormInput
              maxW="100%"
              name="name"
              label="Name"
              placeholder="Enter Name"
              isRequired
            />
            <Flex flexDirection="column" gap="2px">
              <FormUpload
                label="Select image file to upload"
                title="Banner Image"
                name="image_url"
                type='IMAGE'
                accept={"image/*"}
                isRequired
                handleOnChange={(e) =>
                  handleImageChange(e, "image_url")
                }
                handleDragImage={(val) =>
                  handleDragImage(val, "image_url")
                }
                deleteImage={() => deleteImage("image_url")}
              />
              {!watch("image_url") && (
                <Text>*Currently support .png, .jpg less than 200KB </Text>
              )}
            </Flex>
            <FormInput
              maxW="100%"
              name="prize_items"
              value=""
              label="Prize Item"
              placeholder="Select an item as the prize"
              isRequired
              onClick={() => onOpenPrizeItems()}
              autocomplete="off"
            />
            {checkedIds?.length > 0 && !isOpenPrizeItems && (
              <Flex gap="4px" flexWrap="wrap">
                {checkedIds?.map((check, index) => (
                  <Flex
                    key={index}
                    padding="4px 8px"
                    borderRadius="6px"
                    background="#faf871"
                    alignItems="center"
                  >
                    <Text fontSize="14px" fontWeight="400">{check?.name}</Text>
                  </Flex>
                ))}
              </Flex>
            )}
            <Flex alignItems="center" gap="12px" w="100%" padding="8px 0">
              <FormLabelComponent
                m="0px"
                title="Status"
              />
              <SwitchType
                color="#e04995"
                colorThumb="#fff"
                size="lg"
                colorScheme="teal"
                position="relative"
                isChecked={watch('status')}
                onChange={(e) => {
                  setValue("status", e.target?.checked)
                }}
              />
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="700px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "Edit Banner Setup" : "New Banner Setup"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            {idEdit ? "SAVE" : "ADD"}
          </ButtonType>
        </ModalFooter>
      </ModalContent>
      {isOpenPrizeItems && (
        <ModalSelectPrize
          isOpen={isOpenPrizeItems}
          onClose={onClosePrizeItems}
          setCheckedIds={setCheckedIds}
          checkedIds={checkedIds}
          setValue={setValue}
          watch={watch}
          prizeTypes={prizeTypes}
          prizeType="banner"
          type="PVE_SINGLE_PLAY"
          prizeStatus="IN_USED"
          clearErrors={clearErrors}
          categoryPrizeId={idEdit}
        />
      )}
    </Modal>
  );
}
