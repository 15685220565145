import { Box, Flex, Image, Text } from "@chakra-ui/react";
import ButtonType from "components/Button";
import CheckBoxType from "components/Checkbox";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";

import { convertDateLocalLLLL } from "utils/time";
import { LIST_KEY_STATUS_GACHA } from "views/GachaManagement/constants";

export default function ItemCheck({
  item,
  isClickAble = false,
  nftsChecked,
  setNFTsChecked,
  type = "hunter",
}) {
  const history = useHistory();
  const isChecked = useMemo(() => {
    if (isClickAble) {
      return nftsChecked?.some((itemCheck) => itemCheck?.id === item?.id);
    }
    return false;
  }, [item?.id, nftsChecked, isClickAble]);

  const renderBgItem = useMemo(() => {
    switch (item?.status) {
      case LIST_KEY_STATUS_GACHA.ON_GOING:
        return {
          bg: "linear-gradient(137deg, #EBFE95 0%, #B9EA29 100%)",
          color: "#000",
        };
      case LIST_KEY_STATUS_GACHA.INCOMING:
        return {
          bg: "linear-gradient(137deg, #FFEAAC 0%, #FFC515 100%)",
          color: "#000",
        };
      case LIST_KEY_STATUS_GACHA.TERMINATED:
        return {
          bg: "linear-gradient(137deg, #DBDBDB 0%, #747474 100%)",
          color: "#000",
        };
      default:
        return {
          bg: "linear-gradient(137deg, #EDE6E6 1.63%, #C0BDBD 98.37%)",
          color: "#2D377B",
        };
    }
  }, [item?.status]);

  const handleClickCheck = () => {
    if (isClickAble) {
      if (isChecked) {
        setNFTsChecked(
          nftsChecked.filter((itemCheck) => itemCheck?.id !== item?.id)
        );
      } else {
        setNFTsChecked([...nftsChecked, item]);
      }
    } else {
      return history.push(`/admin/${type}/nft-detail/${item?.id}`);
    }
  };

  return (
    <Flex
      direction="column"
      gap={2}
      w="100%"
      maxW="100%"
      p="10px"
      borderRadius="10px"
      bg={renderBgItem.bg}
      cursor="pointer"
      onClick={handleClickCheck}
      position="relative"
    >
      {isChecked && (
        <Box
          position="absolute"
          borderRadius="10px"
          top={0}
          left={0}
          w="100%"
          h="100%"
          bg="rgba(0, 0, 0, 0.6)"
        >
          <CheckBoxType isChecked position="absolute" top="10px" left="10px" />
        </Box>
      )}
      <Flex direction="column" gap={2}>
        <Flex alignItems="center" gap={2}>
          <Box
            bg="#FFC515"
            w="22px"
            h="22px"
            borderRadius="100%"
            border={`3px solid #fff`}
          />
          <Box width="150px">
            <Text
              fontSize={{
                base: "16px",
              }}
              fontWeight="700"
              isTruncated
            >
              {item?.name}
            </Text>
          </Box>
        </Flex>
        <Flex alignItems="center" justifyContent="center" gap={10}>
          <Flex flexDirection="column" alignItems="center">
            <Image
              src={item?.banner_image_url}
              w="100%"
              h="100px"
              alt={item?.id}
            />
          </Flex>
        </Flex>
        <Flex flexDirection="column" lineHeight="18px">
          <Text fontSize="14px" fontWeight="500">Feature: </Text>
          <Text fontSize="14px" fontWeight="500" width="180px" isTruncated>{item?.feature_description}</Text>
        </Flex>
        <Flex p="2px 8px" direction="column" gap="0" background="#fff" borderRadius="6px">
          <Text fontSize="12px" fontWeight="500">Start: {convertDateLocalLLLL(item?.start_date * 1000)}</Text>
          <Text fontSize="12px" fontWeight="500">End: {convertDateLocalLLLL(item?.end_date * 1000)}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
