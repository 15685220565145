import {
  Flex,
  Icon,
  Image,
  Stack,
  Td,
  Text,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { ViewIcon } from "components/Icons/ViewIcon";
import { useMemo } from "react";
import { splitEclipseCharacter } from "utils/string";
import {
  DIRECTION
} from "views/Machine/MachineSettings/constants";

const SelectMachineRow = ({
  checkedIds,
  setCheckedIds,
  handleSetDataDetail,
  machine,
  listGamePlayType
}) => {
  const textColor = useColorModeValue("#000000", "white");

  const isMachineChecked = useMemo(() => {
    return checkedIds.some((item) => item?.id === machine?.id);
  }, [machine?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isMachineChecked) {
      setCheckedIds(
        checkedIds.filter((checkedId) => checkedId?.id !== machine?.id)
      );
    } else {
      setCheckedIds([...checkedIds, machine]);
    }
  };

  return (
    <Tr key={machine?.id}>
      <Td width="10px">
        <CheckBoxType
          isChecked={isMachineChecked}
          onChange={() => handleCheckboxChange()}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.crane_machine?.mac}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.group_label}-{machine?.group_number}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Image
            src={machine?.icon_url}
            w="100%"
            h="100%"
            alt={machine?.icon_url}
          />
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400} minW="40px">
            {DIRECTION[machine?.crane_machine?.direction]}
          </Text>
        </Stack>
      </Td>
      <Td>
              <Text color={textColor} fontSize="sm" fontWeight={400}>
                {splitEclipseCharacter(
                  listGamePlayType?.find(
                    (item) => item?.value === machine?.gameplay_type_id
                  )?.label
                )}
              </Text>
            </Td>
      <Td>
        <Flex gap={6} align="center">
          <Icon
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={ViewIcon}
            onClick={() => handleSetDataDetail(machine?.id)}
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default SelectMachineRow;
