import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { importCSVSerial } from "api/digitaFigure.api";
import ButtonType from "components/Button";
import FormLabelComponent from "components/form/FormLabel";
import FormUpload from "components/form/FormUpload";
import { useCallback, useMemo, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { ERROR_DIGITAL_FIGURE } from "views/DigitalFigure/constants";
import ItemCSV from "views/Hunter/import/itemCSV";

export default function ModalImportCode({
  isOpen,
  onClose,
  item,
  handleCreateOrUpdateSuccess,
}) {
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const form = useForm();
  const [type, setType] = useState("success");

  const handleClose = () => {
    onClose()
  }

  const {
    getValues,
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const {
    fields: fieldCSV,
    append: appendCSV,
    remove: removeCSV,
  } = useFieldArray({
    control,
    name: "listCSV",
  });

  const clearListCSV = () => {
    reset({
      ...getValues(),
      listCSV: [],
    });
  };

  const importCSV = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const params = {
        digital_figure_id: [item?.id]
      }
      return importCSVSerial(formData, params)
    } catch (error) {
      toast({
        description: `${error}`,
        status: "error",
        position: "top",
      });
    }
  };

  const handleOnChangeCSV = (files) => {
    removeCSV(0);
    if (files?.length > 0) {
      const listFile = files?.map((file) => {
        return { file };
      });
      if (listFile && listFile?.length > 0) {
        appendCSV(listFile[0]);
      }
    }
  };

  const isExitsListCSV = useMemo(() => {
    return fieldCSV && fieldCSV?.length > 0;
  }, [fieldCSV]);

  const isExitCSVFail = useMemo(() => {
    if (fieldCSV && fieldCSV?.length > 0) {
      const list = fieldCSV?.filter((item) => {
        if (item?.isError) {
          return item;
        }
      });
      return list?.length > 0;
    }
    return false;
  }, [fieldCSV]);

  const listNameCSVErrors = useCallback(
    (listError) => {
      if (fieldCSV && fieldCSV?.length > 0) {
        const list = fieldCSV
          ?.filter((item, index) => {
            if (listError[index]) {
              return item;
            }
          })
          .map((item) => item?.file?.name);
        return list?.join(", ");
      }
      return null;
    },
    [fieldCSV]
  );

  const onSubmit = useCallback(async () => {
    try {
      if (isExitsListCSV) {
        const promise = fieldCSV?.map((item) => {
          return importCSV(item?.file);
        });
        const res = await Promise.all(promise);
        if (res) {
          let arrError = [];
          let isError = false;
          for (let i = 0; i < res?.length; i++) {
            const err = !res[i]?.data?.success;
            if (err) {
              isError = true;
            }
            arrError = arrError.concat(err);
          }
          if (isError) {
            reset({
              ...getValues(),
              listCSV: fieldCSV?.map((item, index) => ({
                ...item,
                isError: arrError[index],
              })),
            });
            setType("error");
            const listNameError = listNameCSVErrors(arrError);
            if (listNameError) {
              if (!res[0]?.data?.data) {
                toast({
                  description: `${res[0]?.data?.messages[0]}`,
                  status: "error",
                  position: "top",
                });
              } else {
                toast({
                  description: `${res[0]?.data?.data[0]?.error_message?.replace(
                    ";",
                    "."
                  )}`,
                  status: "error",
                  position: "top",
                });
              }
            }
          } else {
            toast({
              title: "Import Successfully!",
              description: "Import Successfully!",
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top",
            });
            handleCreateOrUpdateSuccess()
            clearListCSV();
            onClose()
          }
        }
      }
    } catch (error) {
      toast({
        description: ERROR_DIGITAL_FIGURE[error?.message] || ERROR_DIGITAL_FIGURE[error?.messages[0]],
        status: "error",
        position: "bottom-right",
      });
    }
  }, [isExitsListCSV, listNameCSVErrors]);

  const renderForm = () => {
    return (
      <FormProvider {...form} w="100%">
        <form
          id="role-manager-form-id"
          onSubmit={handleSubmit(onSubmit)}
          style={{
            width: "100%",
            minWidth: "600px",
            overflowX: "auto",
          }}
        >
          <Flex direction="column" gap={4}>
            <FormLabelComponent
              m="0px"
              title='Import Serial Code'
            />
            <Flex direction="column" gap={4} w="100%">
              <FormUpload
                label="Select a file to upload"
                name="csv"
                handleOnChange={handleOnChangeCSV}
                accept=".csv"
              />
              {fieldCSV?.length === 0 && (
                <Text textAlign="center">*Currently support .csv</Text>
              )}
            </Flex>
            <Flex
              direction="column"
              gap={2}
              w="100%"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              {fieldCSV?.map((item, index) => {
                return (
                  <ItemCSV
                    key={item?.id}
                    name={item?.file?.name}
                    isError={item?.isError}
                    indexItem={index}
                    removeCSV={removeCSV}
                  />
                );
              })}
            </Flex>
            <Flex gap="16px" pl="2px" pb="2px" justifyContent="center">
              <ButtonType
                fontSize="16px"
                fontWeight="500"
                w="140px"
                h="56px"
                type="submit"
                btnType="primary-new"
                disabled={
                  !isExitsListCSV || isExitCSVFail
                }
                isLoading={isSubmitting}
              >
                Import
              </ButtonType>
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} closeOnOverlayClick={false} size="xxl">
      <ModalOverlay />
      <ModalContent maxW="900px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          IMPORT SERIAL CODE
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">
          {renderForm()}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
