import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat';
import moment from 'moment'
import utc from 'dayjs/plugin/utc.js'
import { FORMAT_TIME } from 'constants/enum'

dayjs.extend(advancedFormat);

// 00:00:00
export const formatTime = (millis) => {
  if (millis < 0) return '00:00:00'
  const date = new Date(millis)
  const seconds = date.getUTCSeconds()
  const minutes = date.getUTCMinutes()
  const hours = date.getUTCHours()
  return `${hours < 10 ? '0' + hours : hours}:${
    minutes < 10 ? '0' + minutes : minutes
  }:${seconds < 10 ? '0' + seconds : seconds}`
}

// 1m:1s
export const formatTimeMS = (millis) => {
  if (millis < 0) return '00:00:00'
  const date = new Date(millis)
  const seconds = date.getUTCSeconds()
  const minutes = date.getUTCMinutes()
  return `${minutes + 'm'}:${seconds + 's'}`
}

export const getWeekDays = (date) => {
  const dateTime = date || new Date()
  const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
  const d = new Date(dateTime)
  const dayName = days[d.getDay()]
  return dayName
}

export const formatTimestamp = (timestamp) => {
  return moment(timestamp*1000).format('HH:mm');
}

export const isoDateFormat = (value = new Date()) => {
  return dayjs(value).toISOString()
}

// YYYY-MM-DD
export const convertDateTime = (date) => {
  return dayjs(date).format(FORMAT_TIME.YEAR_MONTH_DATE)
}

export const getTimeStampCurrent = () => {
  return dayjs().unix()
}

// YYYY-MM-DD
export const convertTimestampToDate = (value) => {
  if (!value) return '---'
  dayjs.extend(utc)
  const dateTime = dayjs.unix(value)
  return dateTime.format(FORMAT_TIME.YEAR_MONTH_DATE)
}

// 18 Mar 2024 16:48:34
export const getDayLLLL = (value) => {
  return dayjs(value).format(FORMAT_TIME.FULL_DATE_LLLL)
}

// YYYY-MM-DD HH:mm
export const convertShortDateLocal = (value) => {
  return moment.utc(value).local().format(FORMAT_TIME.DATE_HOUR_MINUTES)
}

export const convertShortDateLocalSlash = (value) => {
  return moment.utc(value).local().format(FORMAT_TIME.DATE_HOUR_MINUTES_SLASH)
}

// YYYY-MM-DD HH:mm:ss
export const convertTime = (value) => {
  return moment(value).format(FORMAT_TIME.DATE_HOUR_MINUTES_SECOND)
}

// YYYY-MM-DD HH:mm:ss
export const convertTimeUTC = (value) => {
  return moment(value).utc().format(FORMAT_TIME.DATE_HOUR_MINUTES_SECOND)
}

// 18 Mar 2024 16:48:34
export const convertDateLocalLLLL = (value) => {
  return moment(value).utc().local().format(FORMAT_TIME.FULL_DATE_LLLL)
}

// YYYY-MM-DD HH:mm:ss => 111111111
export const convertTimeUTCNum = (value) => {
  return moment(value).utc().unix()
}

// local YYYY-MM-DD
export const convertDateLocal = (value) => {
  return moment(value).utc().local().format(FORMAT_TIME.YEAR_MONTH_DATE)
}

// local 18 Mar 2024 16:48:34
export const getDateLocalLLLL = (value) => {
  return moment(value).utc().local().format(FORMAT_TIME.FULL_DATE_LLLL)
}

// local 18 Mar 2024 16:48:34
export const getDateUTCLLLL = (value) => {
  return moment.utc(value).format(FORMAT_TIME.FULL_DATE_LLLL)
}

// YYYY-MM-DD
export const shortDate = (date) => {
  return moment(date).format(FORMAT_TIME.YEAR_MONTH_DATE)
}

// YYYY-MM-DD HH:mm:ss
export const dateTimeStr = () => {
  return moment().format(FORMAT_TIME.DATE_HOUR_MINUTES_SECOND)
}

// 2013-02-04T22:44:30.652Z
export const getDateNowISO8601 = () => {
  return moment().toISOString()
}

// consert Sat Apr 30 2016 16:59:46 GMT-0500
export const convertDateToString = (value = new Date()) => {
  return moment(value).toString()
}

export const getYearNow = () => {
  return moment().year()
}

export const getMonthNow = () => {
  return 1 + moment().month()
}

export const getDayNow = () => {
  return moment().date()
}

export const getDaySubtract = () => {
  const arrDays = []
  for (let index = 29; index >= 1; index -= 1) {
    const date = moment().subtract(index, 'days')
    arrDays.push({
      day: date.date(),
      month: 1 + date.month(),
    })
  }
  arrDays.push({
    day: moment().date(),
    month: 1 + moment().month(),
  })
  return arrDays
}

export const getInputDaySubtract = (index) => {
  return moment().subtract(index, 'days')
}

export const getStartDay = (val = '') => {
  if (val) {
    return moment(val).valueOf()
  }
  return moment(moment().utc().startOf('day').toString()).valueOf()
}

export const getEndDay = (val = '') => {
  if (val) {
    return moment(val).valueOf()
  }
  return moment(moment().utc().endOf('day').toString()).valueOf()
}

export const convertDayString = (day = 0) => {
  return day?.length === 1 ? `0${day}` : day
}

// timestamp to MM/YYYY
export const formatTimestampToMonthYear = (timestamp) => {
  return timestamp ? dayjs(timestamp).format('MM/YYYY') : '';
}

// timestamp to MM-YYYY
export const formatTimestampToMonthYearDash = (timestamp) => {
  return timestamp ? dayjs(timestamp).format('MM-YYYY') : '';
}

export const convertToTwoDigits = (number) => {
  if (!number) return "01";
  return dayjs().date(number).format('DD');
}

// 1 -> 1st, 2 -> 2nd, 3 -> 3rd,..., 100 -> 100th
export const getOrdinalNumber = (number) => {
  if (!number) return 0;
  return dayjs().date(number).format('Do');
}

export const convertTimestamp = (timestamp) => {
  const futureDate = moment.unix(timestamp);

  // Calculate the difference from the current time
  const duration = moment.duration(futureDate.diff(moment()));

  // Extract the days, hours, and minutes
  const totalDays = Math.floor(duration.asDays());
  // const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  // Return the result in "xd xh xm" format
  return `${totalDays}d ${hours}h ${minutes}m`;
}
