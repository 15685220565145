import { Icon } from "@chakra-ui/icons";
import {
  Flex,
  Image,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { EditIcon } from "components/Icons/EditIcon";
import { LABEL_EVENT, ROLE_OPTIONS } from "constants/constants";
import { useSelector } from "react-redux";
import { userinfoSelector } from "stores/user/selector";
import { convertTimestamp } from "utils/time";

export default function RoleManagementRow({
  item,
  index,
}) {
  const textColor = useColorModeValue("#929292", "white");
  const { userinfo } = useSelector(userinfoSelector)

  return (
    <>
      <Tr>
        <Td>
          <Flex alignItems='center' gap={6}>
            <Image
              w="200px"
              height='160px'
              src={item?.event_image_url}
              alt={item?.event_image_url}
            />
            <Flex direction='column' gap={6}>
              <Text color="#000" fontSize="md" fontWeight={600}>{item?.event_name}</Text>
              <Text color={item?.event_type === "SKILL_RANK_EVENT" ? "#06b3b9" : "#b044ee"} fontSize="md" fontWeight={600}>{LABEL_EVENT[item?.event_type]}</Text>
            </Flex>
          </Flex>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={600}>
            {item?.playing_players}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={600}>
            {item?.participants}
          </Text>
        </Td>
        <Td>
          <Text color="#06b3b9" padding="2px 6px" backgroundColor="#d1fdff" fontSize="sm" fontWeight={600} maxWidth='max-content' borderRadius="8px">
            {convertTimestamp(item?.event_end_time)}
          </Text>
        </Td>
      </Tr>
    </>
  );
}
