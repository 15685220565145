import {
  Box,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "components/form/FormInput";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import schemaMachine from "./schema";
import ButtonType from "components/Button";
import FormTextAria from "components/form/FormTextAria";
import { MACHINE_TYPE_OPTIONS, STATUS } from "constants/constants";
import FormSelect from "components/form/FormSelect";
import ModalSelectPrize from "views/NewGameMode/Common/SelectPrize";
import { createGameMode, getDetailGameMode, updateGameMode } from "api/gameMode.api";
import styled from "styled-components";
import { ERROR_GAME_MODE } from "views/NewGameMode/constant";
import SwitchType from "components/Switch";
import FormLabelComponent from "components/form/FormLabel";

const TRANS_LABEL = {
	en: {
		name: "Mode name",
		enterName: "Input Mode name",
		modeType: "Mode type",
		des: "Description",
    playFee: "Play fee per turn",
    prize: "Prize(s)",
    boothType: "Booth Type",
    gameType: "Gameplay Type",
    enterValue: "Enter a value",
    numberTurns: "Default number of turns",
    liftGauge: "Life gauge consumed per turn",
	},
	jp: {
		name: "モード名",
		enterName: "入力モード名",
		modeType: "モードタイプ",
		des: "説明",
    playFee: "1ターンあたりのプレイ料金",
    prize: "賞品",
    boothType: "ブースタイプ",
    gameType: "ゲームプレイタイプ",
    enterValue: "値を入力してください",
    numberTurns: "デフォルトのターン数",
    liftGauge: "ターンごとに消費されるライフゲージ",
	},
};

const TextError = styled.div`
  padding: 0 24px;
  color: #E53E3E;
  font-size: 16px;
`

export default function CreateAndUpdatePvp({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
  listPlayFee,
  listGamePlayType,
  type,
  prizeTypes,
}) {
  const [tabIndex, setTabIndex] = useState(0);
  const [trans, setTrans] = useState("en");
  const [checkedIds, setCheckedIds] = useState([]);
  const [gameModeDetail, setGameModeDetail] = useState(dataDetail)
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const form = useForm({
    resolver: yupResolver(schemaMachine()),
  });
  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    clearErrors,
    formState: { errors, isSubmitting },
  } = form;

  const { 
    isOpen: openPrizes, 
    onOpen: onOpenPrizes, 
    onClose: onClosePrizes 
  } = useDisclosure();

  const disableWhenJP = useMemo(() => {
    return trans === "jp";
  }, [trans]);

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  const getDataDetailGameMode = async () => {
    const { data } = await getDetailGameMode(dataDetail?.id);
    setGameModeDetail(data?.data)
  }

  useEffect(() => {
    if (dataDetail) {
      getDataDetailGameMode(dataDetail?.id)
    } else {
      reset();
    }
  }, [dataDetail]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    if (index === 1) {
      setTrans("jp");
    } else {
      setTrans("en");
    }
  };

  const transLabel = (label) => {
    return TRANS_LABEL[trans][label];
  };
  const onSubmit = async (data, e) => {
    e.preventDefault();
    const dataSubmit = {
      mode_name: data?.name,
      description: data?.description,
      mode_type: "PVE_SINGLE_PLAY",
      play_fee_id: data?.play_fee_id,
      prizes: checkedIds?.map((check) => ({
        prize_id: check?.id,
        prize_type: check?.prize_type
      })),
      gameplay_type_id: data?.gameplay_type_id,
      turn_each_player: data?.number_turn,
      life_gauge_consumption: data?.lift_gauge,
      machine_type: data?.machine_type,
      status: data?.status ? STATUS["ACTIVE"] : STATUS["NO_ACTIVE"],
      translations: [
        {
          lang_key: "EN",
          name: data?.name,
          description: data?.description || "",
        },
        {
          lang_key: "JP",
          name: data?.name_jp,
          description: data?.description_jp || "",
        }
      ]
    }
    try {
      if (idEdit) {
        const res = await updateGameMode(idEdit, dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Edited Battle Arena game mode successfully!.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose()
        } else {
          toast({
            description: ERROR_GAME_MODE[res?.data?.message] || ERROR_GAME_MODE[res?.data?.messages[0]] || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          }); 
        }
      } else {
        const res = await createGameMode(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Create Battle Arena game mode successfully!.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose()
        } else {
          toast({
            description: ERROR_GAME_MODE[res?.data?.message] || ERROR_GAME_MODE[res?.data?.messages[0]] || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });          
        }
      }
    } catch (error) {
      toast({
        description: ERROR_GAME_MODE[error?.message] || ERROR_GAME_MODE[error?.messages[0]],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    if (gameModeDetail?.id) {
      const itemJP = gameModeDetail?.translations?.find(
        (item) => item?.lang_key === "JP"
      );
      const itemEN = gameModeDetail?.translations?.find(
        (item) => item?.lang_key === "EN"
      );
      const listPrizes = gameModeDetail?.prize_items?.map((game) => ({
        ...game,
        id: game?.prize_id
      }))
      setCheckedIds(listPrizes)
      reset({
        name: itemEN?.name,
        name_jp: itemJP?.name,
        description: itemEN?.description,
        description_jp: itemJP?.description,
        mode_type: "Single play",
        lift_gauge: gameModeDetail?.life_gauge_consumption,
        number_turn: gameModeDetail?.turn_each_player,
        play_fee_id: gameModeDetail?.play_fee_id,
        gameplay_type_id: gameModeDetail?.gameplay_type_id,
        machine_type: gameModeDetail?.machine_type,
        prize_items: listPrizes,
        status: gameModeDetail?.status === STATUS["ACTIVE"] ? true : false,
      });
    } else {
      reset({
        mode_type: "Single play",
        play_fee_id: listPlayFee[0]?.value,
        prize_items: [],
        status: false,
      });
    }
  }, [reset, gameModeDetail]);

  const disableFreeTicket = useMemo(() => {
    const listFee = listPlayFee?.filter((fee) => fee?.id === watch('play_fee_id'))
    const isValidate = listFee[0]?.payment_item === "PVE_FREE_TICKET"
    if (isValidate) {
      setValue('number_turn', 1)
      setValue('lift_gauge', 0)
    }
    return isValidate
  }, [watch('play_fee_id')]);
  
  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            mb={4}
            index={tabIndex}
            variant="unstyled"
            onChange={handleTabsChange}
          >
            <TabList gap={2}>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                English
              </Tab>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                Japanese
              </Tab>
            </TabList>
          </Tabs>
          <Flex direction="column" w="100%" gap={4}>
            <Grid templateColumns="repeat(1, 1fr)" gap={4}>
              <Flex direction="column" gap={4}>
                {trans === "en" ? (
                  <Box display="flex" flexDirection="column" gap="8px">
                    <FormInput
                      maxW="100%"
                      name="name"
                      label={transLabel("name")}
                      placeholder={transLabel("enterName")}
                      isRequired
                    />
                    <FormInput
                      w="100%"
                      name="mode_type"
                      label={transLabel("modeType")}
                      disabled
                    />
                    <FormTextAria
                      label={transLabel("des")}
                      placeholder=""
                      name="description"
                      h="100px"
                      borderRadius="8px"
                    />
                  </Box>
                ) : (
                  <Flex flexDirection="column" gap="8px">
                    <FormInput
                      maxW="100%"
                      name="name_jp"
                      label={transLabel("name")}
                      placeholder={transLabel("enterName")}
                      isRequired
                    />
                    <FormInput
                      w="100%"
                      name="mode_type"
                      label={transLabel("modeType")}
                      disabled
                    />
                    <FormTextAria
                      label={transLabel("des")}
                      placeholder=""
                      name="description_jp"
                      h="100px"
                      borderRadius="8px"
                    />
                  </Flex>
                )}
                <>
                  <Flex alignItems="flex-start" gap="16px" w="100%">
                    <FormSelect
                      wrapperProps={{
                        w: "100%",
                      }}
                      name="play_fee_id"
                      label={transLabel("playFee")}
                      defaultValue={listPlayFee[0]?.value}
                      options={listPlayFee}
                      onChange={(e) => {
                        setValue("play_fee_id", e.target.value)
                        setValue("prize_items", null)
                        setCheckedIds([])
                      }}
                      isRequired
                      disabled={disableWhenJP}
                    />
                    <Flex flexDirection='column' gap="12px" w="100%" padding="8px 0">
                      <FormLabelComponent
                        m="0px"
                        title="Status"
                      />
                      <SwitchType
                        color="#e04995"
                        colorThumb="#fff"
                        size="lg"
                        colorScheme="teal"
                        isChecked={watch('status')}
                        onChange={(e) => {
                          setValue("status", e.target?.checked)
                        }}
                      />
                    </Flex>
                  </Flex>
                  <FormInput
                    type="text"
                    label={transLabel("prize")}
                    name="prize_items"
                    placeholder="Select prize(s) for game mode"
                    onClick={() => onOpenPrizes()}
                    disabled={disableWhenJP}
                    value=""
                    autoComplete="off"
                  />
                  {checkedIds?.length > 0 && !openPrizes && (
                    <Flex gap="4px">
                      {checkedIds?.map((check, index) => (
                        <Flex
                          key={index}
                          padding="4px 8px"
                          borderRadius="6px"
                          background="#faf871"
                          alignItems="center"
                        >
                          <Text fontSize="14px" fontWeight="400">{check?.name || check?.prize_name}</Text>
                          {/* <SmallCloseIcon onClick={() => filterChecked(check)}/> */}
                        </Flex>
                      ))}
                    </Flex>
                  )}
                  <Flex alignItems="flex-start" gap="16px" w="100%">
                    <FormSelect
                      wrapperProps={{
                        w: "100%",
                      }}
                      name="machine_type"
                      label={transLabel("boothType")}
                      defaultValue={"ALL"}
                      options={[{ label: "All", value: "ALL" }, ...MACHINE_TYPE_OPTIONS]}
                      disabled={disableWhenJP || disableFreeTicket}
                    />
                    <FormSelect
                      wrapperProps={{
                        w: "100%",
                      }}
                      name="gameplay_type_id"
                      label={transLabel("gameType")}
                      defaultValue={listGamePlayType[0]?.value}
                      options={listGamePlayType}
                      disabled={disableWhenJP || disableFreeTicket}
                    />
                  </Flex>
                  <Flex alignItems="flex-start" gap="16px" w="100%">
                    <FormInput
                      wrapperProps={{
                        w: "100%",
                      }}
                      type="number"
                      min={1}
                      name="number_turn"
                      placeholder={transLabel("enterValue")}
                      label={transLabel("numberTurns")}
                      isRequired
                      disabled={disableWhenJP || disableFreeTicket}
                    />
                    <FormInput
                      wrapperProps={{
                        w: "100%",
                      }}
                      min={0}
                      type="number"
                      name="lift_gauge"
                      placeholder={transLabel("enterValue")}
                      label={transLabel("liftGauge")}
                      isRequired
                      disabled={disableWhenJP || disableFreeTicket}
                    />
                  </Flex>
                </>
              </Flex>
            </Grid>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="700px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "Edit PVE game mode" : "New PVE game mode"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        {errors?.name_jp?.message && trans === "en" && <TextError>Mode name Japan is {errors?.name_jp?.message}</TextError>}
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
      {openPrizes && (
        <ModalSelectPrize
          isOpen={openPrizes}
          onClose={onClosePrizes}
          setCheckedIds={setCheckedIds}
          checkedIds={checkedIds}
          type={type}
          watch={watch}
          idModeDetail={idEdit ? idEdit : ""}
          setValue={setValue}
          prizeTypes={prizeTypes}
          clearErrors={clearErrors}
          isFreeTicket={disableFreeTicket}
        />
      )}
    </Modal>
  );
}
