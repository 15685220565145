import { Box, Button, FormLabel } from '@chakra-ui/react'
import React from 'react'
import ReactQuill, { Quill } from "react-quill";
import "../../assets/css/quill.css";
import backgroundIcon from "../../assets/img/background_color.png";

const CustomToolbar = () => (
  <Box
    id='toolbar'
    borderEndStartRadius='15px'
    borderBottomEndRadius='15px'
    borderTop='0px solid transparent !important'
    display='flex'
    justifyContent='flex-end'
    background="lightgray"
  >
    <select className="ql-color">
      <option value="#000000">Black</option>
      <option value="#FF0000">Red</option>
      <option value="#FFA500">Orange</option>
      <option value="#FFFF00">Yellow</option>
      <option value="#008000">Green</option>
      <option value="#0000FF">Blue</option>
      <option value="#800080">Purple</option>
      <option value="#FFC0CB">Pink</option>
      <option value="#FFFFE0">Light Yellow</option>
      <option value="#FFFFCC">Light Yellow 2</option>
      <option value="#98FB98">Pale Green</option>
      <option value="#ADD8E6">Light Blue</option>
      <option value="#DDA0DD">Plum</option>
      <option value="#808080">Gray</option>
      <option value="#FF6347">Tomato</option>
      <option value="#FFD700">Gold</option>
      <option value="#90EE90">Light Green</option>
      <option value="#20B2AA">Light Sea Green</option>
      <option value="#9370DB">Medium Purple</option>
      <option value="#800000">Maroon</option>
      <option value="#8B0000">Dark Red</option>
      <option value="#FF8C00">Dark Orange</option>
      <option value="#008080">Teal</option>
      <option value="#4682B4">Steel Blue</option>
      <option value="#4B0082">Indigo</option>
      <option value="#B22222">Firebrick</option>
      <option value="#FF4500">Orange Red</option>
      <option value="#DAA520">Goldenrod</option>
      <option value="#006400">Dark Green</option>
      <option value="#00008B">Dark Blue</option>
      <option value="#6A5ACD">Slate Blue</option>
    </select>
    <Button
      display='flex !important'
      justifyContent='center !important'
      alignItems='center !important'
      className='ql-bold'></Button>
    <Button
      display='flex !important'
      justifyContent='center !important'
      alignItems='center !important'
      className='ql-underline'></Button>
      <Button
      display='flex !important'
      justifyContent='center !important'
      alignItems='center !important'
      className='ql-link'></Button>
  </Box>
)

export default class Editor extends React.Component {
  constructor(props) {
    super(props)
    this.state = { editorHtml: this.props?.descriptions }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.descriptions !== this.props?.descriptions) {
      this.setState({ editorHtml: this.props?.descriptions });
    }
  }

  componentDidMount() {
    const backgroundPickerLabel = document.querySelector('.ql-color .ql-picker-label');
    if (backgroundPickerLabel) {
      backgroundPickerLabel.innerHTML = `
        <img
          src="${backgroundIcon}"
          alt="Background"
          style="width: 20px; height: 20px; display: inline-block; vertical-align: middle;"
        />
      `;
    }
  }
  calculateHWFWLength(value) {
    if (!value) return 0;
    return [...value.trim()].reduce((sum, char) => {
      const code = char.charCodeAt(0);
      const isFullWidth = code >= 0xFF00 && code <= 0xFFEF;
      return sum + (isFullWidth ? 2 : 1);

    }, 0);
  }

  convertRgbToHex(content) {
    return content.replace(
      /rgb\((\d+),\s*(\d+),\s*(\d+)\)/g,
      (_, r, g, b) => {
        const toHex = (value) => parseInt(value, 10).toString(16).padStart(2, '0');
        return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
      }
    );
  }

  removeSpaceInColorStyle(html) {
    return html.replace(/style="([^"]*color:)\s*#/g, 'style="$1#');
  }

  handleChange(html) {
    const strippedHtml = html.replace(/<[^>]+>/g, "").trim();
    const maxLength = 500;

    if (this.calculateHWFWLength(strippedHtml) > maxLength) {
      if (!this.state.hasError) {
        this.props?.setError(this.props?.name, { message: `Content must be less than ${maxLength} characters.` });
        this.setState({ hasError: true });
      }
    } else {
      if (this.state.hasError) {
        this.props?.clearErrors(this.props?.name);
        this.setState({ hasError: false });
      }
    }
    const convertedHtml = this.convertRgbToHex(html);
    const updatedHtml = convertedHtml.replace(
      /<a(?![^>]*style="[^"]*color:*;)([^>]*)>/gi,
      '<a$1 style="color:#0000EE;">'
    );
    if (this.props?.setValue) {
      this.props.setValue(this.props?.name, this.removeSpaceInColorStyle(updatedHtml));
    }

    this.setState({ editorHtml: html })
  }

  render() {
    return (
      <div className='text-editor'>
        <FormLabel>
          <label>{this.props?.lable}</label>
          {this.props?.isRequired && <span style={{ color: 'red' }}>*</span>}
        </FormLabel>
        <ReactQuill
          value={this.state.editorHtml}
          onChange={this.handleChange}
          placeholder={this.props?.placeholder}
          modules={Editor.modules}
          className="quill-editor"
          readOnly={this.props?.isReadOnly}
        />
        <CustomToolbar />
      </div>
    )
  }
}

Editor.modules = {
  toolbar: {
    container: '#toolbar',
  },
  clipboard: {
    matchVisual: false,
  },
  color: [],
}

const Bold = Quill.import("formats/bold");
Bold.tagName = "B";
Quill.register(Bold, true);

Editor.modules = {
  toolbar: {
    container: '#toolbar',
  },
  clipboard: {
    matchVisual: false,
  },
}

Editor.formats = [
  'bold',
  'underline',
  'link',
  'color'
]
