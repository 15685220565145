import { Icon } from "@chakra-ui/icons";
import {
  Flex,
  Image,
  Stack,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { useMemo } from "react";
import { ViewIcon } from "components/Icons/ViewIcon";
import { bgFeature } from "views/DigitalFigure/constants";

export default function ItemsSendRow({
  machine,
  checkedIds,
  setCheckedIds,
  index,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const isMachineChecked = useMemo(() => {
    return checkedIds.some((item) => item?.id === machine?.id);
  }, [machine?.id, checkedIds]);
  
  const handleCheckboxChange = () => {
    if (isMachineChecked) {
      setCheckedIds(checkedIds.filter((item) => item?.id !== machine?.id));
    } else {
      setCheckedIds([{
        ...machine,
        grand_prize: true,
      }]);
    }
  };

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isMachineChecked}
          onChange={() => handleCheckboxChange()}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {index}
        </Text>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Image
            src={machine?.image_url}
            w="100px"
            alt={machine?.image_url}
          />
        </Stack>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {machine?.name}
        </Text>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.partner?.name}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Flex gap="4px" flexWrap="wrap">
          {machine?.is_ar && (
            <Text color={textColor} fontSize="sm" fontWeight={400} borderRadius="20px" p="4px 8px" bg={bgFeature("AR")} >
              AR
            </Text>
          )}
          {machine?.is_3d && (
            <Text color={textColor} fontSize="sm" fontWeight={400} borderRadius="20px" p="4px 8px" bg={bgFeature("3D")}>
              3D
            </Text>
          )}
          {machine?.is_room && (
            <Text color={textColor} fontSize="sm" fontWeight={400} borderRadius="20px" p="4px 8px" bg={bgFeature("ROOM")}>
              ROOM
            </Text>
          )}
          {machine?.is_holo_model && (
            <Text color={textColor} fontSize="sm" fontWeight={400} borderRadius="20px" p="4px 8px" bg={bgFeature("HoloModel")}>
              HoloModel
            </Text>
          )}
        </Flex>
      </Td>
      {/* <Td>
        <Flex gap={6} align="center">
          <Icon
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={ViewIcon}
          />
        </Flex>
      </Td> */}
    </Tr>
  );
}
