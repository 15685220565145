import { Flex, useDisclosure, useToast } from "@chakra-ui/react";
import ButtonType from "components/Button";
import DeleteModal from "components/Modal/deleteModal";
import { NUMBER } from "constants/enum";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { deletePrizeAllApi, deletePrizeByIdApi } from "stores/prize/action";
import { prizeSelector } from "stores/prize/selector";

export default function ActionPrizeSettings({
  handleOpenCreateOrUpdate,
  checkedIds,
  setCheckedIds,
  setParams,
  params,
  onOpenNotice,
}) {
  const { t } = useTranslation("prize");
  const dispatch = useDispatch();
  const toast = useToast();
  const { statusDelete } = useSelector(prizeSelector);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (statusDelete) {
      const newParams = {
        ...params,
        page: 1,
      };
      setParams(newParams);
      setCheckedIds([]);
      onClose();
    }
  }, [statusDelete]);

  const handleDelete = async () => {
    try {
      if (checkedIds?.length) {
        const listPrizes = checkedIds?.map((check) => check?.id)
        const dataDelete = await dispatch(deletePrizeAllApi(listPrizes));
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Flex gap={4} flexWrap="wrap" alignItems="flex-end">
      <Flex alignItems="flex-end" gap={6} mb="10px">
        <ButtonType
          text="ADD"
          fontSize="12px"
          width="100px"
          onClick={() => {
            handleOpenCreateOrUpdate();
          }}
        />
        <ButtonType
          text="DELETE"
          width="100px"
          fontSize="12px"
          btnType="primary-new-outline"
          disabled={checkedIds?.length === 0}
          onClick={() => {
            const checkInUse = checkedIds?.some((check) => check?.prize_status === "IN_USED")
            if (checkInUse) {
              onOpenNotice()
            } else {
              onOpen()
            }
          }}
        />
      </Flex>
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        handleDelete={handleDelete}
        title={t("delete_prize_title")}
        content={t("content_delete")}
        btnYes="OK"
        btnNo="Cancel"
      />
    </Flex>
  );
}
