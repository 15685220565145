import {
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  getMachineQuantity,
  getMonthlyPayoutSettings,
  importMonthlyPayoutSettings,
  updateMachineQuantity,
  updateMonthlyPayoutSettings,
} from "api/ownerNFTs.api";
import BackIcon from "assets/svg/back-icon.svg";
import ButtonType from "components/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import FormInput from "components/form/FormInput";
import { CheckIcon } from "components/Icons/CheckIcon";
import { EditIcon } from "components/Icons/EditIcon";
import ModalImportFile from "components/Modal/ModalImportFile";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import useWithToast from "hooks/useWithToast";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { onlyNumber } from "utils/number";
import MonthlyPayoutRow from "views/NFTsManagement/components/tableRow/MonthlyPayoutRow";
import { DEFAULT_FILTER_OWNER_PARAMS } from "views/NFTsManagement/constants";

const labels = ["MONTH", "PINK NFT", "GREEN NFT", "PURPLE NFT", ""];
const typeNftError = {
  pinkNftRate: "PINK NFT",
  greenNftRate: "GREEN NFT",
  purpleNftRate: "PURPLE NFT",
};

export default function MonthlyPayoutSettingsPage() {
  const history = useHistory();
  const toast = useToast();
  const form = useForm();
  const { showToastSuccess, showToastFail } = useWithToast();
  const textColor = useColorModeValue("#000000", "white");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImport, setIsLoadingImport] = useState(false);
  const [isRefetch, setIsRefetch] = useState(false);
  const [idRowEdit, setIdRowEdit] = useState(null);
  const [isEditNumOfMachine, setIsEditNumOfMachine] = useState(false);
  const [monthlyPayout, setMonthlyPayout] = useState([]);

  const {
    isOpen: isOpenImportCSV,
    onOpen: onOpenImportCSV,
    onClose: onCloseImportCSV,
  } = useDisclosure();

  const [params, setParams] = useState(DEFAULT_FILTER_OWNER_PARAMS);

  const onBackToOwnerNftList = () => {
    history.push("/admin/nfts/owner-nfts-management");
  };

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  const handleChangeNumOfMachine = async () => {
    try {
      const numOfMachine = onlyNumber(form?.getValues()?.machine);
      if (numOfMachine) {
        const { data } = await updateMachineQuantity({
          quantity: numOfMachine,
        });
        if (data.success) {
          setIsEditNumOfMachine(false);
          showToastSuccess({
            title: "Data update successful.",
          });
        } else {
          const message = data?.message?.content || data?.messages[0];
          showToastFail({
            title: message,
          });
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const debouncedChangeNumOfMachine = useCallback(
    debounce(() => handleChangeNumOfMachine(), 500),
    [form]
  );

  const handleUpdateValueNft = async (newData) => {
    setIsLoading(true);
    try {
      if (newData) {
        const params = {
          pink_nft_rate: newData?.pink_nft_rate,
          green_nft_rate: newData?.green_nft_rate,
          purple_nft_rate: newData?.purple_nft_rate,
        };
        const { data } = await updateMonthlyPayoutSettings(idRowEdit, params);
        if (data.success) {
          setIdRowEdit(null);
          setIsRefetch((preState) => !preState);
          showToastSuccess({
            title: "Data update successful.",
          });
        } else {
          const message = data?.message?.content || data?.messages[0];
          const nftError = message?.split(":")?.[0]
          showToastFail({
            title: message?.replace(nftError, typeNftError[nftError]),
          });
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleImportCSV = async (file) => {
    setIsLoadingImport(true);
    try {
      const { data } = await importMonthlyPayoutSettings(file);
      if (data?.success) {
        onCloseImportCSV();
        showToastSuccess({
          title: "Imported data successfully.",
        });
        if (params.page !== 1) {
          setParams(preState => ({
            ...preState,
            page: 1
          }))
        } else {
          setIsRefetch((preState) => !preState);
        }
      } else {
        const message = data?.message?.content || data?.messages[0];
        showToastFail({
          title: message,
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoadingImport(false);
    }
  };

  const fetchMonthlyPayoutSettings = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await getMonthlyPayoutSettings(params);
      if (data.success) {
        const { records, ...params } = data.data;
        setMonthlyPayout(records);
        setParams((preState) => ({
          ...preState,
          total: params?.total,
          total_page: params?.total_page,
        }));
      }
    } catch (error) {
      toast({
        description:
          error?.message ||
          "An error occurred while fetching Monthly Payout Settings",
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  }, [params?.page, params?.limit, isRefetch]);

  const fetchNumOfMachine = useCallback(async () => {
    try {
      const { data } = await getMachineQuantity();
      if (data.success && data?.data?.quantity) {
        form.setValue("machine", data?.data?.quantity);
      }
    } catch (error) {
      toast({
        description:
          error?.message || "An error occurred while fetching Machine Quantity",
        status: "error",
        position: "bottom-right",
      });
    }
  }, []);

  useEffect(() => {
    fetchMonthlyPayoutSettings();
  }, [params?.page, params?.limit, isRefetch]);

  useEffect(() => {
    fetchNumOfMachine();
  }, []);

  return (
    <>
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
        <Card px="24px">
          <CardHeader>
            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems="center"
              mb="20px"
            >
              <Flex gap={2}>
                <Image
                  src={BackIcon}
                  w="25px"
                  h="25px"
                  alt="back"
                  cursor="pointer"
                  onClick={onBackToOwnerNftList}
                />
                <Text color={textColor} fontSize="lg" fontWeight="bold">
                  Monthly Payout Settings
                </Text>
              </Flex>
              <ButtonType
                text="Import CSV"
                fontSize="16px"
                fontWeight="500"
                onClick={onOpenImportCSV}
              />
            </Flex>
          </CardHeader>
          <CardBody overflowX="auto" flexDirection="column" gap={10} mt={4}>
            <FormProvider {...form}>
              <form>
                <Flex alignItems={"flex-end"} gap={2}>
                  <FormInput
                    w="300px"
                    name="machine"
                    label="Number of machine(s)"
                    disabled={!isEditNumOfMachine}
                    onChange={(e) => {
                      form.setValue("machine", onlyNumber(e.target.value));
                    }}
                  />
                  {isEditNumOfMachine ? (
                    <Icon
                      mb={1}
                      fontSize="30px"
                      borderRadius="8px"
                      cursor="pointer"
                      bg="#07CF84"
                      as={CheckIcon}
                      onClick={debouncedChangeNumOfMachine}
                    />
                  ) : (
                    <Icon
                      mb={1}
                      fontSize="30px"
                      borderRadius="8px"
                      p="7px"
                      cursor="pointer"
                      bg="#1480FF"
                      as={EditIcon}
                      onClick={() => setIsEditNumOfMachine(true)}
                    />
                  )}
                </Flex>
              </form>
            </FormProvider>

            <TableCustom
              labels={labels}
              isLoading={isLoading}
              isCheck={false}
              dataRow={monthlyPayout}
            >
              <>
                {monthlyPayout?.map((item) => {
                  return (
                    <MonthlyPayoutRow
                      key={item?.id}
                      isEditMode={idRowEdit && idRowEdit === item.id}
                      handleOpenUpdateMode={() => setIdRowEdit(item.id)}
                      handleUpdateMonthlyPayout={handleUpdateValueNft}
                      data={item}
                    />
                  );
                })}
              </>
            </TableCustom>
          </CardBody>
          <Paginate
            page={params?.page}
            pageCount={params?.total_page}
            limit={params.limit}
            total={params.total}
            onChangeLimit={onChangeLimit}
            handleChangePage={handleChangePage}
          />
        </Card>
      </Flex>
      {isOpenImportCSV && (
        <ModalImportFile
          isOpen={isOpenImportCSV}
          onClose={onCloseImportCSV}
          handleImport={handleImportCSV}
          isLoading={isLoadingImport}
        />
      )}
    </>
  );
}
