import {
	Flex,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import { useCallback, useEffect, useState } from "react";
import ConditionNonNft from "./condition";
import RowNftGacha from "./row";
import { getListRewardNftGacha } from "api/gacha.api";
import ModalImport from "../ModalImport";

export default function TableRewardNft(props) {
  const { setCheckedIds, checkedIds, watch, listType } = props
  const [isLoading, setIsLoading] = useState(false);
  
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 50,
    allocation: "gacha",
    chain_id: watch('chain_id'),
  });

  const { 
    isOpen: isOpenImport, 
    onOpen: onOpenImport, 
    onClose: onCloseImport, 
  } = useDisclosure();

  const toast = useToast();
  const [rewardNftGacha, setRewardNftGacha] = useState([]);
  
  const labels = [
    "Id",
    "Name",
    "Type",
    "Image",
    "Chain",
    "Rarity",
    "Count",
    "Weight",
  ];
  
  const getListMachine = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getListRewardNftGacha({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          const listData = res?.records
          listData.forEach(item1 => {
            const item2 = checkedIds.find(item => item.id === item1.id);
            if (item2) {
              Object.assign(item1, item2);
            }
          });
          setRewardNftGacha(listData);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );
  
  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };
  
  useEffect(() => {
    getListMachine();
  }, [getListMachine]);
  
  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      const newReward = rewardNftGacha?.filter((reward) => reward?.weight > 0)?.map((reward) => ({
        ...reward,
        related_type: reward?.token_type
      }))
      const r = newReward.filter((elem) => !checkedIds.find(({ id }) => elem.id === id))
      setCheckedIds([...checkedIds, ...r]);
    } else {
      const uniqueArray1 = checkedIds.filter(item1 => 
        !rewardNftGacha.some(item2 => item2.id === item1.id)
      );
      setCheckedIds(uniqueArray1);
    }
  };
  
  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  return (
    <Flex flexDirection="column">
			<Card px="0px">
				<CardHeader mb="12px" display="flex" flexDirection="column" gap="20px">
					<ConditionNonNft
						isLoading={isLoading}
						setParams={setParams}
						params={params}
            listType={listType}
            onOpenImport={onOpenImport}
            watch={watch}
					/>
				</CardHeader>
				<CardBody overflowX="auto">
					<TableCustom
						labels={labels}
						isLoading={isLoading}
						isCheck
						isChecked={
							rewardNftGacha?.length > 0 && checkedIds?.length > 0 && rewardNftGacha.every(reward => checkedIds.find(check => reward.id === check.id))
						}
            showCheck={rewardNftGacha.some(reward => reward?.weight > 0)}
						onChangeChecked={onChangeChecked}
						dataRow={rewardNftGacha}
					>
						<>
							{rewardNftGacha?.map((item) => {
								return (
									<RowNftGacha
										key={item?.id}
										setCheckedIds={setCheckedIds}
										checkedIds={checkedIds}
										machine={item}
									/>
								);
							})}
						</>
					</TableCustom>
				</CardBody>
				<Paginate
					page={params?.page}
					pageCount={totalPage}
					limit={params.limit}
					onChangeLimit={onChangeLimit}
					handleChangePage={handleChangePage}
				/>
			</Card>
      {isOpenImport && (
        <ModalImport
          isOpen={isOpenImport}
          onClose={onCloseImport}
          setCheckedIds={setCheckedIds}
          rewardNftGacha={rewardNftGacha}
          setRewardNftGacha={setRewardNftGacha}
        />
      )}
		</Flex>
  )
}
