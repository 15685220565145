import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";
import { getGamePlayType } from "api/gameplayType.api";
import { getMachineSettings } from "api/machine.api";
import ButtonType from "components/Button";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import { FIRST_PAGE, LIMIT_PAGE } from "constants/constants";
import { useCallback, useEffect, useState } from "react";
import SelectMachineRow from "./row";

export default function SelectMachineModal({
  isOpen,
  onClose,
  handleSelectMachine,
  checkedIds,
  setCheckedIds,
}) {
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const [isLoading, setIsLoading] = useState(false);
  const [listGamePlayType, setListGamePlayType] = useState([]);

  const [dataTable, setDataTable] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: FIRST_PAGE,
    limit: LIMIT_PAGE,
  });

  const getListGamePlayType = async () => {
    try {
      const res = await getGamePlayType();
      if (res?.data?.success) {
        const listType = res?.data?.data?.records?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));
        setListGamePlayType(listType);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };
  const getListMachine = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        setCheckedIds([]);
        const { data } = await getMachineSettings({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setDataTable(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const labels = [
    "Mac Address",
    "Booth No.",
    "Image",
    "Direction",
    "Booth Type",
    "Gameplay Type",
  ];

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };
  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedIds(dataTable);
    } else {
      setCheckedIds([]);
    }
  };

  const onSelectMachine = () => {
    handleSelectMachine(checkedIds || []);
    onClose();
  };

  useEffect(() => {
    getListMachine();
    getListGamePlayType();
  }, [getListMachine]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxW="700px" overflowY="auto">
          <ModalHeader
            borderBottom="1px solid #EEEFF2"
            w="100%"
            textAlign="center"
            margin="auto"
            color={textColor}
          >
            {"Select Machine(s)"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p="24px">
            <TableCustom
              labels={labels}
              isLoading={isLoading}
              isCheck
              isChecked={
                dataTable?.length > 0 && checkedIds.length === dataTable?.length
              }
              onChangeChecked={onChangeChecked}
              dataRow={dataTable}
            >
              <>
                {dataTable?.map((prize, index) => {
                  return (
                    <SelectMachineRow
                      key={index}
                      setCheckedIds={setCheckedIds}
                      checkedIds={checkedIds}
                      isHiddenEdit
                      listMachine={prize}
                    />
                  );
                })}
              </>
            </TableCustom>
            {(totalPage && (
              <Paginate
                page={params?.page}
                pageCount={totalPage}
                limit={params.limit}
                onChangeLimit={onChangeLimit}
                handleChangePage={handleChangePage}
              />
            )) ||
              ""}
          </ModalBody>
          <ModalFooter
            borderTop="1px solid #EEEFF2"
            w="30%"
            textAlign="center"
            margin="auto"
            gap="20px"
          >
            <ButtonType
              mt={4}
              m="auto"
              w="100%"
              h="46px"
              borderRadius="5px"
              onClick={onSelectMachine}
            >
              SELECT
            </ButtonType>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
