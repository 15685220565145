import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useToast,
} from "@chakra-ui/react";
import { IoIosArrowDown } from "react-icons/io";
import ButtonType from "components/Button";
import { useMemo, useCallback } from "react";
import { NFT_MANAGEMENT_TYPE } from "views/NFTsManagement/constants";
import { debounce } from "lodash";

export default function NFTsActionPanel({
  isLoading = false,
  nftMngType,
  checkedIds,
  onChangeAllocate,
  onTransfer,
  onDownload,
  onTransferGenesis,
  onSellGenesis,
  onRemoveFromPackage,
  onImportCSV,
}) {
  const toast = useToast();
  const isHaveCheckedIds = useMemo(() => {
    return checkedIds?.length > 0;
  }, [checkedIds]);

  const isValidateTransfer = useMemo(() => {
    return checkedIds?.every((check) => check?.allocation === "NONE");
  }, [checkedIds]);

  const checkSameChain = useCallback(() => {
    if (!checkedIds || checkedIds.length === 0) {
      return false;
    }
    const firstChainId = checkedIds[0]?.nft_information?.chain_id;
    const isSameChain = checkedIds.every(
      (nft) => nft?.nft_information?.chain_id === firstChainId
    );
    if (!isSameChain) {
      toast({
        description: "Selected NFTs must be on the same chain",
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    return true;
  }, [checkedIds, toast]);

  const checkSamePackage = useCallback(() => {
    if (!checkedIds || checkedIds.length === 0) {
      return false;
    }
    const firstPackageId = checkedIds[0]?.package_id;
    const isSamePackage = checkedIds.every(
      (nft) => nft?.package_id === firstPackageId
    );
    if (!isSamePackage) {
      toast({
        description: "Selected NFTs must be on the same package",
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    return true;
  }, [checkedIds, toast]);

  const checkNotExistPackage = useCallback(() => {
    if (!checkedIds || checkedIds.length === 0) {
      return false;
    }
    const isSamePackage = checkedIds.every((nft) => nft?.package_id);
    if (!isSamePackage) {
      toast({
        description: "Selected NFTs must be in a package",
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    return true;
  }, [checkedIds, toast]);

  const handleMenuItemClick = useCallback(
    (action) => {
      if (!checkSameChain()) return;
      if (!checkSamePackage()) return;
      if (action === "remove" && !checkNotExistPackage()) return;

      switch (action) {
        case "transfer":
          onTransferGenesis?.();
          break;
        case "sell":
          onSellGenesis?.();
          break;
        case "remove":
          onRemoveFromPackage?.();
          break;
        default:
          console.error("Unknown action:", action);
      }
    },
    [
      onTransferGenesis,
      onSellGenesis,
      onRemoveFromPackage,
      checkSameChain,
      checkSamePackage,
      checkNotExistPackage,
    ]
  );

  const handleTransfer = () => {
    if (checkSameChain()) {
      onTransfer?.();
    }
  };

  const debouncedDownload = useCallback(debounce(onDownload, 500), []);

  const renderButtonsAction = () => {
    switch (nftMngType) {
      case NFT_MANAGEMENT_TYPE.OWNER:
        return (
          <ButtonType
            text="Import CSV"
            fontSize="12px"
            btnType="primary-new-outline"
            sizeIcon="16px"
            onClick={onImportCSV}
            isLoading={isLoading}
          />
        );
      case NFT_MANAGEMENT_TYPE.GENESIS:
        return (
          <Menu>
            <MenuButton
              fontSize="12px"
              borderRadius="8px"
              boxShadow="0 4px 7px -1px rgba(0,0,0,.11),0 2px 4px -1px rgba(0,0,0,.07)"
              disabled={!isHaveCheckedIds}
              as={Button}
              rightIcon={<IoIosArrowDown />}
              bg="gray.600"
              color="white"
              _hover={{ bg: "gray.700" }}
              _active={{ bg: "gray.600" }}
            >
              Action
            </MenuButton>
            <MenuList>
              <MenuItem
                minH="48px"
                onClick={() => handleMenuItemClick("transfer")}
              >
                Transfer Genesis NFTs
              </MenuItem>
              <MenuItem minH="48px" onClick={() => handleMenuItemClick("sell")}>
                Sell Genesis NFTs
              </MenuItem>
              <MenuItem
                minH="48px"
                onClick={() => handleMenuItemClick("remove")}
              >
                Remove from package
              </MenuItem>
            </MenuList>
          </Menu>
        );
      default:
        return (
          <>
            <ButtonType
              text="Re-Allocate"
              fontSize="12px"
              btnType="success"
              iconType="change-state"
              sizeIcon="16px"
              disabled={!isHaveCheckedIds}
              onClick={onChangeAllocate}
            />
            <ButtonType
              text="Transfer"
              fontSize="12px"
              btnType="danger"
              iconType="transfer"
              sizeIcon="16px"
              disabled={!isHaveCheckedIds || !isValidateTransfer}
              onClick={handleTransfer}
            />
          </>
        );
    }
  };

  return (
    <Flex gap={4} flexWrap="wrap" alignItems="flex-end">
      <Flex alignItems="flex-end" gap={6} mb="10px">
        {renderButtonsAction()}
        <ButtonType
          text="Download CSV"
          fontSize="12px"
          sizeIcon="8px"
          iconType="download"
          btnType="dark"
          onClick={debouncedDownload}
        />
      </Flex>
    </Flex>
  );
}
