export const GAS_CONFIG = 1000000000
export const TIME_ONE_DAY = 100000000
export const TIME_TRANSACTION = 30000
export const optionPercent = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

export const StorageKeys = {
  USERINFO: 'userinfo',
  TOKEN: 'access_token',
  LANGUAGE: 'langType',
  THEME: 'theme',
  PRIMARY_COLOR: 'primaryColor',
  menuOpenkeys: 'menuOpenkeys',
  HIDELOGO: 'hideLogo',
  HIDETAGSVIEW: 'hideTagsView',
  NAVTYPE: 'navType',
  NOFIXEDHEADER: 'header_not_fixed',
}

export const USER_ROLE = {
  ADMIN: 'ADMIN',
  EDITOR: 'EDITOR',
  VISITOR: 'VISITOR',
  USER: 'USER',
  MEMBER: 'MEMBER',
  GUEST: 'GUEST',
  MANAGER: 'MANAGER',
  MASTER: 'MASTER',
  OWNER: 'OWNER',
}

export const OPERATING_SYSTEM = {
  window: 'WindowsPhone',
  android: 'Android',
  testflight: 'testflight',
  unknown: 'unknown',
}

export const ANTD_UPLOAD_STATUS = {
  UPLOADING: 'uploading',
  DONE: 'done',
}

export const ANTD_ORDER_TO_API_FIELD = {
  descend: 'DESC',
  ascend: 'ASC',
}

export const FORMAT_TIME = {
  YEAR_MONTH_DATE: 'YYYY-MM-DD',
  DATE_HOUR_MINUTES: 'YYYY-MM-DD HH:mm',
  DATE_HOUR_MINUTES_SLASH: 'DD/MM/YYYY HH:mm',
  DATE_HOUR_MINUTES_SECOND: 'YYYY-MM-DD HH:mm:ss',
  HOUR_MINUTES: 'HH:mm',
  YEAR_MONTH_DATE_LOWER: 'YYYY-MM-DD',
  HOUR_MINUES_LOWER: 'HH:mm',
  HOUR_MINUES_SECOND: 'HH:mm:ss',
  MINUES_SECOND_LOWER: 'mm:ss',
  FULL_DATE: 'YYYY-MM-DD HH:mm',
  FULL_DATE_LLLL: 'DD MMM YYYY HH:mm:ss'
}

export const NUMBER = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  ELEVEN: 11,
  TWELVE: 12,
  TWENTY: 20,
  TWENTY_FOUR: 24,
  TWENTY_NINE: 29,
  THIRTY: 30,
  FORTY: 40,
  SIXTY: 60,
  ONEHUNDRED: 100,
  TWOHUNDRED: 200,
  FIVEHUNDRED: 500,
  THOUSAND: 1000,
  NUM_2147483647: 9999999999.99999,
  LIMMIT_PAGE: 20,
}

export const STRING = {
  EMPTY: '',
  ALL: '---All---',
  abilities: 'abilities',
  Save: 'Save',
  Edit: 'Edit',
  NOT_FULL: 'NOT_FULL',
  FULL: 'FULL',
  normal: 'normal',
  GET: 'GET',
  SET: 'SET',
  ERROR: 'ERROR',
  start: 'start',
  end: 'end',
  STR_0: '0',
  STR_1: '1',
  STR_2: '2',
  STR_3: '3',
  STR_4: '4',
  STR_5: '5',
  STR_6: '6',
  STR_7: '7',
  STR_8: '8',
  STR_9: '9',
  STR_00: '00',
  STR_01: '01',
  STR_02: '02',
  STR_03: '03',
  STR_04: '04',
  STR_05: '05',
  STR_06: '06',
  STR_07: '07',
  STR_08: '08',
  STR_09: '09',
  Disband: 'Disband',
}

export const mapMimeToExt = {
  'audio/aac': 'aac',
  'application/x-abiword': 'abw',
  'application/x-freearc': 'arc',
  'video/x-msvideo': 'avi',
  'application/vnd.amazon.ebook': 'azw',
  'application/octet-stream': 'bin',
  'image/bmp': 'bmp',
  'application/x-bzip': 'bz',
  'application/x-bzip2': 'bz2',
  'application/x-cdf': 'cda',
  'application/x-csh': 'csh',
  'text/css': 'css',
  'text/csv': 'csv',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'docx',
  'application/vnd.ms-fontobject': 'eot',
  'application/epub+zip': 'epub',
  'application/gzip': 'gz',
  'image/gif': 'epub',
  'text/html': 'html',
  'image/vnd.microsoft.icon': 'ico',
  'text/calendar': 'ics',
  'application/java-archive': 'jar',
  'image/jpeg': 'jpg',
  'text/javascript': 'js',
  'application/json': 'json',
  'application/ld+json': 'jsonld',
  'audio/midi audio/x-midi': 'midi',
  'audio/mpeg': 'mp3',
  'video/mp4': 'mp4',
  'video/mpeg': 'mpeg',
  'application/vnd.apple.installer+xml': 'mpkg',
  'application/vnd.oasis.opendocument.presentation': 'odp',
  'application/vnd.oasis.opendocument.spreadsheet': 'ods',
  'application/vnd.oasis.opendocument.text': 'odt',
  'audio/ogg': 'oga',
  'video/ogg': 'ogv',
  'application/ogg': 'ogx',
  'audio/opus': 'opus',
  'font/otf': 'otf',
  'image/png': 'png',
  'application/pdf': 'pdf',
  'application/x-httpd-php': 'php',
  'application/vnd.ms-powerpoint': 'ppt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'pptx',
  'application/vnd.rar': 'rar',
  'application/rtf': 'rtf',
  'application/x-sh': 'sh',
  'image/svg+xml': 'svg',
  'application/x-shockwave-flash': 'swf',
  'application/x-tar': 'tar',
  'image/tiff': 'tiff',
  'video/mp2t': 'ts',
  'font/ttf': 'ttf',
  'text/plain': 'txt',
  'application/vnd.visio': 'vsd',
  'audio/wav': 'wav',
  'audio/webm': 'weba',
  'video/webm': 'webm',
  'image/webp': 'webp',
  'font/woff': 'woff',
  'font/woff2': 'woff2',
  'application/xhtml+xml': 'xhtml',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/xml': 'xml',
  'application/vnd.mozilla.xul+xml': 'xul',
  'application/zip': 'zip',
  'video/3gpp': '3gp',
  'video/3gpp2': '3g2',
  'application/x-7z-compressed': '7z',
  'video/quicktime': 'MOV',
}
