import { Box, Flex } from "@chakra-ui/react";
import ButtonType from "components/Button";
import { useMemo } from "react";

export default function ActionDigitalFigures({ checkedIds, toggle }) {
  const isHaveCheckedIds = useMemo(() => {
    return checkedIds?.length > 0;
  }, [checkedIds]);

  return (
    <Box>
      <Flex alignItems="center" justifyContent="flex-end" gap={4}>
        <ButtonType
          text="Allocate"
          width="100px"
          fontSize="12px"
          btnType="success"
          onClick={() => toggle("modalAllocate") || checkedIds?.some((check) => check?.allocation !== "NONE")}
          disabled={!checkedIds?.length || checkedIds?.some((check) => check?.allocation !== "NONE") || checkedIds?.some((check) => check?.count === 0)}
        />
        <ButtonType
          text="Transfer"
          width="100px"
          fontSize="12px"
          btnType="dark"
          onClick={() => toggle("modalTransfer")}
          disabled={!checkedIds?.length || checkedIds?.some((check) => check?.allocation !== "NONE") || checkedIds?.some((check) => check?.count === 0)}
        />
        <ButtonType
          text="NEW"
          fontSize="12px"
          width="100px"
          onClick={() =>  toggle("modalCreateAndEdit")}
        />
        <ButtonType
          text="DELETE"
          fontSize="12px"
          btnType="danger"
          width="100px"
          onClick={() => toggle("modalDelete")}
          disabled={!checkedIds?.length || checkedIds?.some((check) => check?.allocation !== "NONE")}
        />
      </Flex>
    </Box>
  );
}
