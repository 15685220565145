import { Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { deleteMachineSettings } from "api/machine.api";
import ButtonType from "components/Button";
import { useMemo } from "react";

export default function ActionMachineGroup({
  handleOpenCreateOrUpdate,
  checkedGroups,
  onOpenDelete
}) {
  const isHaveCheckedIds = useMemo(() => {
    return checkedGroups?.length > 0;
  }, [checkedGroups]);

  return (
    <Flex gap={4} flexWrap="wrap" w="100%" >
      <Flex alignItems="center" w="100%" justifyContent="flex-end" gap={4} mb={2}>
        <ButtonType
          text="NEW"
          w="120px"
          fontSize="12px"
          onClick={() => {
            handleOpenCreateOrUpdate();
          }}
        />
        <ButtonType
          text="REMOVE"
          btnType="primary-new-outline"
          fontSize="12px"
          disabled={!isHaveCheckedIds}
          w="120px"
          onClick={() => onOpenDelete()}
        />
      </Flex>
    </Flex>
  );
}
