import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import TwoClaw from 'assets/img/2_Claws.png'
import ThreeClaw from 'assets/img/3_Claws.png'
import EditIcon from 'assets/svg/edit.svg'
import PrizeIcon from 'assets/svg/prize-game-mode.svg'
import machineIcon from 'assets/svg/machine.svg'
import { STATUS } from 'constants/constants'
import SwitchType from 'components/Switch'

export default function DetailModePvp(props) {
  const { item, toggle, handleSwitchStatus } = props

  const isStateActive = useMemo(() => {
    return item.status === STATUS.ACTIVE;
  }, [item.status]);

  return (
    <Flex 
      w="auto"
      height="auto"
      p="8px" 
      borderRadius="8px"
      direction="column"
      columnGap="4px"
      bg="linear-gradient(160deg, #69EAE3 40%, #18A69E 98.37%)"
      minWidth="150px"
      maxWidth="200px"
    >
      <Flex direction="column" gap={2}>
        <Flex alignItems="center" justifyContent="center">
          <Box width="150px"> {/* Thiết lập độ rộng cho Box */}
            <Text
              fontSize={{
                base: "16px",
              }}
              textAlign="center"
              fontWeight="700"
              isTruncated
            >
              {item?.mode_name}
            </Text>
          </Box>
        </Flex>
        <Flex alignItems="center" justifyContent="center" gap={6}>
          <Image
            src={item?.machine_type === "TWO_CLAWS" ? TwoClaw : ThreeClaw}
            w="50px"
            h="50px"
            alt={item?.id}
          />
          <Image
            src={item?.gameplay_type?.image_url}
            w="50%"
            h="auto"
            alt={item?.id}
          />
        </Flex>
        <Flex
          p="2px 6px"
          w="100%"
          borderRadius="6px"
          textAlign="start"
          backgroundColor="#f82e38"
          color="#FFF"
          fontSize="14px"
          fontWeight="500"
          direction="column"
        >
          <Text>Lv. : {item?.entry_restriction_values[0]?.min_value?.split("_")?.[1]}-{item?.entry_restriction_values[0]?.max_value?.split("_")?.[1]}</Text>
          <Text>Skill rank : {item?.entry_restriction_values[1]?.min_value || 0}-{item?.entry_restriction_values[1]?.max_value || 0}</Text>
          <Text>Arena Hunter: {item?.entry_restriction_values[2]?.min_value || 0}-{item?.entry_restriction_values[2]?.max_value || 0}</Text>
        </Flex>
        <Box
          p="2px 6px"
          w="100%"
          borderRadius="6px"
          textAlign="center"
          backgroundColor="rgba(0, 0, 0, 0.4)"
          color="#FFF"
        >
          {item?.turn_each_player} turns
        </Box>
        <Flex direction="row" gap="4px" borderRadius="6px">
          <Flex p="6px" background="#fff" borderRadius="6px" w="100%" justifyContent="center">
            <Text fontSize="14px" fontWeight="500" color="#D32C35">{item?.play_fee?.quantity} BC</Text>
            <Text fontSize="14px" fontWeight="500"> /turn</Text>
          </Flex>
          <Flex p="6px" background="#fff" borderRadius="6px" w="100%" justifyContent="center">
            <Text fontSize="14px" fontWeight="500" color="#D32C35">{item?.life_gauge_consumption} LG</Text>
            <Text fontSize="14px" fontWeight="500"> /turn</Text>
          </Flex>
        </Flex>
        <Flex alignItems="center" justifyContent="flex-end" gap="6px">
          <SwitchType
            color="#e04995"
            colorThumb="#fff"
            size="lg"
            colorScheme="teal"
            position="relative"
            isChecked={isStateActive}
            onChange={() => handleSwitchStatus(item?.id, item?.status)}
          >
            <Box
              as="span"
              position="absolute"
              left="25%"
              top="50%"
              transform="translate(-50%, -50%)"
              color="white"
              fontWeight="bold"
              fontSize="12px"
              pointerEvents="none" // Prevents pointer events from interfering with the switch
            >
              On
            </Box>
            {!isStateActive && (
              <Box
                as="span"
                position="absolute"
                left="64%"
                top="50%"
                transform="translate(-50%, -50%)"
                fontWeight="bold"
                color="#4a4a4a"
                fontSize="12px"
                pointerEvents="none" // Prevents pointer events from interfering with the switch
              >
                Off
              </Box>
            )}
          </SwitchType>
          <Flex alignItems='center' gap="6px">
            <Flex onClick={() => toggle('listMachines', item)} w="30px" h="30px" borderRadius="8px" background="#000" alignItems="center" justifyContent="center" cursor="pointer">
              <Image src={machineIcon} alignSelf='center' justifySelf='center' />
            </Flex>
            <Flex onClick={() => toggle('listPrize', item)} w="30px" h="30px" borderRadius="8px" background="#000" alignItems="center" justifyContent="center" cursor="pointer">
              <Image src={PrizeIcon} alignSelf='center' justifySelf='center' />
            </Flex>
            <Flex onClick={() => toggle('addNew', item)} w="30px" h="30px" borderRadius="8px" background="#000" alignItems="center" justifyContent="center" cursor="pointer">
              <Image src={EditIcon} alignSelf='center' justifySelf='center' />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
