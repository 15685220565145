import { Flex, useToast } from "@chakra-ui/react";
import END_POINT from "api/constants";
import ButtonType from "components/Button";
import FormSelect from "components/form/FormSelect";
import { OPTIONS_RARITY } from "constants/constants";
import { FormProvider, useForm } from "react-hook-form";
import { downloadCSV } from "utils/downloadCSV";
import { convertParams, omitParams } from "utils/object";

export default function ConditionNonNft({
  isLoading,
  setParams,
  params,
  listType,
  onOpenImport,
  watch,
}) {
  const form = useForm();
  const toast = useToast();

  const { watch: watchFilter, setValue, handleSubmit } = form;

  const onSubmit = (data) => {
    if (!isLoading) {
      const newParams = {
        ...params,
        ...data,
        page: 1,
      };
      setParams(newParams);
    }
  };

  const downloadCsvNft = async () => {
    try {
      const paramsDownload = convertParams(omitParams({
        ...params,
        token_type: watchFilter('token_type'),
        rarity: watchFilter('rarity'),
        chain_id: watch('chain_id'),
      }));
      const baseUrl = `${END_POINT.REWARD_EXPORT}?${paramsDownload}`;
      await downloadCSV(baseUrl, true);
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Flex
      gap={4}
      flexWrap="wrap"
      justifyContent="space-between"
      w="100%"
      alignItems="flex-end"
    >
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
        >
          <Flex w="100%" flexWrap="wrap" alignItems="flex-end" gap={4}>
            <FormSelect
              name="token_type"
              label="Type"
              defaultValue={""}
              w="220px"
              options={[{ label: "All", value: "" }, ...listType]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("token_type", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
            <FormSelect
              name="rarity"
              label="Rarity"
              defaultValue={""}
              w="220px"
              options={[{ label: "All", value: "" }, ...OPTIONS_RARITY]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("rarity", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
          </Flex>
        </form>
      </FormProvider>
      
      <Flex gap="4px">
        <ButtonType
          text="Download CSV"
          fontSize="12px"
          sizeIcon="8px"
          btnType="dark"
          onClick={downloadCsvNft}
        />
        <ButtonType
          text="Import"
          fontSize="12px"
          sizeIcon="8px"
          btnType="dark"
          onClick={onOpenImport}
        />
      </Flex>
    </Flex>
  );
}
