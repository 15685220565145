
export const STATUS_PRESENTBOX = [
  { value: "PENDING", label: "Pending" },
  { value: "ON_GOING", label: "OnGoing" },
  { value: "COMPLETED", label: "Completed" },
  { value: "FAIL", label: "Failed" },
];

export const RECIPIENTS = [
  { value: "INDIVIDUALS", label: "Individual(s)" },
  { value: "ALL", label: "All Users" },
];

export const DISTRIBUTE_TYPE = [
  { value: "STANDARD", label: "Standard" },
  { value: "CONDITION", label: "Conditional" },
];

export const DISTRIBUTE_CONDITION = [
  { value: "EVENT_INVITATION_CARD", label: "Send Invitation Card" },
  { value: "EVENT_TOP_RANK", label: "Event Rank" },
];

export const TYPE_CURRENCY = [
  { value: "point_currency", label: "Point & Currency" },
  { value: "invitation_card", label: "Invitation Card" },
];
