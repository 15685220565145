import React, { forwardRef } from "react";
import BundledEditor from "components/tinymce-editor";

const MceEditor = forwardRef(
  ({ mailContent, height, index }, editorRefs) => {

    // Configuration object for the editor
    const editorConfig = {
      height: height,
      menubar: false,
      plugins: [
        "advlist",
        "autolink",
        "lists",
        "link",
        "image",
        "charmap",
        "anchor",
        "searchreplace",
        "visualblocks",
        "code",
        "fullscreen",
        "insertdatetime",
        "media",
        "table",
        "preview",
        "help",
        "wordcount",
      ],
      toolbar:
        "undo redo | blocks | " +
        "bold italic forecolor | alignleft aligncenter " +
        "alignright alignjustify | bullist numlist outdent indent | " +
        "Placeholders | " +
        "link | removeformat | help",
      content_style:
        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }" +
        `
          .wrappretext {
            white-space: pre-wrap;
            word-wrap: break-word;
          }
        `,
        formats: {
          pre: { block: 'pre', classes: 'wrappretext' }
        },
    };

    return (
      <BundledEditor
        selector="textarea"
        license_key="gpl"
        onInit={(_evt, editor) => {
          editorRefs.current[index] = editor;
        }}
        initialValue={mailContent}
        init={editorConfig}
      />
    );
  }
);

export default MceEditor;
