import { Td, Tr, Image } from "@chakra-ui/react";
import FormInput from "components/form/FormInput";

export default function CardRow ({
  item,
  index,
  cardData,
  setCardData,
}) {
const handleInputChange = (value) => {
  const updatedCardData = [...cardData];
  updatedCardData[index].amount = value;
  setCardData(updatedCardData);
};

  return (
    <Tr>
      <Td width="10px">
        {item?.card_name}
      </Td>
      <Td>
        <Image
          src={item?.url_image}
          w="28px"
          h="28px"
          alt={item?.id}
        />
      </Td>
      <Td minWidth="150px">
        <FormInput
          type="number"
          min="1"
          step="1"
          maxW="100%"
          name="amount"
          value={item.amount}
          onChange={(e) =>
            handleInputChange(Number(e?.target?.value) >= 0 ? e?.target?.value : 1)
          }
        />
      </Td>
    </Tr>
  );
}