import {
  Flex,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import TableCustom from "components/Tables/CustomTable";
import { useCallback, useEffect, useState } from "react";
import RoleManagementRow from "./row";
import { useHistory } from "react-router-dom";
import { getEventSummary } from "api/dashboad.api";
import { useCronJob } from "hooks/useCronJob";

export default function ActiveEvent(props) {
  const labels = [
    "EVENT", 
    "PLAYING", 
    "PARTICIPANTS",
    "END IN"
  ];
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const history = useHistory();
  const [activeEvents, setActiveEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getRoleData = useCallback(async (isReStartJon = true) => {
    setIsLoading(true)
    try {
      if (isReStartJon) {
        stopJob();
      }
      const { data } = await getEventSummary();
      if (data.success) {
        setActiveEvents(data?.data)
      }
      setIsLoading(false)
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
      setIsLoading(false)
    } finally {
      if (isReStartJon) {
        startJob();
      }
    }
  }, []);

  useEffect(() => {
    getRoleData();
    return () => {
      stopJob();
    };
  }, [getRoleData]);

  const { startJob, stopJob } = useCronJob({
    timeCron: 60000,
    task: () => {
      getRoleData(false);
    },
  });

  return (
    <Flex flexDirection="column">
      <Card p="12px">
        <CardHeader>
          <Flex w="100%" alignItems='center' justifyContent='space-between' px={3}>
            <Flex
              w="100%"
              direction="column"
              alignItems="flex-start"
            >
              <Text color={textColor} fontSize="lg" fontWeight="bold">
                Active Events
              </Text>
              <Text fontSize="sm" fontWeight="bold" color="#ccc">
                Updated 1 minutes ago
              </Text>
            </Flex>
            <Text fontSize='20px' cursor='pointer' onClick={() => history.push("/admin/game-control/new-event-management")}>...</Text>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto" mt="8px">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck={false}
            dataRow={activeEvents}
          >
            <>
              {activeEvents?.map((item, index) => {
                return (
                  <RoleManagementRow
                    key={item?.id}
                    item={item}
                    index={index + 1}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
      </Card>
    </Flex>
  );
}
