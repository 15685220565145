import { Flex, Text, FormLabel, Grid, Box, useToast, TagLabel, Tag } from '@chakra-ui/react';
import { Select as ChakraSelect, chakraComponents } from "chakra-react-select";
import FormDatePicker from 'components/form/FormDatePicker';
import FormInput from 'components/form/FormInput';
import FormSelect from 'components/form/FormSelect';
import React from 'react'
import debounce from "lodash/debounce";
import ButtonType from "components/Button";
import FormCheckbox from "components/form/FormCheckbox";
import FormRadio from "components/form/FormRadio";
import TableCustom from "components/Tables/CustomTable";
import RowGift from "./rowGift"
import { useState, useEffect, useCallback } from "react";
import { DISTRIBUTE_TYPE, RECIPIENTS, DISTRIBUTE_CONDITION } from "../constant";
import FormTextAria from "components/form/FormTextAria";
import { getUserInfo } from "api/presentBox.api";
import { downloadLocalCSV } from "utils/downloadCSV";
import PresentBoxCSV from "assets/csv/present_box.csv";
import { SmallCloseIcon } from "@chakra-ui/icons";
import styled from "styled-components";
const TextError = styled.div`
color: #E53E3E;
font-size: 16px;
`
export default function FormEng(props) {
  const toast = useToast();
  const [userOptions, setUserOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [resourceData, setResourceData] = useState({});
  const [cardData, setCardData] = useState([]);
  const [gifts, setGifts] = useState([]);

  const {
    transLabel,
    setValue,
    dateFormat,
    watch,
    onOpenSelectItem,
    dataDetail,
    getValues,
    onOpenImportCSV,
    optionEvents,
    events,
    event,
    setEvent,
    fileSelect,
    setFileSelect,
    errors,
    receiver,
    setReceiver,
    isNoExpire,
    setIsNoExpire,
    clearErrors,
  } = props

  const labels = [
    "NAME",
    "TYPE",
    "SUB TYPE",
    "VALUE",
    ""
  ];

  useEffect(() => {
    if(dataDetail) {
      const cardData = dataDetail?.gift?.event_invitation_cards;
      const resourceData = dataDetail?.gift?.resource;
      if(cardData?.length > 0) {
        setValue("details", cardData);
      }

      if(resourceData) {
        setValue("resource", resourceData);
      }
      setIsNoExpire(dataDetail?.is_no_expiration);

      if(dataDetail?.available_in_days) {
        setValue("expired_date", dataDetail?.available_in_days);
      }

      if (dataDetail?.receivers) {
        const options = dataDetail?.receivers?.map((item) => {
          return {
            value: item?.id,
            label: item?.email,
          };
        });
        setUserOptions(options);
        setReceiver(options);
      }
    }
  }, [dataDetail]);

  useEffect(() => {
    setResourceData(watch("resource") || {});
    setCardData(watch("details") || []);
  }, [watch("resource"), watch("details")]);

  useEffect(() => {
    const giftsData = [
      ...cardData.map((item) => ({
        type: "Invitation Card",
        name: item?.card_name || item?.name,
        value: item?.amount,
      })),
      ...Object.keys(resourceData)
        .filter((key) => resourceData[key] > 0)
        .map((key) => ({
          type: "Point & Currency",
          name: key,
          value: resourceData[key],
        }))
    ];

    setGifts(giftsData);
  }, [cardData, resourceData]);

  const handleDeleteGift = (item) => {
    if(Object.prototype.hasOwnProperty.call(resourceData, item.name)) {
      const updatedResourceData = { ...resourceData };
      delete updatedResourceData[item.name];
      setValue("resource", updatedResourceData);
    } else {
      const filteredData = cardData.filter((card) => card.card_name !== item.name);
      setValue("details", filteredData);
    }
  }

  const onChangeEvent = (value) => {
    if(value) {
      setValue("event", value);
      const event = events.find(event => event.id === value);
      setEvent(event);
      clearErrors("event");
    }
  }

  useEffect(() => {
    if(watch("event")) {
      const event = events.find(event => event.id === watch("event"));
      setEvent(event);
    }
  }, [watch("event"), dataDetail, events]);

  useEffect(() => {
    if(watch("recipient_type") === "ALL") {
      setFileSelect(null);
      setReceiver([]);
      setValue("receivers", []);
    }
  }, [watch("recipient_type")]);

  useEffect(() => {
    if(watch("distribute_condition") === "EVENT_TOP_RANK") {
      setValue("recipient_type", "ALL");
    }
  }, [watch("distribute_condition")]);

  useEffect(() => {
    setValue("expired_date", getValues('expired_date'))
  }, []);

  const downloadCsvTemplate = async () => {
    try {
      const fileName = "present_box.csv";
      const csv = PresentBoxCSV;
      await downloadLocalCSV(csv, fileName);
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const fetchUsers = async (inputValue) => {
    setIsLoading(true);
    try {
      const params = {
        page: 1,
        limit: 999999,
        keyword: inputValue,
        status: "ACTIVE",
      };
      const { data } = await getUserInfo(params);
      if (data?.data?.records?.length > 0) {
        const options = data?.data?.records?.map((user) => {
          return {
            value: user?.id,
            label: user?.email,
          };
        });
        setUserOptions(options);
      } else {
        toast({
          description: "No users found",
          status: "warning",
          position: "top",
        });
        setUserOptions([]);
      }
    } catch (error) {
      setUserOptions([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if(fileSelect) {
      setReceiver([]);
    }
  }, [fileSelect]);

  useEffect(() => {
    if(receiver.length > 0) {
      setValue("identifier_type", "ID");
      setValue("receivers", receiver);
      setFileSelect(null);
      clearErrors("receivers");
    }
  }, [receiver]);

  const debouncedFetchUsers = useCallback(
    debounce((inputValue) => fetchUsers(inputValue), 300),
    []
  );

  const handleInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      debouncedFetchUsers(inputValue);
    } else {
      setUserOptions([]);
    }
  };

  const handleChangeNoExpire = (isChecked) => {
    setValue("is_no_expiration", isChecked);
    setIsNoExpire(isChecked);
  }

  const removeCSV = () => {
    setFileSelect(null);
    setValue("identifier_type", null);
    setValue("receivers", []);
  };

  const handleSelectChange = (selectedOption) => {
    if(selectedOption.length == 0) {
      setValue("receivers", []);
    }
    setReceiver(selectedOption)
  }

  return (
    <Flex flexDirection="column" gap="12px">
      <FormInput
        maxW="100%"
        name="mgmt_name"
        label={transLabel("mgmt_name")}
        placeholder={transLabel("enterMgmtName")}
        isRequired
      />
      <FormInput
        maxW="100%"
        name="name_en"
        label={transLabel("name")}
        placeholder={transLabel("enterName")}
        isRequired
      />
      <FormTextAria
        label={transLabel("description")}
        placeholder={transLabel("enterDesc")}
        name="description_en"
        rows={7}
        h="150px"
        fontSize="md"
      />
      <Flex direction="column" gap="2px" w="100%" padding="8px 0">
        <FormLabel>{transLabel("distributeType")}</FormLabel>
        <Flex alignItems="center" gap="70px" width="100%">
          <FormRadio
            name="distribute_type"
            options={DISTRIBUTE_TYPE}
            defaultValue={DISTRIBUTE_TYPE[0].value}
            isRequired
          />
        </Flex>
      </Flex>
      {watch("distribute_type") === "CONDITION" && (
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          <FormSelect
            name="distribute_condition"
            label={transLabel("distributeCondition")}
            options={[...DISTRIBUTE_CONDITION]}
            onChange={(e) => setValue("distribute_condition", e.target.value)}
            isRequired
          />
          <FormSelect
            name="event"
            label={transLabel("event")}
            options={[{ label: "Select Event", value: "" }, ...optionEvents]}
            onChange={(e) => onChangeEvent(e.target.value)}
          />
        </Grid>
      )}
      {watch("distribute_type") === "CONDITION" && watch('distribute_condition') === 'EVENT_TOP_RANK' &&
      (<Grid templateColumns="repeat(2, 1fr)" gap={6}>
        <FormInput
          width="100%"
          type="number"
          name="from_rank"
          isRequired
          label={transLabel("from")}
          placeholder={transLabel("fromPlaceholder")}
        />
        <FormInput
          maxW="100%"
          type="number"
          name="to_rank"
          isRequired
          label={transLabel("to")}
          placeholder={transLabel("fromPlaceholder")}
        />
      </Grid>
      )}
      <FormLabel>{transLabel("distributeDate")}<span style={{ color: 'red' }}>&nbsp;*</span></FormLabel>
      <Flex w="100%" gap="8px">
        <Flex w="50%">
          <FormDatePicker
            name="distribution_start_date"
            showTimeSelect
            dateFormat={dateFormat}
            h="40px"
          />
        </Flex>
      </Flex>
      <Flex direction="column" gap="12px" w="100%" padding="8px 0">
        <FormLabel>{transLabel("recipient")}</FormLabel>
        <Flex alignItems="center" gap="70px" width="100%">
          <FormRadio
            name="recipient_type"
            options={RECIPIENTS}
            defaultValue={RECIPIENTS[1].value}
            isRequired
            isDisabled={watch('distribute_condition') === 'EVENT_TOP_RANK'}
          />
        </Flex>
      </Flex>
      {watch("recipient_type") !== 'ALL' && (
        <Flex alignItems="center" gap={6} width="100%">
          <Flex width="50%">
            <Box width="100%">
              <ChakraSelect
                options={userOptions}
                placeholder="Add user(s)"
                value={receiver}
                onChange={handleSelectChange}
                onInputChange={handleInputChange}
                isLoading={isLoading}
                noOptionsMessage={() => null}
                chakraStyles={{
                  placeholder: (provided) => ({
                    ...provided,
                    color: "gray.400",
                  }),
                  control: (provided) => ({
                    ...provided,
                    cursor: "text",
                  }),
                }}
                components={{
                  Option: ({ children, ...props }) => (
                    <chakraComponents.Option {...props}>
                      <Flex alignItems="center">
                        <Box mr={2}>{children}</Box>
                      </Flex>
                    </chakraComponents.Option>
                  ),
                  DropdownIndicator: () => null,
                }}
                isMulti
              />
              </Box>
          </Flex>
          <Text fontSize="sm" ml="2">Or</Text>
          <ButtonType
            text="DOWNLOAD TEMPLATE"
            fontSize="12px"
            sizeIcon="8px"
            btnType="primary-new-outline"
            onClick={downloadCsvTemplate}
          />
          <ButtonType
            text="IMPORT CSV"
            fontSize="12px"
            sizeIcon="8px"
            onClick={onOpenImportCSV}
          />
        </Flex>
      )}
      {receiver?.length > 0 && (
        <Flex wrap="wrap" mb={4}>
          {receiver.map((user) => (
            <Tag key={user.value} colorScheme="blue" mr={2} mb={2}>
              <TagLabel>{user.label}</TagLabel>
            </Tag>
          ))}
        </Flex>
      )}
      {errors?.receivers?.message && <TextError>{errors?.receivers?.message}</TextError>}
      {fileSelect && (
        <Flex alignItems="cetner" flexWrap="wrap" gap="8px" mt="8px">
          <Flex
            background="#e0ffff"
            padding="4px 6px"
            alignItems="center"
            borderRadius="6px"
          >
            <Text fontSize="14px" fontWeight="400">{fileSelect?.name}</Text>
            <SmallCloseIcon onClick={() => removeCSV()}/>
          </Flex>
        </Flex>
      )}
      <Flex direction="row" gap={4} w="100%" alignItems="flex-end" justifyContent="flex-start">
        <Text fontSize="16px" fontWeight="500">
          {transLabel("selectPresentBoxItem")}
        </Text>
        <ButtonType
          text="+"
          fontSize="25px"
          sizeIcon="8px"
          btnType="primary-new-outline"
          onClick={() => onOpenSelectItem()}
        />
      </Flex>
      {errors?.resource?.message && <TextError>{errors?.resource?.message}</TextError>}
      {gifts.length > 0 && (
        <TableCustom
        labels={labels}
        dataRow={gifts}
        isCheck={false}
      >
        {gifts?.map((item, index) => {
          return (
            <RowGift
              key={index}
              item={item}
              handleDelete={() => handleDeleteGift(item)}
            />
          );
        })}
      </TableCustom>
      )}
      <FormLabel mt="3" direction="row" >{transLabel("receiptExpireSetting")}<span style={{ color: 'red' }}>&nbsp;*</span></FormLabel>
      <Flex>
        <Flex direction="row" gap={4} w="100%" alignItems="center" justifyContent="space-between">
          <FormCheckbox
            name="is_no_expiration"
            label={transLabel("noExpire")}
            isChecked={isNoExpire}
            onChange={(e) => handleChangeNoExpire(e.target.checked)}
            wrapperProps={{
              display: "flex",
              alignItems: "center",
              margin: "0"
            }}
          />
          <Flex justifyContent="flex-end" alignItems="center" gap="4px">
            <FormInput
              maxW="100%"
              type="number"
              name="expired_date"
              placeholder={transLabel("enterExpireDate")}
              disabled={isNoExpire}
            />
            <Text fontSize="12px" >
              {transLabel("day")}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
