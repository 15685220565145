import { ALLOCATION } from "constants/constants";

export const FEATURES_LIST = [
  { label: "3D", value: "is_3d" },
  { label: "Room", value: "is_room" },
  { label: "AR Support", value: "is_ar" },
  { label: "Holomodels", value: "is_holo_model" },
]

export const TRANS_LABEL = {
	en: {
		name: "Name",
		enterName: "Enter a name",
		description: "Description",
		enterDesc: "Enter description",
		image: "Image",
		asset: "Asset",
    features: "Features",
    partner: "Partner",
    serialCode: "Import Serial Code",
    count: "Count",
    countries: "Target Countries",
    allCountries: "Add countries",
    enterValue: "Enter a value",
    relatedDigital: "Related Digital Figure",
    selectDigital: "Select a digital figure",
	},
	jp: {
		name: "名前",
		enterName: "名前を入力してください",
		description: "説明",
		enterDesc: "説明を入力",
		image: "画像",
		asset: "資産",
    features: "特徴",
    partner: "パートナー",
    serialCode: "シリアルコードのインポート",
    count: "数",
    countries: "対象国",
    allCountries: "国を追加",
    enterValue: "値を入力してください",
    relatedDigital: "関連デジタルフィギュア",
    selectDigital: "デジタルフィギュアを選択",
	},
};

export const listPartner = [
  {
    "id": "1",
    "create_time": 1734707933,
    "update_time": 1734707943,
    "name": "Pepsi",
    "label": "Pepsi",
    "value": "1",
  },
  {
    "id": "2",
    "create_time": 1734707933,
    "update_time": 1734707943,
    "name": "Coca",
    "label": "Coca",
    "value": "2",
  },
]

export const ALLOCATION_DF = [
  { value: ALLOCATION.PRIZE, label: "Prize" },
  { value: ALLOCATION.NONE, label: "None" },
];

export const bgFeature = (type) => {
  switch (type) {
    case "3D":
      return "#fffd77"
    case "AR":
      return "#b8ffa2"
    case "ROOM":
      return "#d096ff"
    case "HoloModel":
      return "#ffabff"
    default:
      return "#ccc"
  }
}

export const ERROR_DIGITAL_FIGURE = {
  "DIGITAL_FIGURE_HAS_BEEN_ALLOCATED_FOR_PRIZE": "Digital figure has been allocated for prize",
  "NUMBER_OF_COLUMNS_INVALID": "Number of columns invalid"
}
