import {
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ButtonType from "components/Button";
import { NUMBER } from "constants/enum";
import FormEng from "./formEng";
import FormJp from "./formJp";
import schemaPresentBox from "./schema";
import ModalSelectItem from "./ModalSelectItem"
import CurrencyItem from "./ModalSelectItem/currencyItem"
import { createPresentBox, checkUserValid, putPresentBox } from "api/presentBox.api";
import styled from "styled-components";
import { getDayLLLL } from "utils/time";
import { generateMgmtName } from "utils/string"
import { DISTRIBUTE_CONDITION } from "./../constant"
import ModalImportFile from "components/Modal/ModalImportFile";
import { addDays } from 'date-fns';

const TextError = styled.div`
padding: 0 24px;
color: #E53E3E;
font-size: 16px;
`
const TRANS_LABEL = {
  en: {
    newNPresentBox: "New Present Box",
    mgmt_name: "Mgmt Name",
    name: "Name",
    enterName: "Enter a name",
    enterDesc: "Enter description",
    description: "Description",
    distributeType: "Distribution Types",
    standard: "Standard",
    conditional: "Conditional",
    distributeDate: "Distribution Date ",
    recipient: "Recipients",
    individual: "Individual(s)",
    downloadTemplate: "DOWNLOAD TEMPLATE",
    importCSV: "IMPORT CSV",
    selectPresentBoxItem: "Select Present Box Items",
    receiptExpireSetting: "Receipt Expiration Settings",
    noExpire: "No Expiration",
    day: "day(s)",
    distributeCondition: "Distribution Condition",
    event: "Event",
    from: "From",
    fromPlaceholder: "Enter a value",
    to: "To",
    enterExpireDate: "Enter a duration, e.g., 60",
    addUsers: "Add user(s)",
  },
  jp: {
    newNPresentBox: "新しいプレゼントボックス",
    mgmt_name: "Mgmt名",
    name: "名前",
    enterName: "名前を入力する",
    enterDesc: "説明を入力する",
    description: "説明",
    distributeType: "配布タイプ",
    standard: "標準",
    conditional: "条件",
    distributeDate: "配布日",
    recipient: "受取人",
    individual: "個人",
    downloadTemplate: "ダウンロードテンプレート",
    importCSV: "CSVをインポートする",
    selectPresentBoxItem: "プレゼントボックスアイテムを選択する",
    receiptExpireSetting:"受取の有効期限設定",
    noExpire: "有効期限なし",
    day: "日",
    distributeCondition: "配布条件",
    event: "イベント",
    from: "から",
    fromPlaceholder: "値を入力する",
    to: "まで",
    enterExpireDate: "期間を入力する、例えば60",
    addUsers: "ユーザーを追加する",
  },
};

export default function CreateAndUpdatePresentBox({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
  events,
  optionEvents,
}) {
  const dateFormat = "dd MMM. YYYY HH:mm:ss";
  const [tabIndex, setTabIndex] = useState(0);
  const [trans, setTrans] = useState("en");
  const [checkedIds, setCheckedIds] = useState([]);
  const [event, setEvent] = useState(null);
  const [fileSelect, setFileSelect] = useState(null);
  const [receiver, setReceiver] = useState([]);
  const [isNoExpire, setIsNoExpire] = useState(true);
  const {
    isOpen: isOpenSelectItem,
    onOpen: onOpenSelectItem,
    onClose: onCloseSelectItem
  } = useDisclosure();

  const {
    isOpen: isOpenSelectItemCurrency,
    onOpen: onOpenSelectItemCurrency,
    onClose: onCloseSelectItemCurrency,
  } = useDisclosure();

  const {
    isOpen: isOpenImportCSV,
    onOpen: onOpenImportCSV,
    onClose: onCloseImportCSV,
  } = useDisclosure();

  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const form = useForm({
    resolver: yupResolver(schemaPresentBox()),
    defaultValues: {
      mgmt_name: generateMgmtName(),
      name_en: "",
      name_jp: "",
      description_en: "",
      description_jp: "",
      distribute_condition: DISTRIBUTE_CONDITION[0].value,
      distribution_start_date: null,
      from_rank: null,
      to_rank: null,
      is_no_expiration: true,
      expired_date: null,
    },
  });
  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    clearErrors,
    formState: { errors, isSubmitting },
  } = form;

  const disableWhenJP = useMemo(() => {
    return trans === "jp";
  }, [trans]);

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  useEffect(() => {
    if (dataDetail) {
      reset({
        ...dataDetail,
        distribution_start_date: getDayLLLL(dataDetail?.distribution_start_date * 1000),
        distribute_condition: dataDetail?.distribution_condition || DISTRIBUTE_CONDITION[0].value,
        distribute_type: dataDetail?.distribution_type,
        is_no_expiration: dataDetail?.is_no_expiration,
        event: dataDetail?.condition_setting?.event_id,
        from_rank: dataDetail?.condition_setting?.from_rank,
        to_rank: dataDetail?.condition_setting?.to_rank,
      });
    } else {
      reset();
    }
  }, [dataDetail]);

    const distributionDateDefault = () => {
      if(getValues("distribute_type") === "STANDARD") {
        setEvent(null);
        setValue("event", null);
        setValue("details", null);
      }

      if(getValues("distribute_type") === "STANDARD" && !dataDetail) {
        setValue("distribution_start_date", null);
      }

      if(event) {
        setValue("distribution_start_date",
          getValues("distribute_condition") === "EVENT_INVITATION_CARD" ?
            getDayLLLL(event.event_start_time * 1000) :
            getDayLLLL((event.event_end_time + 15 * 60) * 1000)
        )
        clearErrors("distribution_start_date")
      }
      if(event) {
        setValue("event_end_time", event.event_end_time)
        setValue("event_start_time", event.event_start_time)
      }
    }
    useEffect(() => {
      distributionDateDefault()
    }, [watch("distribute_condition"), event, watch("distribute_type")]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    if (index === 1) {
      setTrans("jp");
    } else {
      setTrans("en");
    }
  };

  const transLabel = (label) => {
    return TRANS_LABEL[trans][label];
  };

  const handleImport = async (data) => {
    const csvFile = data.get("file");
    try {
      const res = await checkUserValid(data);
      const result = res?.data
      if (result?.success) {
        const data = result?.data;
        if(data.status === "VALID") {
          setFileSelect(csvFile)
          setValue("identifier_type", "EMAIL");
          setValue("receivers", data?.emails);
          clearErrors("receivers")
          onCloseImportCSV();
        } else {
          toast({
            description: `${data?.emails} emails invalid`,
            status: "error",
            position: "bottom-right",
          });
        }
      } else {
        toast({
          description: res?.data?.message || res?.data?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const onSubmit = async (data, e) => {
    const conditionSetting = {
      distribution_condition: data?.distribute_condition,
      event_id: data?.event,
      from_rank: data?.from_rank,
      to_rank: data?.to_rank,
    }
    const recipientDetails = {
      identifier_type: data?.identifier_type,
      receivers: data?.identifier_type === "EMAIL" ? data?.receivers : data?.receivers?.map(item => item.value),
    }
    e.preventDefault();
    let dataSubmit = {
      distribution_type: data?.distribute_type,
      mgmt_name: data?.mgmt_name,
      name_en: data?.name_en,
      name_jp: data?.name_jp,
      description_en: data?.description_en,
      description_jp: data?.description_jp,
      recipient_type: data?.recipient_type,
      distribution_start_date: data?.distribution_start_date,
      is_no_expiration: data?.is_no_expiration,
      available_in_days: !data?.is_no_expiration ? data?.expired_date : null,
      gift: {
        resource: data?.resource,
        event_invitation_cards: data?.details?.map(({ id, amount }) => ({ id, amount })) || [],
      },
      ...(data?.recipient_type === "INDIVIDUALS" && { recipient_details: recipientDetails }),
      ...(data?.distribute_type === "CONDITION" && { condition_setting: conditionSetting }),
    }

    try {
      if (idEdit) {
        const res = await putPresentBox(dataDetail?.id, dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Present Box Edited Successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose()
        } else {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      } else {
        const res = await createPresentBox(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Present Box Created.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose()
        } else {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="present-box-form" onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            mb={4}
            index={tabIndex}
            variant="unstyled"
            onChange={handleTabsChange}
          >
            <TabList gap={2}>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                English
              </Tab>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                Japanese
              </Tab>
            </TabList>
          </Tabs>
          <Flex direction="column" w="100%" gap={4}>
            <Grid templateColumns="repeat(1, 1fr)" gap={4}>
              <Flex direction="column" gap={4}>
                {trans === "en" ? (
                  <FormEng
                    transLabel={transLabel}
                    setValue={setValue}
                    dateFormat={dateFormat}
                    watch={watch}
                    onOpenSelectItem={onOpenSelectItem}
                    dataDetail={dataDetail}
                    getValues={getValues}
                    onOpenImportCSV={onOpenImportCSV}
                    optionEvents={optionEvents}
                    events={events}
                    event={event}
                    setEvent={setEvent}
                    fileSelect={fileSelect}
                    setFileSelect={setFileSelect}
                    errors={errors}
                    receiver={receiver}
                    setReceiver={setReceiver}
                    isNoExpire={isNoExpire}
                    setIsNoExpire={setIsNoExpire}
                    clearErrors={clearErrors}
                  />
                ) : (
                  <FormJp
                    transLabel={transLabel}
                    disableWhenJP={disableWhenJP}
                    setValue={setValue}
                    watch={watch}
                    onOpenSelectItem={onOpenSelectItem}
                    dataDetail={dataDetail}
                    getValues={getValues}
                    dateFormat={dateFormat}
                    onOpenImportCSV={onOpenImportCSV}
                    optionEvents={optionEvents}
                    fileSelect={fileSelect}
                    errors={errors}
                    receiver={receiver}
                    setReceiver={setReceiver}
                    isNoExpire={isNoExpire}
                    setIsNoExpire={setIsNoExpire}
                  />
                )}
              </Flex>
            </Grid>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="700px" overflowY="auto" maxHeight="90vh" overflow="hidden">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "Edit Present Box" : "New Present Box"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px" height="calc(100% - 150px)" overflowY="auto">{renderForm()}</ModalBody>
        {errors?.name_jp?.message && trans === "en" && <TextError>Present Box Name Japan is {errors?.name_jp?.message}</TextError>}
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="notice-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="present-box-form"
            isLoading={isSubmitting}
          >
            Add
          </ButtonType>
        </ModalFooter>
      </ModalContent>
      {isOpenSelectItem && (
        <ModalSelectItem
          isOpen={isOpenSelectItem}
          onClose={onCloseSelectItem}
          setValueParent={setValue}
          checkedIds={checkedIds}
          setCheckedIds={setCheckedIds}
          event={event}
          getValuesParent={getValues}
          clearErrorsParent={clearErrors}
        />
      )}
      {isOpenSelectItemCurrency && (
        <CurrencyItem
          isOpen={isOpenSelectItemCurrency}
          onClose={onCloseSelectItemCurrency}
          setValue={setValue}
        />
      )}
      {isOpenImportCSV && (
        <ModalImportFile
          isOpen={isOpenImportCSV}
          onClose={onCloseImportCSV}
          handleImport={handleImport}
        />
      )}
    </Modal>
  );
}
